import React, { Component } from 'react';
import { observable } from 'mobx';
import classNames from 'classnames';

import MaskedTextInput from 'react-text-mask';
import { observer, inject } from 'mobx-react';

import Input from '../../ui/Input';
import Button from '../../ui/Button';
import RenderIf from '../../../helpers/renderIf';
import FormBinder from '../../../helpers/formBinder';
import {EMAIL, SHIPPING_STEP_FIELDS} from '../../../constants/formFields';
import { generateStateField } from '../../../helpers/static';
import CustomerService from '../../../services/customer';
import Loader from "../../ui/Loader";

@inject('StoreUser')
@inject('StorePurchase')
@observer
class ShippingAddressForm extends Component {
    form = null;

    @observable isLoading = false;

    constructor(props) {
        super(props);

        const { existingData = {}, StoreUser } = this.props;

        if (this.isStreamlineProduct && !StoreUser.isAuthenticated) {
            const ASYNC_EMAIL_RULES = {
                ...EMAIL,
                rules: `${EMAIL.rules}|checkUserEmail`
            };
            SHIPPING_STEP_FIELDS.push(ASYNC_EMAIL_RULES);
        }

        this.form = new FormBinder(SHIPPING_STEP_FIELDS, {
            onSuccess: this.onSuccess.bind(this)
        }, {}, existingData);
    }

    get isStreamlineProduct() {
        return this.props.StorePurchase.isStreamlineProduct;
    }

    async onSuccess(form) {
        this.isLoading = true;
        const { existingData = {}, StoreUser, handlerOnSuccess } = this.props;

        const values = form.values();
        const data = { ...existingData, ...values, province: generateStateField(values.province) };

        if (StoreUser.isAuthenticated) {
            const addresses = existingData && existingData._id
                ? await CustomerService.updateAddress(data)
                : await CustomerService.addAddress(data);

            StoreUser.updateItem('shippingAddresses', addresses);

            handlerOnSuccess(addresses, values.email);
        } else {
            handlerOnSuccess(
                [ { ...data, isDefault: true } ],
                values.email
            );
        }

        this.isLoading = false;
    }

    handleCancel = (event) => {
        this.form.onReset(event);
        return this.props.handlerOnCancel();
    };

    render() {
        const { form } = this;
        const { isModal = false, StoreUser } = this.props;
        const isDisableButton = isModal ? !form.isValid : !form.isValid || form.isPristine;

        return (
            <form className="form form-settings-shipping" onSubmit={form.onSubmit}>
                <div className="two-inputs-row">
                    <Input
                        showLabel={!isModal}
                        error={form.$('firstName').error}
                        {...form.$('firstName').bind()}
                    />
                    <Input
                        showLabel={!isModal}
                        error={form.$('lastName').error}
                        {...form.$('lastName').bind()}
                    />
                </div>
                {this.isStreamlineProduct && !StoreUser.isAuthenticated &&
                    <Input
                        showLabel={!isModal}
                        error={form.$('email').error}
                        {...form.$('email').bind()}
                    />
                }
                <div className="two-inputs-row displaced">
                    <Input
                        showLabel={!isModal}
                        error={form.$('address1').error}
                        {...form.$('address1').bind()}
                    />
                    <Input
                        showLabel={!isModal}
                        error={form.$('address2').error}
                        {...form.$('address2').bind()}
                    />
                </div>

                <Input
                    showLabel={!isModal}
                    error={form.$('city').error}
                    {...form.$('city').bind()}
                />

                <div className="three-inputs-row displaced flex-start">
                    <Input
                        wrapperClass="state"
                        showLabel={!isModal}
                        error={form.$('province').error}
                        {...form.$('province').bind()}
                    />

                    <Input
                        wrapperClass="zip"
                        showLabel={!isModal}
                        error={form.$('zipCode').error}
                        {...form.$('zipCode').bind()}
                    />

                    <div className={classNames('common-input-wraper', {
                        'input-with-error': form.$('phone').error
                    })}>
                        <RenderIf condition={!isModal}>
                            <label htmlFor="phone" className="Select-label">Phone</label>
                        </RenderIf>
                        <MaskedTextInput {...form.$('phone').bind()}
                            className={classNames('form-control__input', {'input-error': form.$('phone').error})}
                            // tslint:disable-next-line
                            mask={[ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ]}
                            guide={false} />
                        <RenderIf condition={Boolean(form.$('phone').error)}>
                            <div className="error-message">{form.$('phone').error}</div>
                        </RenderIf>
                    </div>

                </div>
                <div className="market-place-settings-content__actions">
                    <RenderIf condition={!isModal}>
                        <Button
                            onClick={this.handleCancel}
                            label="Cancel"
                            className="transparent"
                        />
                    </RenderIf>
                    <Button
                        type="submit"
                        onClick={form.onSubmit}
                        label={isModal ? "Continue" : "Save"}
                        className="dark-pink header-btn"
                        disabled={isDisableButton}
                    />
                </div>
                <Loader display={this.isLoading} />
            </form>
        );
    }
}

export default ShippingAddressForm;
