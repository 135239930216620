import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import './notifications.scss';

@inject('StoreNotifications')
@observer
class Notifications extends Component {

    renderNotification(scope, key, notification) {
        const { display, data } = notification;
        if (!display) {
            return null;
        }
        if (scope && scope !== data.scope) {
            return null;
        }

        return (
            <div key={`global-notification-${key}`} className={`notification-block ${data.type ? data.type : 'error'}`}>
                <img onClick={() => notification.close()} className="close-icon" src={require('../../../images/close-mini__icon.svg')} alt=""/>
                <img className="notification-block__img" src={require('../../../images/information_icon.svg')} alt=""/>
                <p className="notification-block__title">{data.title ? data.title : 'Error'}</p>
                {
                    data.desc ?
                        <p className="notification-block__info">{ data.desc }</p>
                        :
                        null
                }
                {
                    data.descArray && data.descArray.length ?
                        <div className="notification-block__info">
                            <ul className="notification-block__list">
                                {
                                    data.descArray.map((error, key) => {
                                        return (
                                            <li key={`notification-error-${key}`}>{error}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        :
                        null
                }
            </div>
        );
    }

    render() {
        const { scope = null, StoreNotifications } = this.props;
        return (
            <div className="block-errors">
                {
                    StoreNotifications.items && StoreNotifications.items.length ?
                        StoreNotifications.items.map((p, key) => {
                            return (
                                this.renderNotification(scope, key, p)
                            );
                        })
                        :
                        null
                }
            </div>
        );
    }
}

export default Notifications;
