import React, { Component } from 'react';
import { observable } from "mobx";
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import Cropper from 'react-cropper';

import Button from '../../ui/Button';
import Loader from "../../ui/Loader";
import CustomerService from "../../../services/customer";

/*
You need cropper.css in your project which is from cropperjs. Since this project have dependency on cropperjs, it located in
 */
import 'cropperjs/dist/cropper.css';

@inject('StoreUser')
@inject('StoreNotifications')
@observer
class ComponentCropper extends Component {
    cropperRef;
    labelRef;

    defaultRation = {
        min: 5000,
        max: 20000
    };

    @observable isLoading = false;
    @observable ratio = {
        current: 10000,
        min: this.defaultRation.min,
        max: this.defaultRation.max
    };

    getBlobCanvasData() {
        return new Promise((resolve) => {
            this.cropperRef.getCroppedCanvas().toBlob(resolve);
        });
    }

    crop = async () => {
        this.isLoading = true;

        const blob = await this.getBlobCanvasData();
        const formData = new FormData();
        const fileName = `${Date.now()}_avatar.jpg`;
        formData.append('avatar', blob, fileName);

        const { logoImage } = await CustomerService.setLogo(formData);
        this.props.StoreUser.changeLogo(logoImage.Location);

        this.props.cropperState.display = false;
        this.props.cropperState.imgSource = logoImage.Location;
        this.props.cropperState.notSavedFile = false;

        this.isLoading = false;
    };

    removeLogo = async() => {
        const { cropperState } = this.props;
        if (cropperState.notSavedFile) {
            this.props.cropperState.imgSource = '';
            this.props.cropperState.imgBlob = '';

            return true;
        }

        this.isLoading = true;

        try {
            await CustomerService.removeLogo();

            this.props.cropperState.imgSource = '';
            this.props.cropperState.imgBlob = '';

            this.props.StoreUser.changeLogo(null);
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }

        this.isLoading = false;
    };

    handleOnZoomRangeChange = (e) => {
        const { value } = e.target;
        this.ratio.current = value;
        this.cropperRef.zoomTo(value / 10000);
    };

    pressedKey = (e) => {
        const charCode = e.which || e.keyCode;
        if(charCode === 27) {
            this.props.closeCropper();
        }

        if(charCode === 13) {
            if (this.props.src) {
                this.crop();
            } else {
                this.labelRef.click();
            }
        }
    };

    onCropperReady = () => {
        const canvasData = this.cropperRef.getCanvasData();
        const currentRatio = canvasData.width / canvasData.naturalWidth;

        this.ratio.current = Math.round(currentRatio * 10000);
        this.ratio.min = Math.round(this.defaultRation.min * currentRatio);
        this.ratio.max = Math.round(this.defaultRation.max * currentRatio);
    };

    constructor (props) {
        super(props);

        window.addEventListener('keyup', this.pressedKey, true);
    }

    componentWillUnmount () {
        window.removeEventListener('keyup', this.pressedKey, true);
    }

    render() {
        const { isLoading, cropperState, closeCropper} = this.props;
        return (
            <div className="cropper-modal-overlay">
                <div className={classNames('cropper-block', {'with-loader': isLoading})}>
                    <h3 className="title">Upload new logo</h3>
                    <div className="cropper-content">
                        { cropperState.imgBlob && <i className="close-cropper" onClick={this.removeLogo}/> }
                        {
                            cropperState.imgBlob ?
                                <Cropper
                                    ref={(ref) => {
                                        this.cropperRef = ref;
                                    }}
                                    ready={this.onCropperReady}
                                    src={cropperState.imgBlob}
                                    style={{width: 320, height: 320, margin: '0 auto'}}
                                    aspectRatio={1}
                                    guides={false}
                                    minCropBoxWidth={320}
                                    minCropBoxHeight={320}
                                    cropBoxResizable={false}
                                    center={false} />
                                :

                                <label htmlFor="avatar-file" className="cropper-no-image" ref={(ref) => {
                                    this.labelRef = ref;
                                }}>Add Logo</label>
                        }
                    </div>
                    <div className="cropper-controls">
                        { cropperState.imgBlob ?
                            <div className="zoom-input">
                                <i className="zoom-icon mini" />

                                <input type="range"
                                    value={this.ratio.current}
                                    step="1"
                                    min={this.ratio.min}
                                    max={this.ratio.max}
                                    onChange={this.handleOnZoomRangeChange}/>
                                <i className="zoom-icon big" />
                            </div>
                            : null
                        }
                        <div className="cropper-action-btns">
                            <Button onClick={() => closeCropper()}
                                label="Cancel"
                                className="transparent"/>
                            <Button onClick={this.crop}
                                label="Save"
                                className="dark-pink"/>
                        </div>
                    </div>
                    <Loader display={this.isLoading} />
                </div>
            </div>
        );
    }
}
export default ComponentCropper;
