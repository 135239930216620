import { observable, action, computed } from 'mobx';
import { localStore } from '../../helpers/browserStores';

class StoreApp {
    creditsCoinEnabled = Boolean(parseInt(process.env.CREDITS_COIN_ENABLED, 10)) || false;

    @observable creditsCoinMode = localStore.get('creditsCoinMode', 'buy');

    @computed get creditsBuyMode() {
        return this.creditsCoinEnabled && this.creditsCoinMode === 'buy';
    }

    @computed get creditsGiftMode() {
        return this.creditsCoinEnabled && this.creditsCoinMode === 'gift';
    }

    @action creditsToggleMode(mode = null) {
        if (mode) {
            this.creditsCoinMode = mode;
        } else {
            this.creditsCoinMode = this.creditsGiftMode ? 'buy' : 'gift';
        }
        localStore.set('creditsCoinMode', this.creditsCoinMode);
    }

    disableGiftMode = this.creditsToggleMode.bind(this, 'buy');

    @observable title = 'Marketplace';
    @observable description = 'Earn rewards by shopping the world\'s best-voted ads...';

    @action changeHtmlHead(title, description = null) {
        this.title = title;
        this.description = description;
    }

    @observable isMobile = this.isMobileView();
    @observable isMobileLandscape = this.isMobileLandscapeView();
    @observable scrollDirection = null;
    lastScrollTop = 0;

    isMobileView() {
        return document.body.clientWidth <= 667;
    }

    isMobileLandscapeView() {
        return document.body.clientWidth > 480 && document.body.clientWidth < 600;
    }

    @action checkMobileView() {
        this.isMobile = this.isMobileView();
        this.isMobileLandscape = this.isMobileLandscapeView();
    }

    @action checkScrollDirection() {
        const pageYOffset = window.pageYOffset;
        this.scrollDirection = this.lastScrollTop > pageYOffset ? 'scrollUp' : 'scrollDown';
        this.lastScrollTop = pageYOffset;
    }

    lastMarketplaceURL = null;
    setLastURL(url) {
        this.lastMarketplaceURL = url;
    }

    @observable signInFormStep = 'signin';

    @action changeStep = (step) => {
        this.signInFormStep = step;
    };

    @observable isClickExclude = false;
}

export default StoreApp;
