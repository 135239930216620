import { observable } from 'mobx';

class StoreSearch {

    @observable emptySearchPlaceholder = false;
    @observable searchQuery = '';

    searchQueryOnChange(value) {
        this.searchQuery = value;
    }

    clearSearchQuery() {
        this.searchQuery = '';
    }

}

export default StoreSearch;
