import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import * as get from 'lodash/get';
import * as qs from 'qs';
import productsLoader from '../../helpers/productsLoader';
import customerFlow from '../../helpers/customerFlow';
import ProductService from '../../services/product';
import SimilarProducts from '../../components/marketplace/SimilarProducts';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/ui/Loader';

import { PageSignUp } from '../../pages/SignUp';

import ProductInfo from './steps/1_ProductInfo';
import CustomerInfo from './steps/2_CustomerInfo';
import LoggedInAs from './steps/2_LoggedInAs';
import GeneralPurchaseSteps from './steps/components/GeneralPurchaseSteps';

import TrackHelper from '../../helpers/track';
import { GTM } from '../../utils/GTM';

import './index.scss';
import AuthService from "../../services/auth";

@inject('StorePurchase')
@inject('StoreUser')
@inject('StoreApp')
@observer
class ModulePurchaseModal extends Component {
    productId = null;
    accountId = null;

    handleCloseModal = () => {
        this.props.StorePurchase.handleCloseModal();
    };

    constructor(props) {
        super(props);

        document.body.classList.add('open-modal');
        this.props.StorePurchase.widgetSize = this.props.widgetSize;

        TrackHelper.setEventSourceAndWidgetSize(this.props.StorePurchase.purchaseOn, this.props.StorePurchase.widgetSize);

        this.props.StorePurchase.handleCloseModalFunction = this.props.onClose;
        this.props.StorePurchase.handleSimilarProductClickFunction = this.props.onSimilarProductClick;
        this.props.StorePurchase.handleModalStepChangeFunction = this.props.onModalStepChange;
        this.props.StorePurchase.initNewStore(this.props.StoreUser.profile);
    }

    async __loadPurchaseProduct(productId, flow) {
        const { StorePurchase, onProductLoaded } = this.props;
        this.productId = productId;
        StorePurchase.toggleLoader(true);

        try {
            const serverProduct = await ProductService.getProductById(this.productId);

            if (this.props.StorePurchase.productInExpandableUnit && serverProduct.isValidForMarketplace === false) {
                throw 'Product is not valid for purchase on widget or marketplace';
            }

            this.accountId = serverProduct.account;

            // Omit variants with > 10$ for giveaway flow
            if (customerFlow.isCustomerFlowProduct(serverProduct._id)) {
                serverProduct.variants = serverProduct.variants.filter((variant) => variant.price <= 10);
            }

            const product = productsLoader.parseProduct(serverProduct);

            if (flow.isStreamlined) {
                customerFlow.enableStreamline(product, flow.options);
                const offer = customerFlow.getStreamlinedSpecialOffer(product.get('id'));
                if (offer && offer.strictVariant) {
                    product.updateWithStreamlinedParams(offer);
                    customerFlow.enableStreamline(product, flow.options);
                }
            }

            StorePurchase.store.options = product.options.map((option) => {
                const object = {
                    name: option.name,
                    position: option.position,
                    selected: option.selected || null
                };

                if (option.values && option.values.length) {
                    object.values = option.values.map((value) => {
                        return {
                            value,
                            disabled: false
                        };
                    });

                    if (option.values.length === 1) {
                        object.selected = get(option.values, '[0]', null);
                    }
                }

                return object;
            });

            StorePurchase.setProduct(product);

            if (onProductLoaded) {
                onProductLoaded(product);
            }

            TrackHelper.sendEvent('loaded', [ serverProduct ]);

            this.sendProductInfoToGTM(product);
        } catch (error) {
            if (this.props.onProductFetchError) {
                this.props.onProductFetchError(productId);
            }
        }

        this.props.StorePurchase.toggleLoader(false);
    }

    async componentDidMount () {
        const { productId, match, history } = this.props;

        const options = qs.parse(history.location.search.slice(1));
        this.forceSkipSignup = options.skipSignup === 'true';

        await this.__loadPurchaseProduct(productId, {
            isStreamlined: match.params.modalStep === 'streamlined',
            options
        });
    }

    componentWillUnmount() {
        document.body.classList.remove('open-modal');

        if (customerFlow.isStreamline) {
            customerFlow.disableFlow();
        }
    }

    componentDidUpdate (prevProps) {
        const { productId } = this.props;
        if (this.productId !== productId) {
            this.props.StorePurchase.initNewStore(this.props.StoreUser.profile);
            this.__loadPurchaseProduct(productId);
        }
        if((this.props.StoreApp.isMobile || this.props.StoreApp.isMobileLandscape) && this.modalOverlayRef) {
            this.modalOverlayRef.scrollTop = 0;
        }
    }

    closeSignInModal = async () => {
        const profile = await AuthService.checkAuth();

        this.props.StoreUser.setProfile(profile);
        this.props.StorePurchase.setProfile(profile);
    };

    sendProductInfoToGTM(product) {
        const firstVariant = get(product.variants, '0', null);
        GTM.ViewContentEvent.send({
            content_name: product.get('title'),
            content_ids: product.get('externalId'),
            contents: firstVariant && firstVariant.get('sku')
        });

        GTM.ProductClickEventGA.send({
            ecommerce: {
                click: {
                    products: [ {
                        name: product.get('title'),
                        id: product.get('externalId'),
                        price: product.price,
                        brand: product.get('brandName'),
                        category: product.get('category')
                    } ]
                }
            }
        });
    }

    /*
     Steps: productInfo, loggedInAs, customerInfo, shipping, billing, confirmation, orderCompleted
     */
    __getActiveStep(step) {
        switch (step) {
        case 'productInfo':
            return <ProductInfo />;
        case 'loggedInAs':
            return <LoggedInAs />;
        case 'customerInfo':
            return <CustomerInfo />;
        case 'shipping':
        case 'billing':
        case 'confirmation':
        case 'orderCompleted':
            return <GeneralPurchaseSteps step={step} />;
        default:
            return null;
        }
    }

    render() {
        const { store: { product, step, isLoading }, purchaseOn, isGiveAwayProduct, isFreeProduct, isStreamlineProduct, isShipFreeProduct } = this.props.StorePurchase;
        const { isImageViewInMobile } = this.props.StorePurchase;
        const { similarProductsLikeAccount = false, linkToMarketplace } = this.props;
        const isCustomerFlow = isGiveAwayProduct || isFreeProduct || isShipFreeProduct;

        const displaySignupWindow = () => <PageSignUp isStepMode={true} onSuccess={this.closeSignInModal}/>;

        const displayPurchaseWindow = () =>
            <div ref={ref => {
                this.modalOverlayRef = ref;
            }} className={classNames(`overlay overlay-y step-${step}`, {'image-view-in-mobile': isImageViewInMobile})}>
                {
                    product ?
                        <div className={classNames('mp-modal', {'expandable': purchaseOn === 'widget-buy'}, { 'full-sized': isCustomerFlow || isStreamlineProduct})}>
                            {!(isCustomerFlow || isStreamlineProduct) && <span className="mp-modal__close" onClick={this.handleCloseModal} />}

                            { this.__getActiveStep(step) }

                            {
                                step === 'productInfo' && !isImageViewInMobile ?
                                    <div className="mp-modal__footer">
                                        {!(isCustomerFlow || isStreamlineProduct) &&
                                            <Fragment>
                                                <SimilarProducts productId={this.productId} accountId={similarProductsLikeAccount ? this.accountId : null} />
                                                {
                                                    purchaseOn === 'marketplace' &&
                                                    <div className="mp-modal__more-wrapper">
                                                        <button className="mp-modal__more" onClick={this.handleCloseModal}>
                                                            See more products...
                                                        </button>
                                                    </div>
                                                }
                                            </Fragment>
                                        }
                                    </div>
                                    : null
                            }

                            {
                                purchaseOn === 'widget-buy' &&
                                <MarketplaceLink href={linkToMarketplace}/>
                            }

                            <Loader display={isLoading} />
                        </div>
                        : <Loader size="180px" display={true} />
                }

            </div>;


        if (!this.props.StoreUser.profile && isStreamlineProduct && !this.forceSkipSignup) {
            return displaySignupWindow();
        }

        return displayPurchaseWindow();

    }
}

const MarketplaceLink = ({ href }) => {
    return (
        <div className="marketplace-link-wrapper">
            <a href={href} target="_blank" rel="noopener noreferrer" className="marketplace-link">
                <span className="marketplace-link__text">powered by</span>
                <img src={require('../../images/klickly-logo-small.svg')}
                    alt="klickly"
                    className="marketplace-link__logo"/>
            </a>
        </div>
    );
};

export default withRouter(ModulePurchaseModal);
