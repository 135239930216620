import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import * as toNumber from 'lodash/toNumber';
import ImageCarousel from '../../../../components/marketplace/ImageCarousel';
import ProductDetails from './ProductDetails';

import TrackHelper from '../../../../helpers/track';
import { GTM } from "../../../../utils/GTM";

@inject('StoreDiscount')
@inject('StorePurchase')
@inject('StoreUser')
@observer
class ProductInfo extends Component {

    onPayloadComplete = (payload) => {
        const { StorePurchase, StoreDiscount, StoreUser } = this.props;

        StorePurchase.setCheckout(payload.selectedVariant, payload.qty, StoreDiscount.isFreeShipping);

        const { product } = StorePurchase.store;

        StorePurchase.setCreditsData(StoreUser.userCredits, product.get('creditsMultiplier'));

        TrackHelper.sendEvent('add-to-cart', [ product.object ], null, {
            price: toNumber(payload.selectedVariant.price) * 100, // convert dollars to cents for finger print
            quantity: payload.qty
        });

        GTM.CheckoutEvent.send({
            content_name: product.get('title'),
            content_ids: product.get('externalId'),
            contents: payload.selectedVariant.get('sku')
        });

        GTM.CheckoutEventGA.send({
            ecommerce: {
                checkout: {
                    products: [ {
                        name: product.get('title'),
                        id: product.get('externalId'),
                        price: payload.selectedVariant.price,
                        brand: product.get('brandName'),
                        category: product.get('category'),
                        variant: payload.selectedVariant.get('title'),
                        quantity: payload.qty
                    } ]
                }
            }
        });

        if (StorePurchase.isStreamlineProduct) {
            return StorePurchase.requiresShipping
                ? StorePurchase.changeStep('shipping')
                : StorePurchase.changeStep('billing');
        }

        // check on which step redirect
        StorePurchase.changeStep('customerInfo', true);
    };

    closeModalOrHideImagePreviewOnMobile() {
        if (this.props.StorePurchase.isImageViewInMobile) {
            this.props.StorePurchase.closeImageViewInMobile();
        } else {
            this.props.StorePurchase.handleCloseModal();
        }
    }

    render() {
        const {
            StorePurchase: { store: { product }, isImageViewInMobile, isStreamlineProduct } } = this.props;
        return (
            <div className="mp-modal-product">
                {!isStreamlineProduct && <span className="mp-modal__back" onClick={() => this.closeModalOrHideImagePreviewOnMobile()} />}
                {
                    product && <div className="mp-modal-product__carousel">
                        <ImageCarousel product={product} />
                    </div>
                }

                {
                    !isImageViewInMobile &&
                    <div className="mp-modal-product__info">
                        <ProductDetails product={product} onPayloadComplete={this.onPayloadComplete} />
                    </div>
                }
            </div>
        );
    }
}

export default ProductInfo;
