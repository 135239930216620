import React from 'react';
import Radio from '../../ui/Input/Radio';

export const AddressItem = (props) => {
    return (
        <div className={`shipping-tab__label accordion-address ${props.className}`}
            onClick={() => props.onClick ? props.onClick(props._id, props.isDefault) : null }>
            <Radio checked={props.isDefault} id={props._id} />
            <div>
                <div className="shipping-tab__name">{`${props.firstName} ${props.lastName}` }:</div>
                <div className="shipping-tab__address">{`${props.address1}`}</div>
            </div>
        </div>
    );
};
