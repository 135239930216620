
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ComponentHtmlHead from './components/static/HtmlHead';
import Notifications from './components/static/Notifications';

@inject('StoreApp')
@observer
class App extends Component {

    windowResizeHandler = () => {
        this.props.StoreApp.checkMobileView();
    };

    windowScrollHandler = () => {
        this.props.StoreApp.checkScrollDirection();
    };

    componentWillMount() {
        window.addEventListener('resize', this.windowResizeHandler);
        window.addEventListener('scroll', this.windowScrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeHandler);
        window.removeEventListener('scroll', this.windowScrollHandler);
    }

    componentWillUpdate({ location, history }) {
        const gtag = window.gtag;

        if (location.pathname === this.props.location.pathname) {
            return;
        }

        if (history.action === 'PUSH' && typeof gtag === 'function') {
            gtag('config', process.env.WIDGET_GA_ID, {
                'page_location': location.href,
                'page_path': location.pathname
            });
        }
    }

    render() {
        return (
            <div className="main-wrapper">
                <ComponentHtmlHead />
                {this.props.children}
                <Notifications />
            </div>
        );
    }
}

export default withRouter(App);
