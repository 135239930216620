
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ShippingAddressForm from '../../../../components/reusable/ShippingAddressForm';
import './index.scss';

@inject('StorePurchase')
@inject('StoreUser')
@observer
class Shipping extends Component {

    handlerOnSuccess = (addresses, email) => {
        const { StorePurchase, StoreUser } = this.props;

        // Specific case for streamlined flow ( guest checkout where email is entered in shipping or billing section )
        if (!StoreUser.isAuthenticated && email) {
            StorePurchase.setValue('profile.email', email);
        }

        StorePurchase.setValue('profile.shippingAddresses', addresses);
        StorePurchase.changeStep(StorePurchase.isGiveAwayProduct ? 'confirmation' : 'billing', true);
    };

    render() {
        return (
            <div className="mp-modal-purchase__shipping">
                <ShippingAddressForm
                    isModal={true}
                    handlerOnSuccess={this.handlerOnSuccess}
                    existingData={this.props.StorePurchase.editableShippingData}
                />
            </div>
        );
    }

}

export default Shipping;
