
import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import SignInForm from './SignInForm';
import ForgotForm from './ForgotForm';
import ResetForm from './ResetForm';
import './index.scss';
import {getProjectData} from "../../../projectMap";

const SignInSteps = inject('StoreApp')(
    observer((props) => {
        const { toggleLoader, onSuccess, onCreateAccountClick = null, hideSignUpLink = false, StoreApp: { changeStep, signInFormStep } } = props;
        return (
            <Fragment>
                { signInFormStep === 'signin' && props.children }

                { signInFormStep === 'signin' && <SignInForm
                    toggleLoader={toggleLoader}
                    changeStep={changeStep}
                    onSuccess={onSuccess} /> }
                { signInFormStep === 'forgot' && <ForgotForm toggleLoader={toggleLoader} changeStep={changeStep} /> }
                { signInFormStep === 'reset' && <ResetForm toggleLoader={toggleLoader} changeStep={changeStep} /> }

                {
                    signInFormStep === 'signin' && !hideSignUpLink ?
                        <div className="form__text">
                            New { getProjectData('title') } user?
                            <span
                                className="form__link"
                                onClick={() => onCreateAccountClick ? onCreateAccountClick() : null}> Create an account</span>
                        </div>
                        : null
                }
            </Fragment>
        );
    })
);

export default SignInSteps;
