
import Request from '../utils/Request';
import { shopAPI } from '../config/api';

class OrdersService {
    path = shopAPI.endPoints.orders;

    list(params) {
        return Request.get(this.path.list, params);
    }

    draftCreate(body) {
        return Request.post(this.path.draftOrder, body);
    }

    draftUpdate(id, body) {
        return Request.put(`${this.path.draftOrder}/${id}`, body);
    }

    draftComplete(id, body) {
        return Request.post(`${this.path.draftOrder}/${id}/complete`, body);
    }

}

const instance = new OrdersService();
export default instance;
