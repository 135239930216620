import { action, observable, computed } from 'mobx';
import * as get from 'lodash/get';
import { sessionStore } from './browserStores';

const KEY = 'quickto';
const FREE_OFFER_FLOW = 'freeoffer';
const GIVE_AWAY_FLOW = 'giveaway';
const FREE_PRODUCT_FLOW = 'freeproduct';
const STREAMLINE_FLOW = 'streamline';
const SHIP_FREE_FLOW = 'shipfree';

const BRAND_OFFER_DISCOUNT_MAP = new Map([
    [ 'default', {
        1: {
            qty: 1,
            strictQty: true,
            shipping: 5,
            discount: 0
        },
        2: {
            qty: 2,
            strictQty: true,
            shipping: 0,
            discount: 10
        },
        3: {
            qty: 3,
            strictQty: true,
            shipping: 0,
            discount: 30
        }
    } ],
    [ 'Your Super', {
        1: {
            qty: 1,
            strictQty: true,
            shipping: 5,
            discount: 0
        },
        2: {
            qty: 2,
            strictQty: true,
            shipping: 0,
            discount: 0
        },
        3: {
            qty: 1,
            strictQty: true,
            shipping: 0,
            discount: 20
        },
        4: {
            qty: 1,
            strictQty: true,
            strictVariant: 'Detox Bundle',
            shipping: 0,
            discount: 0
        }
    } ],
    [ 'WallyGro', {
        1: { // use Single variant
            qty: 1,
            strictQty: false,
            strictVariant: 'Single',
            shipping: 5,
            discount: 0
        },
        2: { // use 2pk variant
            qty: 1,
            strictQty: true,
            strictVariant: '2 pk',
            shipping: 0,
            discount: 0
        },
        3: { // use Single variant
            qty: 3,
            strictQty: true,
            strictVariant: 'Single',
            shipping: 0,
            discount: 14.98,
            defaultOptionIndex: 0
        },
        4: { // use 4pk variant
            qty: 1,
            strictQty: true,
            strictVariant: '4 Pk',
            shipping: 0,
            discount: 0
        },
        5: { // use Single variant
            qty: 5,
            strictQty: true,
            strictVariant: 'Single',
            shipping: 0,
            discount: 34.96,
            defaultOptionIndex: 0
        }
    } ],
    [ 'AcousticSheep LLC', {
        1: {
            qty: 1,
            strictQty: true,
            shipping: 0,
            discount: 0
        },
        2: {
            qty: 1,
            strictQty: true,
            shipping: 0,
            discount: 0
        },
        3: {
            qty: 1,
            strictQty: true,
            shipping: 0,
            discount: 0
        }
    } ]
]);

class CustomerFlow {
    @observable params = { flow: '', productID: '', offer: null };

    constructor() {
        this.params = sessionStore.get(KEY, {
            flow: '',
            productID: '',
            offer: null
        });
    }

    isCustomerFlowProduct(productID) {
        return this.params.productID === productID && (this.isGiveAwayFlow || this.isFreeProductFlow);
    }

    @computed get isGiveAwayFlow() {
        return this.params.flow === GIVE_AWAY_FLOW;
    }

    @computed get isFreeOfferFlow() {
        return this.params.flow === FREE_OFFER_FLOW;
    }

    @computed get isFreeProductFlow() {
        return this.params.flow === FREE_PRODUCT_FLOW;
    }

    @computed get isShipFreeFlow() {
        return this.params.flow === SHIP_FREE_FLOW;
    }

    @computed get isStreamline() {
        return this.params.flow === STREAMLINE_FLOW;
    }

    @computed get isFlowEnabled() {
        return this.isGiveAwayFlow ||
            this.isFreeOfferFlow ||
            this.isFreeProductFlow ||
            this.isStreamline ||
            this.isShipFreeFlow;
    }

    @action getStreamlinedSpecialOffer(productID) {
        const { params } = this;

        if (params.flow !== STREAMLINE_FLOW || params.productID !== productID || !params.offer) {
            return null;
        }

        return params.offer;
    }

    @action enableStreamline(product, options) {
        const currentParams = sessionStore.get(KEY);

        this.params = {
            ...currentParams,
            flow: STREAMLINE_FLOW,
            productID: product.get('id'),
            brandName: product.get('brandName')
        };

        const DISCOUNT_MAP = BRAND_OFFER_DISCOUNT_MAP.has(product.get('brandName'))
            ? BRAND_OFFER_DISCOUNT_MAP.get(product.get('brandName'))
            : BRAND_OFFER_DISCOUNT_MAP.get('default');

        if (!DISCOUNT_MAP[options.offer]) {
            sessionStore.set(KEY, this.params);
            return;
        }

        const subtotal = Math.round((product.price * DISCOUNT_MAP[options.offer].qty) * 100) / 100;

        this.params.offer = {
            defaultOptionIndex: DISCOUNT_MAP[options.offer].defaultOptionIndex,
            qty: DISCOUNT_MAP[options.offer].qty,
            subtotal,
            strictQty: DISCOUNT_MAP[options.offer].strictQty,
            strictVariant: DISCOUNT_MAP[options.offer].strictVariant,
            shipping: DISCOUNT_MAP[options.offer].shipping,
            discount: DISCOUNT_MAP[options.offer].discount,
            deal: subtotal - DISCOUNT_MAP[options.offer].discount,
            total: subtotal + DISCOUNT_MAP[options.offer].shipping - DISCOUNT_MAP[options.offer].discount
        };

        sessionStore.set(KEY, this.params);
    }

    @action enableFreeProduct(productID) {
        const currentParams = sessionStore.get(KEY);

        this.params = {
            ...currentParams,
            flow: FREE_PRODUCT_FLOW,
            productID
        };

        sessionStore.set(KEY, this.params);
    }

    @action enableShipFree(productID) {
        const currentParams = sessionStore.get(KEY);

        this.params = {
            ...currentParams,
            flow: SHIP_FREE_FLOW,
            productID
        };

        sessionStore.set(KEY, this.params);
    }

    @action enableFreeOffer(productID) {
        const currentParams = sessionStore.get(KEY);

        this.params = {
            ...currentParams,
            flow: FREE_OFFER_FLOW,
            productID
        };

        sessionStore.set(KEY, this.params);
    }

    @action enableGiveAway(productID) {
        const currentParams = sessionStore.get(KEY);

        this.params = {
            ...currentParams,
            flow: GIVE_AWAY_FLOW,
            productID
        };

        sessionStore.set(KEY, this.params);
    }

    @action disableFlow() {
        this.params.flow = '';
        this.params.productID = '';
        this.params.offer = null;
        sessionStore.set(KEY, this.params);
    }

    shouldGoToQtyOption() {
        return get(this, 'params.offer.defaultOptionIndex') === 0;
    }


}

export default new CustomerFlow();

