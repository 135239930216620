// add GTM to dom
const GTM_ID = process.env.GTM_ID;

// GTM event class
class GTMEvent {
    constructor(eventName, extraSendParams) {
        this.eventName = eventName;
        this.extraSendParams = extraSendParams;
    }

    send(params) {
        if (!GTM_ID) {
            return false;
        }
        const dataLayer = {
            event: this.eventName,
            ...this.extraSendParams
        };

        window['dataLayer'].push(Object.assign(dataLayer, params));
        return true;
    }
}

// GTM Events
export const GTM = {
    // Google Analytics GTM event triggers
    ProductImpressionEventGA: new GTMEvent('productImpression'),
    ProductClickEventGA: new GTMEvent('productClick'),
    AddToCartEventGA: new GTMEvent('addToCart'),
    CheckoutEventGA: new GTMEvent('checkout'),
    PurchaseEventGA: new GTMEvent('purchase'),
    ViewContentEvent: new GTMEvent('ViewContent', { content_type: 'product' }),
    SearchEvent: new GTMEvent('Search', {}),
    AddToCartEvent: new GTMEvent('AddToCart', { content_type: 'product' }),
    AddToWishlistEvent: new GTMEvent('AddToWishlist', { content_type: 'product' }),
    CheckoutEvent: new GTMEvent('InitiateCheckout', { content_type: 'product' }),
    AddPaymentInfoEvent: new GTMEvent('AddPaymentInfo', { content_type: 'product' }),
    PurchaseEvent: new GTMEvent('Purchase', {
        currency_type: 'USD', // may change in the future
        content_type: 'product'
    }),
    LeadEvent: new GTMEvent('Lead', { content_type: 'product' }),
    CompleteRegistrationEvent: new GTMEvent('CompleteRegistration', {}),
    HomePageCTAEvent: new GTMEvent('homePageCTA')
};
