
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import * as get from 'lodash/get';
import * as map from 'lodash/map';

import PurchaseInfo from '../PurchaseInfo';

import Shipping from '../../3_Shipping';
import Billing from '../../4_Billing';
import Confirmation from '../../5_Confirmation';
import OrderCompleted from '../../6_OrderCompleted';

@inject('StoreApp')
@inject('StorePurchase')
@observer
class GeneralPurchaseSteps extends Component {

    breadcrumbs = {
        loggedInAs: {
            label: 'customer info',
            visible: true,
            disabled: false
        },
        shipping: {
            label: 'shipping',
            visible: true,
            disabled: false
        },
        billing: {
            label: 'billing',
            visible: true,
            disabled: false
        },
        confirmation: {
            label: 'confirm',
            visible: true,
            disabled: false
        }
    };

    constructor(props) {
        super(props);
        const { StorePurchase } = this.props;
        this.breadcrumbs.shipping.visible = get(StorePurchase, 'store.checkout.requiresShipping', true);
        this.breadcrumbs.billing.visible = !StorePurchase.isGiveAwayProduct;

        if (StorePurchase.isStreamlineProduct) {
            Object.keys(this.breadcrumbs).forEach((key) => {
                this.breadcrumbs[key].disabled = true;
            });
        }
    }

    __goToStep(step) {
        this.props.StorePurchase.clearFormActionStatuses();
        this.props.StorePurchase.changeStep(step);
    }

    render() {
        const { step: activeStep } = this.props;
        return (
            <div className="mp-modal-purchase">
                <span
                    className="mp-modal__back"
                    onClick={() => this.__goToStep('productInfo')}
                />

                {!(activeStep === 'orderCompleted' && this.props.StoreApp.isMobile) && <PurchaseInfo />}

                <div className="mp-modal-purchase__steps">

                    {activeStep !== 'orderCompleted' &&
                        <ul className="mp-modal-purchase__step-list">
                            {map(this.breadcrumbs, ({label, visible}, stepKey) => {
                                if (!visible) return null;

                                return (
                                    <li key={`purchase-step-${label}`}
                                        className={classNames('mp-modal-purchase__step', { 'is-active': activeStep === stepKey })}
                                        onClick={() => {
                                            if (!this.breadcrumbs[stepKey].disabled) {
                                                this.__goToStep(stepKey);
                                            }
                                        }}
                                    >
                                        {label}
                                    </li>
                                );
                            })}
                        </ul>
                    }

                    { activeStep === 'shipping' && <Shipping /> }
                    { activeStep === 'billing' && <Billing /> }
                    { activeStep === 'confirmation' && <Confirmation /> }
                    { activeStep === 'orderCompleted' && <OrderCompleted /> }

                </div>
            </div>
        );
    }

}

export default GeneralPurchaseSteps;
