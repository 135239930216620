
import Request from '../utils/Request';
import { shopAPI } from '../config/api';

class CustomerService {
    path = shopAPI.endPoints.customer;

    checkCustomerEmail(email) {
        return Request.get(`${this.path.checkEmail}?email=${email}`);
    }

    updateCustomerProfile(data) {
        return Request.put(this.path.updateProfile, data);
    }

    addAddress(data) {
        return Request.post(this.path.address, data);
    }

    updateAddress(data) {
        return Request.put(this.path.address, data);
    }

    removeAddress(_id) {
        return Request.remove(this.path.address, _id);
    }

    setDefaultAddress(_id) {
        return Request.put(this.path.setDefaultAddress, {'addressId': _id});
    }

    addCreditCard(data) {
        return Request.post(this.path.payment, data);
    }

    updateCreditCard(data) {
        return Request.put(this.path.payment, data);
    }

    removeCreditCard(_id) {
        return Request.remove(this.path.payment, _id);
    }

    setDefaultCreditCard(data) {
        return Request.post(this.path.setDefaultPayment, data);
    }

    removeLogo() {
        return Request.remove(this.path.logo);
    }

    setLogo(data) {
        return Request.post(this.path.logo, data);
    }

    syncFavorites(data) {
        return Request.post(this.path.syncFavorites, data);
    }
}

const instance = new CustomerService();
export default instance;
