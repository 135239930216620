import { observable, action } from 'mobx';

class StoreAsidePanel {
    @observable display = {
        coins: false,
        cart: false,
        favorites: false
    }

    hideAllExceptChosen(element) {
        Object.keys(this.display).forEach(item => {
            if(item !== element) {
                this.display[item] = false;
            }
        });
    }

    @action showElement = (element) => {
        this.hideAllExceptChosen(element);
        this.display[element] = true;
    }

    @action hideElement = (element) => {
        this.display[element] = false;
    }

    @action toggleElement = (element) => {
        this.hideAllExceptChosen(element);
        this.display[element] = !this.display[element];
    }

    showFavorites = this.showElement.bind(this, 'favorites');
    hideFavorites = this.hideElement.bind(this, 'favorites');

    hideCart = this.hideElement.bind(this, 'cart');
}

export default StoreAsidePanel;
