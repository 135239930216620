import { sessionStore } from './browserStores';
import { getURLParam } from './static';
import StoreRoot from '../stores';

const defaultRedirectCategory = 'under10';

class QuicktoHelper {
    paramsToRedirect = sessionStore.get('paramsToRedirect', {
        category: defaultRedirectCategory,
        coins: false,
        gift: false,
        tutorial: false
    });
    initTheQuickToParams() {
        const params = {
            category: getURLParam('category'),
            coins: getURLParam('coins'),
            gift: getURLParam('gift'),
            tutorial: getURLParam('tutorial')
        };

        if (params.category !== null) {
            this.paramsToRedirect.category = params.category;
        } else {
            this.paramsToRedirect.category = defaultRedirectCategory;
        }

        if (params.coins !== null) {
            this.paramsToRedirect.coins = params.coins === '' ? true : Boolean(parseInt(params.coins, 10));
        }

        if (params.gift !== null) {
            this.paramsToRedirect.gift = params.gift === '' ? true : Boolean(parseInt(params.gift, 10));

            if (this.paramsToRedirect.gift) {
                StoreRoot.StoreApp.creditsToggleMode('gift');
            }
        }

        if (params.tutorial !== null) {
            this.paramsToRedirect.tutorial = params.tutorial === '' ? true : Boolean(parseInt(params.tutorial, 10));
        }

        sessionStore.set('paramsToRedirect', this.paramsToRedirect);
    }

    enableParam(param) {
        this.paramsToRedirect = {
            ...sessionStore.get('paramsToRedirect'),
            [param]: true
        };

        sessionStore.set('paramsToRedirect', this.paramsToRedirect);
    }
}

const instance = new QuicktoHelper();
export default instance;
