
export const shopAPI = {
    baseURL: process.env.SHOP_API_URL,
    endPoints: {
        auth: {
            checkAuth: '/auth/me',
            login: '/auth/login',
            logout: '/auth/logout',
            forgotPassword: '/password/forgot',
            verifyToken: '/password/verify',
            resetPassword: '/password/reset',
            register: '/auth/register'
        },
        customer: {
            checkEmail: '/customer/checkEmail',
            updateProfile: '/customer/updateProfile',
            logo: '/customer/avatar',
            address: '/customer/address',
            setDefaultAddress: '/customer/setDefaultAddress',
            payment: '/customer/payment',
            setDefaultPayment: '/customer/payment/default',
            syncFavorites: '/customer/syncFavorites'
        },
        orders: {
            list: '/order/list',
            draftOrder: '/order/draft'
        },
        discounts: {
            list: '/discounts'
        },
        events: {
            klickly: '/events/klickly'
        },
        product: {
            getBy: '/promotions/',
            similarProducts: '/recommendations'
        }
    }
};

export const fingerprintAPI = {
    enable: parseInt(process.env.ENABLE_FINGERPRINT_TRACKING, 10),
    baseURL: process.env.FINGERPRINT_API_URL,
    endPoints: {
        track: '/track'
    }
};

export const marketplace = {
    baseURL: process.env.MARKETPLACE_URL
};
