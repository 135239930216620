
export const FIRST_NAME = {
    type: 'name',
    name: 'firstName',
    label: 'first name',
    placeholder: 'First Name',
    value: null,
    rules: 'required|string|min:2'
};

export const LAST_NAME = {
    type: 'name',
    name: 'lastName',
    label: 'last name',
    placeholder: 'Last Name',
    value: null,
    rules: 'required|string|min:2'
};

export const ADDRESS = {
    type: 'address',
    name: 'address1',
    label: 'address',
    placeholder: 'Address',
    value: null,
    rules: 'required|string|min:3'
};

export const APARTMENT = {
    type: 'text',
    name: 'address2',
    label: 'apartment',
    placeholder: 'Apartment',
    value: null,
    rules: 'string'
};

export const STATE = {
    type: 'address',
    name: 'province',
    label: 'state',
    placeholder: 'State',
    value: null,
    rules: 'required|state'
};

export const CITY = {
    type: 'address',
    name: 'city',
    label: 'city',
    placeholder: 'City',
    value: null,
    rules: 'required|string|min:2'
};

export const ZIP_CODE = {
    type: 'address',
    name: 'zipCode',
    label: 'zip code',
    placeholder: 'Zip Code',
    value: null,
    rules: 'required|digits:5'
};

export const EMAIL = {
    type: 'email',
    name: 'email',
    label: 'Email Address',
    placeholder: 'Email',
    value: null,
    rules: 'required|email|string|min:3',
    errorMessage: 'Email address must be in the format someone@example.com'
};

export const PASSWORD = {
    type: 'password',
    name: 'password',
    label: 'Password',
    placeholder: 'Password',
    rules: 'required|string|between:6,25'
};

export const PHONE = {
    type: 'phone',
    name: 'phone',
    label: 'phone number',
    placeholder: 'Phone (for delivery)',
    value: null,
    rules: 'required|phone'
};

export const RESET_TOKEN = {
    type: 'text',
    name: 'resetToken',
    label: 'Reset Token',
    placeholder: 'Insert token',
    rules: 'required|checkResetToken'
};

export const CONFIRM_PASSWORD = {
    type: 'password',
    name: 'passwordConfirm',
    label: 'Password Confirmation',
    placeholder: 'Confirm Password',
    rules: 'required|string|same:password'
};

export const NAME_ON_CARD = {
    type: 'name',
    name: 'nameOnCard',
    label: 'Name on card',
    placeholder: 'Name on card',
    rules: 'required|string|min:3'
};

export const CARD_NUMBER = {
    name: 'cardToken',
    label: 'Card number',
    rules: 'required'
};

export const IS_EQUAL_WITH_SHIPPING = {
    type: 'checkbox',
    name: 'isEqualWithShipping',
    label: 'Billing address',
    placeholder: 'isEqualWithShipping'
};

export const SHIPPING_STEP_FIELDS = [
    ADDRESS,
    APARTMENT,
    CITY,
    FIRST_NAME,
    STATE,
    LAST_NAME,
    ZIP_CODE,
    PHONE
];

export const PROFILE_FORM_FIELDS = [
    FIRST_NAME,
    LAST_NAME,
    EMAIL
];

export const BILLING_ADDRESS_FIELDS = [
    IS_EQUAL_WITH_SHIPPING,
    NAME_ON_CARD,
    CARD_NUMBER,
    FIRST_NAME,
    LAST_NAME,
    ADDRESS,
    APARTMENT,
    ZIP_CODE,
    CITY,
    STATE
];

export const BILLING_ADDRESS_FIELDS_EDIT_MODE = [
    IS_EQUAL_WITH_SHIPPING,
    FIRST_NAME,
    LAST_NAME,
    ADDRESS,
    APARTMENT,
    ZIP_CODE,
    CITY,
    STATE
];

