import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import './index.scss';

@inject('StoreUser')
@observer
class CircleProgress extends Component {

    circleFill = null;
    radius = 90;

    fillProgress(value) {
        const circumference = 2 * Math.PI * this.radius;
        this.circleFill.style.strokeDasharray = circumference;
        this.circleFill.style.strokeDashoffset = circumference;

        for (let i=1; i<=value; i++) {
            setTimeout(() => {
                const progress = i / 100;
                if (this.circleFill) {
                    this.circleFill.style.strokeDashoffset = circumference * (1 - progress);
                }
            }, 20*i);
        }
    }

    componentDidMount() {
        this.fillProgress(50);
    }

    render() {
        return(
            <div className="circle-progress">
                <div className="circle-progress__value-container">
                    <img src={require('../../../images/quickTo/quickto-logo--small.svg')}
                        className="circle-progress__logo"
                        alt="quickto-logo"/>
                    <span className="circle-progress__value">{this.props.StoreUser.userCredits}</span>
                </div>
                <svg className="circle-progress__container" width="140" height="140" viewBox="0 0 200 200">
                    <circle className="circle-progress__bar"
                        cx="100"
                        cy="100"
                        r={this.radius}
                        strokeWidth="14" />
                    <circle
                        className="circle-progress__fill"
                        cx="100"
                        cy="100"
                        r={this.radius}
                        strokeWidth="14"
                        ref={ref => {
                            this.circleFill = ref;
                        }}/>
                </svg>
            </div>
        );
    }
}

export default CircleProgress;
