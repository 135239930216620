import React from 'react';
import './input.scss';

const Radio = ({ id, checked, onChange = () => null }) => {
    return (
        <div className="radio-input">
            <input
                id={id}
                type="radio"
                checked={checked}
                className="radio-input"
                onChange={onChange}
            />
            <label
                htmlFor={id}
                className="radio-input__label"
            />
        </div>
    );
};

export default Radio;
