import React, { Component, Fragment } from 'react';
import { observable } from "mobx";
import { observer, inject } from 'mobx-react';

import { ProductCard } from '../ProductCard';
import ProductService from '../../../services/product';
import productsLoader from '../../../helpers/productsLoader';
import TrackHelper from "../../../helpers/track";

import './similarProducts.scss';
import './productPlaceholder.scss';

const SIMILAR_PRODUCTS_LENGTH = 20;

@inject('StorePurchase')
@inject('StoreProducts')
@observer
class SimilarProducts extends Component {

    @observable similarProducts = [];
    @observable showSimilarSection = false;

    async componentDidMount() {
        const { recommendations } = await ProductService.getSimilarProducts(this.props.productId, this.props.accountId);
        if (recommendations && recommendations.length) {
            this.similarProducts = productsLoader.parseProducts(recommendations);

            TrackHelper.sendEvent('loaded', recommendations, 'similar-product');
            this.showSimilarSection = true;
        }
    }

    get productCardsFallback() {
        return Array(SIMILAR_PRODUCTS_LENGTH)
            .fill(0)
            .map((value, key) =>
                <section
                    key={`product-similar-placeholder-${key}`}
                    className="relative"
                    aria-label="Product image placeholder"
                >
                    <div className="product-card-link">
                        <div className="product-card">
                            <div className='product-placeholder product-placeholder--loading'>
                                <div className='img-placeholder'>
                                    <div className='img-placeholder__img'/>
                                </div>
                                <div className='content-placeholder'>
                                    <div className='line line--70' />
                                    <div className='line line--25' />
                                    <div className='line line--50' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
    }

    render() {
        return (
            <Fragment>
                {this.showSimilarSection ?
                    <div className="products-recommend-wrapper">
                        <p className="products-recommend__title">Similar products</p>
                        <div className="products-recommend">
                            <div className="products-recommend__overflow relative">
                                {
                                    this.similarProducts.length
                                        ? this.similarProducts.map((product, key) =>
                                            <ProductCard
                                                onClick={(product) => this.props.StorePurchase.handleSimilarProductClick(product)}
                                                key={`similar-product-card-${key}`}
                                                product={product}
                                                enableAddToFavorites={!this.props.StorePurchase.productInExpandableUnit}
                                                similarProduct={true}
                                            />
                                        )
                                        : this.productCardsFallback
                                }
                            </div>
                        </div>
                    </div> : null
                }
            </Fragment>
        );
    }
}

export default SimilarProducts;
