import { get } from 'lodash';
const KLICKLY_GA_ID = process.env.WIDGET_GA_ID;

export function gaPromotion(promotion, gaTrackingByAccount) {
    const gaTrackingMatch = gaTrackingByAccount.filter(trackingObject => promotion.account === trackingObject.accountId);
    const gaTrackingData = gaTrackingMatch.length ? gaTrackingMatch[0].gaTrackingData : {};

    const isEnabled = get(gaTrackingData, 'isEnabled', false);
    const sendToParam = [ KLICKLY_GA_ID ];

    if (isEnabled) {
        sendToParam.push(get(gaTrackingData, 'klicklyGaId', ''));
    }

    const eventParameters = {
        event_category: 'Klickly',
        send_page_view: false,
        event_label: get(gaTrackingData, 'campaignName', ''),
        non_interaction: true,
        send_to: sendToParam
    };

    return {
        recordView: () => {
            window['gtag']('event', 'Klickly View', eventParameters);
            return this;
        },
        recordClick: () => {
            window['gtag']('event', 'Klickly Click', eventParameters);
            return this;
        },
        recordClickThrough: () => {
            window['gtag']('event', 'Klickly Click-Through', eventParameters);
            return this;
        },
        recordAddToCart: () => {
            window['gtag']('event', 'Klickly Add-To-Cart', eventParameters);
            return this;
        }
    };
}
