import { observable, action } from 'mobx';
import * as isArray from 'lodash';

import productsLoader from '../../helpers/productsLoader';
import TrackHelper from '../../helpers/track';
import { GTM } from '../../utils/GTM';

class StoreProducts {
    productsPerPage = 24;
    @observable displayProductsLoader = true;
    @observable products = [];

    @action async loadProducts(forceRequest = false, category = -1, searchQuery = -1, nextPage = null) {
        if (forceRequest || (productsLoader.nextPage && productsLoader.isCanLoad)) {
            this.displayProductsLoader = true;
            if (nextPage === 1) {
                this.clearProducts();
            }

            try {
                const products = await productsLoader.submit(forceRequest, category, searchQuery, nextPage);
                if (isArray(products)) {
                    this.addProducts(products);
                    this.displayProductsLoader = false;
                }
            } catch (error) {
                // display error
            }
        }
    }

    @action proceedSearchCategory(category) {
        this.loadProducts(true, category, null, 1);
    }

    @action proceedSearchQuery(search) {
        this.loadProducts(true, null, search, 1);
    }

    @action addProducts(products) {
        const parsedProducts = productsLoader.parseProducts(products);
        this.products = [ ...this.products, ...parsedProducts ];

        TrackHelper.sendEvent('loaded', products);
        this.trackProductViewsGTM(parsedProducts);
    }

    @action clearProducts() {
        this.products = [];
    }

    trackProductViewsGTM(promotions) {
        const impressions = [];

        promotions.forEach((product) => {
            impressions.push({
                name: product.get('title'),
                id: product.get('externalId'),
                price: product.price,
                brand: product.get('brandName'),
                category: product.get('category')
            });
        });

        GTM.ProductImpressionEventGA.send({
            ecommerce: {
                currencyCode: 'USD',
                impressions
            }
        });
    }

}

export default StoreProducts;
