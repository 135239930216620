
import React, { Component } from 'react';
import { inject } from 'mobx-react';

import AuthService from '../../../services/auth';

@inject('StoreUser')
@inject('StoreFavorites')
class ComponentFacebook extends Component {

    openFacebookOAuthWindow = () => {
        this.props.toggleLoader(true);
        AuthService.openOAuthWindow('facebook', this.onCloseHandler);
    };

    onCloseHandler = async() => {
        try {
            const profile = await AuthService.checkAuth();

            this.props.StoreUser.setProfile(profile);
            await this.props.StoreFavorites.syncFavoritesProductsWithServer(true);

            this.props.toggleLoader(false);

            if (this.props.onSuccess) {
                this.props.onSuccess(profile);
            }
        } catch (error) {
            this.props.StoreUser.setProfile(null);
            this.props.toggleLoader(false);
        }
    };

    render() {
        const { text = 'Continue with Facebook' } = this.props;
        return (
            <button onClick={this.openFacebookOAuthWindow} className="auth__button auth__button_fb facebook">
                <img src={require('../../../images/facebook-logo.svg')} alt=""/>
                <span>{text}</span>
            </button>
        );
    }

}

export default ComponentFacebook;
