import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';

import { GTM } from '../../../utils/GTM';
import changeHead from '../../../helpers/changeHead';

const SEARCH_BUTTON_ICON = require('../../../images/mask.svg');

@inject('StoreApp')
@inject('StoreSearch')
@inject('StoreProducts')
@inject('StoreCategoryFilter')
@observer
class ComponentSearchForm extends Component {

    @action onFormSubmit(event) {
        event.preventDefault();
        const { StoreSearch, StoreProducts, StoreCategoryFilter, StoreApp } = this.props;

        StoreSearch.emptySearchPlaceholder = false;

        if (StoreSearch.searchQuery !== null && StoreSearch.searchQuery !== '') {
            GTM.SearchEvent.send({ search_string: StoreSearch.searchQuery });

            StoreProducts.proceedSearchQuery(StoreSearch.searchQuery);
            StoreCategoryFilter.clearFilterCategory();

            changeHead(null, StoreSearch.searchQuery);

            const url = `/search-results/?q=${StoreSearch.searchQuery}`;

            this.props.history.push(url);
            StoreApp.setLastURL(url);
        } else {
            StoreSearch.emptySearchPlaceholder = true;
        }
    }

    render() {
        const { StoreSearch } = this.props;

        return (
            <div className="market-place-search-block">
                <form className="market-place-search-form" onSubmit={(event) => this.onFormSubmit(event)}>
                    <div className="market-place-search-form__input-wrapper">
                        <input
                            name="search"
                            type="text"
                            value={StoreSearch.searchQuery}
                            placeholder={`${StoreSearch.emptySearchPlaceholder ? 'Please enter a search term' : 'Search'}`}
                            onChange={(event) => StoreSearch.searchQueryOnChange(event.target.value)}
                            onBlur={() => {
                                StoreSearch.emptySearchPlaceholder = false;
                                return true;
                            }}
                        />
                    </div>
                    <button className="market-place-search-form__submit search-submit" type="submit">
                        <img src={SEARCH_BUTTON_ICON} alt="search-button"/>
                    </button>
                </form>
            </div>
        );
    }
}
export default withRouter(ComponentSearchForm);
