import React from 'react';
import { observer, inject } from 'mobx-react';
import * as capitalize from "lodash/capitalize";
import { prepareImageUrl, roundToLocaleString } from '../../../../../../helpers/static';

const PurchasedProduct = inject("StorePurchase")(observer(({ StorePurchase }) => {
    const { product, checkout } = StorePurchase.store;

    return (
        <div className="purchase-product">
            <div className="purchase-product__img-wrapper">
                <img src={`${prepareImageUrl(product.get('image'), '_105x$1')}`} alt="product" className="purchase-product__img"/>
            </div>
            <div className="purchase-product__info">
                <div className="purchase-summary__row">
                    <span className="purchase-product__title">{ product.get('title') }</span>
                    <span className="purchase-product__price">{`$${roundToLocaleString(checkout.subtotalPrice)}`}</span>
                </div>
                <span className="purchase-product__brand">{ product.get('brandName') }</span>
                <div className="purchase-product__option-box">
                    {checkout.options.map((option) => {
                        return (
                            <span className="purchase-product__option" key={`option-${option.position}`}>
                                {capitalize(option.name)}: {checkout.variant.get(`option${option.position}`)}
                            </span>
                        );
                    })}
                    <span className="purchase-product__option">Qty: { checkout.qty }</span>
                    <span className="purchase-product__option">Product Category: { capitalize(product.get('category')) }</span>
                </div>
            </div>
        </div>
    );
}));

export default PurchasedProduct;
