
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Input from '../../ui/Input';
import Button from '../../ui/Button';

import FormBinder from '../../../helpers/formBinder';
import { RESET_TOKEN, PASSWORD, CONFIRM_PASSWORD } from '../../../constants/formFields';

import AuthService from '../../../services/auth';

@observer
class ResetForm extends Component {
    form = null;

    constructor(props) {
        super(props);

        this.form = new FormBinder([
            { ...RESET_TOKEN, label: '' },
            { ...PASSWORD, label: '' },
            { ...CONFIRM_PASSWORD, label: '' }
        ], {
            onSuccess: this.onSuccess.bind(this)
        });
    }

    async onSuccess(form) {
        this.props.toggleLoader(true);
        try {
            await AuthService.resetPassword(form.values());
            form.clear();
            this.props.changeStep('signin');
        } catch(error) {
            // PopUpStore.addError(error);
        }
        this.props.toggleLoader(false);
    }

    render() {
        const { form } = this;
        return (
            <form className="reset-your-password" onSubmit={form.onSubmit}>
                <div className="reset-your-password__title">Reset your password</div>
                <Input
                    {...form.$('resetToken').bind()}
                    error={form.$('resetToken').error}
                    isShowError={false}
                />
                <Input
                    {...form.$('password').bind()}
                    error={form.$('password').error}
                />
                <Input
                    {...form.$('passwordConfirm').bind()}
                    error={form.$('passwordConfirm').error}
                    isShowError={false}
                />

                <Button
                    type="submit"
                    label="Reset"
                    className="dark-pink"
                    disabled={form.isPristine || !form.isValid}
                />
            </form>
        );
    }
}

export default ResetForm;
