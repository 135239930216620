
import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import * as find from 'lodash/find';
import * as get from 'lodash/get';
import { roundToLocaleString } from '../../../../helpers/static';
import * as isEmpty from 'lodash/isEmpty';

import FormBinder from '../../../../helpers/formBinder';
import { PASSWORD, FIRST_NAME, LAST_NAME } from '../../../../constants/formFields';
import statesAbbr from '../../../../constants/statesAbbr';
import Input from '../../../../components/ui/Input';
import Button from '../../../../components/ui/Button';
import CustomerService from '../../../../services/customer';
import AuthService from '../../../../services/auth';
import { GTM } from '../../../../utils/GTM';
import QuicktoHelper from '../../../../helpers/quickto';
import customerFlow from '../../../../helpers/customerFlow';
import PurchasedProduct from './components/PurchasedProduct';
import TextRow from './components/TextRow';

import './index.scss';
import {getProjectData} from "../../../../projectMap";

const CHECK_MARK_ICON = require('../../../../images/check-mark.svg');

@inject('StoreApp')
@inject('StorePurchase')
@inject('StoreUser')
@inject('StoreFavorites')
@inject('StoreNotifications')
@observer
class OrderCompleted extends Component {
    form = null;
    shippingAddress = null;

    constructor(props) {
        super(props);
        this.shippingAddress = find(get(this.props.StorePurchase, 'store.profile.shippingAddresses', null), { isDefault: true }) || null;

        const formFields = [
            { ...PASSWORD, label: '' }
        ];

        if (!this.shippingAddress) {
            formFields.push({ ...FIRST_NAME, label: '' });
            formFields.push({ ...LAST_NAME, label: '' });
        }

        this.form = new FormBinder(formFields, {
            onSuccess: this.onSuccess.bind(this)
        });
    }

    handleCloseModal = () => {
        this.props.StorePurchase.handleCloseModal();
    };

    async onSuccess(form) {
        this.props.StorePurchase.toggleLoader(true);

        const values = form.values();

        const { profile, result } = this.props.StorePurchase.store;

        const data = {
            firstName: get(this.shippingAddress, 'firstName'),
            lastName: get(this.shippingAddress, 'lastName'),
            email: profile.email,
            orderId: result.orderId,
            stripeCustomer: result.stripeCustomer
        };

        let serverProfile = null;

        try {
            serverProfile = await AuthService.register({ defaultCoinsEnabled: QuicktoHelper.paramsToRedirect.coins, ...data, ...values });
        } catch (error) {
            this.props.StorePurchase.toggleLoader(false);
            this.props.StoreNotifications.addError(error);
            return;
        }

        GTM.CompleteRegistrationEvent.send({
            content_name: serverProfile.email
        });

        this.props.StoreUser.setProfile(serverProfile);
        this.props.StoreFavorites.syncFavoritesProductsWithServer(true);

        if (!isEmpty(this.shippingAddress)) {
            const addresses = await CustomerService.addAddress(this.shippingAddress);
            this.props.StoreUser.updateItem('shippingAddresses', addresses);
        }

        this.props.StorePurchase.toggleLoader(false);

        this.handleCloseModal();
    }

    render() {
        const { StorePurchase, StoreApp, StoreUser } = this.props;
        const { checkout, profile } = StorePurchase.getPayload();
        const shippingAddress = profile.shippingAddresses
            ? profile.shippingAddresses.find((address) => address.isDefault)
            : null;
        const card = profile.cards
            ? profile.cards.find((card) => card.isDefault)
            : null;
        const isQuickToBalance = customerFlow.isFlowEnabled && StorePurchase.memberDiscountPrice > 0;
        const mailto = getProjectData('mailto');

        return (
            <div className="purchase-complete">
                <div className="purchase-complete__content">

                    <div className="purchase-thanks">
                        <img src={CHECK_MARK_ICON} alt="check icon" className="purchase-thanks__icon"/>
                        <div className="purchase-thanks__content">
                            <h3 className="purchase-thanks__heading">Thank you for your purchase!</h3>
                            <p className="purchase-thanks__text">
                                Your order is being sent to {StorePurchase.store.result.brandName} for processing.
                                If you do not receive a confirmation within 24 hours,
                                please contact <a className="purchase-thanks__link" href={`mailto:${mailto}`}>{mailto}</a>
                            </p>
                            <button className="purchase-thanks__action" onClick={() => {
                                customerFlow.disableFlow();
                                StorePurchase.handleCloseModal();
                            }}>Shop other products</button>
                        </div>
                    </div>

                    <div className="purchase-info">
                        <div className="purchase-complete__item">
                            <h5 className="purchase-complete__heading">order details</h5>
                            <div className="purchase-info__item">
                                <h4 className="purchase-info__heading">Payment Method</h4>
                                {isQuickToBalance && <TextRow prefix="info" label={`Paid with your ${getProjectData('title')} balance`} value={`$${roundToLocaleString(checkout.memberDiscountPrice)}`}/> }
                                <TextRow
                                    prefix="info"
                                    label={`Charged to your Credit Card ${card ? `(${card.last4})` : ''}`}
                                    value={`$${roundToLocaleString(checkout.totalPurchasePrice)}`}/>
                            </div>

                            {
                                shippingAddress &&
                                <div className="purchase-info__item">
                                    <h4 className="purchase-info__heading">Shipping Address</h4>
                                    <p className="purchase-info__text">
                                        {shippingAddress.firstName} {shippingAddress.lastName}
                                    </p>
                                    <p className="purchase-info__text">
                                        {shippingAddress.address2} {shippingAddress.address1}
                                    </p>
                                    <p className="purchase-info__text">
                                        {shippingAddress.city}, { statesAbbr.find(item => item.value === shippingAddress.province).label }, {shippingAddress.zipCode}
                                    </p>
                                </div>
                            }
                        </div>

                        {
                            StoreApp.isMobile &&
                            <div className="purchase-complete__item purchase-complete__item--summary">
                                <h5 className="purchase-complete__heading">order summary</h5>
                                <PurchasedProduct/>
                                <div className="purchase-summary">
                                    <TextRow prefix="summary" label="Subtotal" value={`$${roundToLocaleString(checkout.subtotalPrice)}`}/>
                                    <TextRow prefix="summary" label="Shipping" value={`$${roundToLocaleString(checkout.shippingPrice)}`}/>
                                    {/* There is "Points bonus" instead of "Tax" in designs */}
                                    <TextRow prefix="summary" label="Tax" value={`$${roundToLocaleString(checkout.taxPrice)}`}/>
                                    <TextRow prefix="summary" label="Total" value={`$${roundToLocaleString(checkout.totalPurchasePrice)}`} extraClass="total"/>
                                    {/* This is mock data */}
                                    {isQuickToBalance && <TextRow prefix="info" label={`Paid with your ${getProjectData('title')} balance`} value={`$${roundToLocaleString(checkout.memberDiscountPrice)}`}/>}

                                    <TextRow prefix="info" label={`Charged to your Credit Card ${card ? `(${card.last4})` : ''}`} value={`$${roundToLocaleString(checkout.totalPurchasePrice)}`}/>
                                </div>
                            </div>
                        }

                        <div className="purchase-complete__item">
                            <h5 className="purchase-complete__heading">Customer service</h5>
                            <div className="purchase-info__item purchase-info__item--large">
                                <div>
                                    <p className="purchase-info__text">
                                        For billing enquiries, please contact
                                        the <a href={`mailto:${mailto}`} className="purchase-info__link">{getProjectData('title')} team</a>.
                                    </p>
                                    <p className="purchase-info__text">
                                        For customer service enquiries, please contact {StorePurchase.store.result.brandName} directly:
                                    </p>
                                </div>

                                {/* This is mock data */}
                                <ul className="purchase-info__list">
                                    <li>Email - <a className="purchase-info__link" href={`mailto:${StorePurchase.store.result.brandEmail}`}>{StorePurchase.store.result.brandEmail}</a></li>
                                    <li>Phone - {StorePurchase.store.result.brandPhone}</li>
                                    <li>Site  - <a className="purchase-info__link" href={`//${StorePurchase.store.result.brandDomain}`}>{StorePurchase.store.result.brandDomain}</a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !StoreUser.isAuthenticated ?
                        <Fragment>
                            <div className="purchase-complete__divider" />
                            <div className="purchase-complete-form">
                                <form onSubmit={this.form.onSubmit}>
                                    <p className="purchase-complete-form__title">Save your info for next time?</p>

                                    {
                                        !this.shippingAddress ?
                                            <Fragment>
                                                <Input
                                                    {...this.form.$('firstName').bind() }
                                                    error={this.form.$('firstName').error}
                                                />

                                                <Input
                                                    {...this.form.$('lastName').bind() }
                                                    error={this.form.$('lastName').error}
                                                />
                                            </Fragment>
                                            : null
                                    }

                                    <Input
                                        {...this.form.$('password').bind() }
                                        error={this.form.$('password').error}
                                    />

                                    <Button
                                        type="submit"
                                        label="Save"
                                        onClick={this.form.onSubmit}
                                        className="common-button dark-pink"
                                        disabled={this.form.isPristine || !this.form.isValid}
                                    />

                                    <span className="purchase-complete-form__link"
                                        onClick={this.handleCloseModal}>
                                          No thanks. Continue shopping.
                                    </span>
                                </form>
                            </div>
                        </Fragment>
                        : null
                }
            </div>
        );
    }

}

export default OrderCompleted;
