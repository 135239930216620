class EventListener {
    mainClass = null;
    action = null;

    onClickHandler = (event) => {
        this.onClick(event);
    };

    addClick(mainClass, action) {
        this.mainClass = mainClass;
        this.action = action;
        document.addEventListener('click', this.onClickHandler);
    }

    removeClick() {
        document.removeEventListener('click', this.onClickHandler);
        this.mainClass = null;
        this.action = null;
    }

    onClick(event) {
        if (!event.target.className.includes(this.mainClass)) {
            document.removeEventListener('click', this.onClickHandler);
            if (this.action && typeof this.action === 'function') {
                this.action();
            }
        }
    }
}

export default EventListener;
