import Request from '../utils/Request';
import { shopAPI } from '../config/api';
import QuicktoHelper from '../helpers/quickto';
import customerFlow from '../helpers/customerFlow';

class AuthService {
    path = shopAPI.endPoints.auth;
    oauthWindow = null;

    checkAuth() {
        return Request.get(this.path.checkAuth);
    }

    login(data) {
        return Request.post(this.path.login, data);
    }

    logout() {
        return Request.post(this.path.logout);
    }

    resetPassword(data) {
        return Request.post(this.path.resetPassword, data);
    }

    forgotPassword(data) {
        return Request.post(this.path.forgotPassword, data);
    }

    verifyToken(token) {
        return Request.get(`${this.path.verifyToken}?resetToken=${token}`);
    }

    register(data) {
        return Request.post(this.path.register, data);
    }

    openOAuthWindow(provider, closeHandler = null) {
        if (customerFlow.isFreeOfferFlow) {
            QuicktoHelper.enableParam('coins');
        }

        const authUrl = `${shopAPI.baseURL}/auth/${provider}?defaultCoinsEnabled=${QuicktoHelper.paramsToRedirect.coins}`;
        this.oauthWindow = window.open(authUrl, 'facebook_login', 'width=500,height=300');
        this.oauthWindow.focus();
        this.oauthWindow.onload = () => this.oauthWindow.close();
        setTimeout(this.checkOAuthWindow.bind(this, closeHandler), 1000);
    }

    checkOAuthWindow(closeHandler) {
        if (this.oauthWindow && this.oauthWindow.closed) {
            if (closeHandler && typeof closeHandler === 'function') {
                closeHandler();
            } else {
                window.location.assign('/');
            }
        } else {
            setTimeout(this.checkOAuthWindow.bind(this, closeHandler), 1000);
        }
    }
}

const instance = new AuthService();
export default instance;
