import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { Router, Switch, Route } from 'react-router-dom';

import stores from './stores';
import * as pages from './pages';
import ProductLanding from './components/marketplace/QuickToProductLanding';

import App from './App';
import './styles/index.scss';

const history = createBrowserHistory();

const elements =
    <Provider {...stores}>
        <Router history={history}>
            <Switch>
                <App>
                    <Switch>
                        <Route exact path="/" component={pages.PageQuickToMainPage}/>
                        <Route path="/land" component={pages.PageQuickToLand}/>
                        <Route path="/freeoffer/:productId" component={ProductLanding}/>
                        <Route path="/freeproduct/:productId" component={ProductLanding}/>
                        <Route path="/shipfree/:productId" component={ProductLanding}/>
                        <Route path="/giveaway/:productId" component={ProductLanding}/>
                        <Route path="/productland/:productId" component={ProductLanding}/>
                        <Route path="/productlander/:productId" component={ProductLanding}/>
                        <Route path="/settings" component={pages.PageSettings}/>
                        <Route path="/:categoryName" component={pages.PageMarketplace}/>
                    </Switch>
                </App>
            </Switch>
        </Router>
    </Provider>
;

ReactDOM.render(elements, document.getElementById('klickly-marketplace-root'));
