import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Button from '../../ui/Button';
import BrandsCarousel from '../BrandsCarousel';
import { quickToBuy, quickToCollect, quickToChoose } from '../../../images/quickTo';
import QuicktoHelper from '../../../helpers/quickto';
import './index.scss';

const bannerItemsData = [
    {
        icon: quickToBuy,
        title: 'Shop',
        subtitle: 'Buy items just like anywhere else.'
    },
    {
        icon: quickToCollect,
        title: 'Earn',
        subtitle: 'Get quickto coins on every purchase.'
    },
    {
        icon: quickToChoose,
        title: 'Spend',
        subtitle: 'Use coins just like cash on anything in the store.'
    }
];

const QuickToBanner = inject(stores => ({ StoreApp: stores.StoreApp, StoreFavorites: stores.StoreFavorites }))((props) => {
    const { title = 'Get paid to shop!', hasExtraClass = false, hasSubtitle = false } = props;
    const items = bannerItemsData.map(({ icon, title, subtitle }, idx) => {
        return(
            <div className="qt-banner-item" key={`banner-item-${idx}`}>
                <img src={icon} alt="" className="qt-banner-item__icon"/>
                <div className="qt-banner-item__text">
                    <h5 className="qt-banner-item__title">{ title }</h5>
                    <h6 className="qt-banner-item__subtitle">{ subtitle }</h6>
                </div>
            </div>
        );
    });

    return(
        <div className={classnames('qt-banner', {'qt-banner--cut-bg': hasExtraClass})}>
            <div className="qt-banner-main">
                <div className="qt-banner-main__inner">
                    <h1 className="qt-banner-main__title">{title}</h1>
                    {hasSubtitle && <h3 className="qt-banner-main__subtitle">Your first gift is on us!</h3>}
                    <Button
                        onClick={() => {
                            if (QuicktoHelper.paramsToRedirect.gift) {
                                props.StoreApp.creditsToggleMode('gift');
                                props.StoreFavorites.creditsToggleCoinBlock(true);
                            } else {
                                props.StoreApp.creditsToggleMode('buy');
                            }

                            props.history.push(`/${QuicktoHelper.paramsToRedirect.category}`);
                        }}
                        className="dark-pink qt-banner__button"
                        label="Shop Now"
                    />
                </div>
            </div>
            <div className="qt-banner-footer">
                <div className="qt-banner__how-it-works">
                    <h4 className="qt-banner-footer__title">How It Works</h4>
                    <div className="qt-banner-item-list">
                        { items }
                    </div>
                </div>
                <div className="qt-banner-footer__divider"/>
                <div className="qt-banner__brands">
                    <h4 className="qt-banner-footer__title">Shop & Get Gifts From Brands Like</h4>
                    <BrandsCarousel/>
                </div>
            </div>
        </div>
    );
});

export default withRouter(QuickToBanner);
