import StoreApp from './app';
import StoreUser from './user';
import StoreDiscount from './discount';
import StoreFavorites from './favorites';
import StoreProducts from './products';
import StoreCategoryFilter from './categoryFilter';
import StoreSearch from './search';
import StoreNotifications from './notifications';
import StorePurchase from './purchase';
import StoreAsidePanel from './asidePanel';
import StoreTutorial from './tutorial';
import StoreCheckout from './checkout';

class StoreRoot {
    StoreApp = new StoreApp();
    StoreUser = new StoreUser();
    StoreDiscount = new StoreDiscount();
    StoreFavorites = new StoreFavorites();
    StoreProducts = new StoreProducts();
    StoreCategoryFilter = new StoreCategoryFilter();
    StoreSearch = new StoreSearch();
    StoreNotifications = new StoreNotifications();
    StorePurchase = new StorePurchase();
    StoreAsidePanel = new StoreAsidePanel();
    StoreTutorial = new StoreTutorial();
    StoreCheckout = new StoreCheckout();
}

export default new StoreRoot();
