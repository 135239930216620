import React from 'react';

const TextRow = ({ prefix, label, value, extraClass }) => {
    return(
        <div className={`purchase-${prefix}__row ${extraClass}`}>
            <span className={`purchase-${prefix}__text`}>{ label }</span>
            <span className={`purchase-${prefix}__text`}>{ value }</span>
        </div>
    );
};

export default TextRow;
