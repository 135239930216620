import React from 'react';
import Slider from 'react-slick';
import { sliderArrowLeft, sliderArrowRight, brandLogos } from "../../../images/quickTo";
import './index.scss';

const PrevArrow= (props) =>
    <div onClick={props.onClick} className="brands-carousel__prev">
        <img src={sliderArrowLeft} alt="arrow prev"/>
    </div>;

const NextArrow = (props) =>
    <div onClick={props.onClick} className="brands-carousel__next">
        <img src={sliderArrowRight} alt="arrow next"/>
    </div>;

const carouselSlides = brandLogos.map((src, idx) =>
    <div className="brands-carousel__item" key={`brand-logo-${idx}`}>
        <img src={src} alt="logo"/>
    </div>
);

const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: 'brands-carousel',
    children: carouselSlides,
    responsive: [
        {
            breakpoint: 860,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 668,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]
};

const QuickToCarousel = () => {
    return <Slider {...carouselSettings}/>;
};

export default QuickToCarousel;
