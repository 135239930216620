
import * as get from 'lodash/get';
import * as sortBy from 'lodash/sortBy';
import { computed, action } from 'mobx';

import StoreRoot from '../stores';
import { discountFormula } from '../helpers/static';
import ProductVariantModel from './productVariant';

class ProductModel {

    object = null;
    firstVariant = null;

    constructor(object) {
        this.object = object;

        this.object.variants = sortBy(this.object.variants, 'price');
        this.object.image = get(this.object, 'images[0].src', '//placehold.it/200x120');

        const options = get(this.object, 'options', []).filter((option) => {
            return !(option.name === 'Title' && option.values[0] === 'Default Title');
        });

        options.push({
            name: 'Qty',
            position: 0,
            selected: 1
        });

        this.object.options = options;

        this.firstVariant = new ProductVariantModel(get(this.object, 'variants[0]', {}), this.maxQty.bind(this), this.__calculateKlicklyPrice.bind(this));

        this.object.creditsEarned = this.firstVariant.creditsEarned;
        this.object.creditsRequired = this.firstVariant.creditsRequired;

        if (this.object.shopifyOriginalPrice <= this.object.shopifyPrice) {
            this.object.shopifyOriginalPrice = 0;
        }
    }

    get(field, defaultValue = '') {
        return get(this.object, field, defaultValue);
    }

    get id() {
        return get(this.object, 'id', null);
    }

    get options() {
        return this.get('options', []);
    }

    @action memberDiscountPrice(variantPrice, variantOriginalPrice, qty, shippingPrice) {
        if (!StoreRoot.StoreUser.isAuthenticated) {
            return 0;
        }

        const discountPrices = {
            price: variantPrice * qty,
            originalPrice: (variantOriginalPrice || 0) * qty
        };

        let savePrice = discountPrices.originalPrice > discountPrices.price ? discountPrices.originalPrice - discountPrices.price : 0;

        if (StoreRoot.StoreDiscount.isFreeShipping) {
            savePrice += shippingPrice;
        }

        return savePrice;
    }

    @computed get variants() {
        const variants = this.get('variants', []);

        return variants.map((variant) => {
            return new ProductVariantModel(variant, this.maxQty.bind(this), this.__calculateKlicklyPrice.bind(this));
        });
    }

    @action maxQty(qty) {
        if (this.appliedDiscount && this.appliedDiscount.qtyRestriction && this.appliedDiscount.qtyRestriction < qty) {
            return this.appliedDiscount.qtyRestriction;
        }

        return qty;
    }

    @computed get appliedDiscount() {
        const { discount } = StoreRoot.StoreDiscount;
        if (discount) {
            if (discount.type === 'usual') {
                return discount;
            } else if (discount.type === 'favorites') {
                const difference = discount.amountOfProducts - StoreRoot.StoreFavorites.items.length;

                if (difference <= 0) {
                    return discount;
                }
            }
        }

        return null;
    }

    __calculateKlicklyPrice(price) {
        if (this.appliedDiscount) {
            return discountFormula(price, this.appliedDiscount.rate);
        }

        return null;
    }

    @computed get price() {
        return this.firstVariant.price;
    }

    @computed get originalPrice() {
        return this.firstVariant.originalPrice;
    }

    @action updateWithStreamlinedParams(params) {
        this.object.variants = this.object.variants.filter(variant => variant.title === params.strictVariant);
        this.object.options[0].values = this.object.options[0].values.filter(value => value === params.strictVariant);

        this.firstVariant = new ProductVariantModel(get(this.object, 'variants[0]', {}), this.maxQty.bind(this), this.__calculateKlicklyPrice.bind(this));

        this.object.creditsEarned = this.firstVariant.creditsEarned;
        this.object.creditsRequired = this.firstVariant.creditsRequired;

        if (this.object.shopifyOriginalPrice <= this.object.shopifyPrice) {
            this.object.shopifyOriginalPrice = 0;
        }
    }

}

export default ProductModel;
