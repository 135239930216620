
import * as capitalize from 'lodash/capitalize';
import StoreRoot from '../stores';

const changeHead = (category = null, searchQuery = null, routeType = 'Marketplace') => {
    const { StoreApp } = StoreRoot;

    if (category) {
        StoreApp.changeHtmlHead(category.text, [ 'newest', 'popular', 'under10' ].indexOf(category.key) !== -1 ?
            `View ${category.text} products on Klickly`
            :
            `View products related to ${category.text} on Klickly`
        );
    } else if (searchQuery) {
        StoreApp.changeHtmlHead(searchQuery, `View products related to ${searchQuery} on Klickly`);
    } else {
        StoreApp.changeHtmlHead(capitalize(routeType));
    }
};

export default changeHead;
