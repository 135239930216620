import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import QuickCoin from "../QuickCoin";
import QuickToCart from "../QuickToCart";
import ComponentFavorites from '../Favorites';
import './index.scss';

@inject('StoreAsidePanel')
@inject('StoreApp')
@observer
class AsidePanel extends Component {
    componentRef = null;

    setComponentWidth = () => {
        this.props.setComponentWidth(this.componentRef.getBoundingClientRect().width);
    };

    componentDidMount() {
        this.setComponentWidth();
        window.addEventListener('resize', this.setComponentWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setComponentWidth);
    }

    render() {
        const { StoreAsidePanel: { display }, StoreApp, isFixed } = this.props;
        return (
            <aside className={classNames('mp-aside-panel',
                {'mp-aside-panel--fixed': isFixed},
                {'mp-aside-panel--full-width': display.favorites && StoreApp.isMobile})
            } ref={(ref) => {
                this.componentRef = ref;
            }}>
                {display.coins && <QuickCoin/>}
                {display.cart && <QuickToCart/>}
                {display.favorites && <ComponentFavorites/>}
            </aside>
        );
    }
}

export default AsidePanel;
