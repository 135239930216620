
import Request from '../utils/Request';
import { shopAPI } from '../config/api';

class DiscountsService {
    path = shopAPI.endPoints.discounts;

    getList() {
        return Request.get(this.path.list);
    }
}

const instance = new DiscountsService();
export default instance;
