import React, { Component } from 'react';
import { inject } from 'mobx-react';

import ModulePurchaseModal from '../../modules/PurchaseModal';
import { sanitizeProductName } from "../../helpers/static";

@inject('StoreApp')
export class PageProduct extends Component {

    closeModal() {
        this.props.history.push(this.props.StoreApp.lastMarketplaceURL);
    }

    render() {
        const { match: { params: { productId } } } = this.props;

        return <ModulePurchaseModal
            purchaseOn={`${process.env.INSTANCE_TYPE}`}
            productId={productId}
            onProductLoaded={(product) => this.props.StoreApp.changeHtmlHead(product.get('title', 'Sorry, nothing found'), product.get('description', product.get('title')))}
            onSimilarProductClick={(product) => this.props.history.push(`/${sanitizeProductName(product.get('title'))}/${product.get('id')}`)}
            onModalStepChange={(product, step) => this.props.history.push(`/${sanitizeProductName(product.get('title'))}/${product.get('id')}/${step}`)}
            onProductFetchError={() => this.closeModal()}
            onClose={() => this.closeModal()}
        />;
    }
}
