
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';

import AuthService from '../../../services/auth';
import Loader from '../../ui/Loader';

@inject('StoreUser')
@inject('StoreFavorites')
@observer
class RequireAuthenticationHOC extends Component {

    @observable isLoading = true;
    @action changeLoading(status) {
        this.isLoading = status;
    }

    async componentDidMount () {
        this.changeLoading(true);
        try {
            if (this.props.StoreUser.profile === null) {
                const profile = await AuthService.checkAuth();
                this.props.StoreUser.setProfile(profile);
                this.props.StoreFavorites.syncFavoritesProductsWithServer(true);
            }

            this.changeLoading(false);
        } catch (error) {
            this.props.StoreUser.setProfile(null);
            this.props.history.push('/');
        }
    }

    render() {
        return this.isLoading ? <Loader display={true} /> : this.props.children;
    }
}

export default withRouter(RequireAuthenticationHOC);
