import React, { Component } from 'react';
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import classNames from 'classnames';
import * as isEmpty from 'lodash/isEmpty';

import RenderIf from '../../../helpers/renderIf';

import './accordionWithForm.scss';

export class Accordion extends Component {
    render() {
        return (
            <div className="accordion">
                <div>
                    { this.props.children }
                </div>
            </div>
        );
    }
}

export const Headline = ({ title, children }) => {
    return(
        <div className="headline">
            <span className="headline__title">{ title }</span>
            { children }
        </div>
    );
};

export const ActionButton = ({ className = '', name, onClick }) => {
    return (
        <span className={`edit-button ${className}`} onClick={ onClick }>{name}</span>
    );
};

@observer
export class AccordionItems extends Component {
    @observable isOpened = false;
    @action toggleIsOpened = () => {
        this.isOpened = !this.isOpened;
    };

    render() {
        const {
            itemsList,
            listItemComponent: ListItemComponent,
            onClickAddNew,
            onClickMakeDefault,
            labelPart,
            textPart,
            collapsedAbility = true
        } = this.props;

        return (
            <div>
                {
                    !isEmpty(itemsList) ?
                        <div className="accordion-default-item"
                            onClick={this.toggleIsOpened}>
                            <ListItemComponent {...itemsList.find(item => item.isDefault)} />
                            <RenderIf condition={collapsedAbility}>
                                <span className={classNames('accordion-arrow', {'opened': this.isOpened})}/>
                            </RenderIf>
                        </div>
                        :
                        <EmptyState labelPart={labelPart} textPart={textPart} />
                }
                <div className={classNames('accordion-items-list', { 'list-opened': this.isOpened })}>
                    {
                        itemsList.map((item) =>
                            <ListItemComponent
                                key={`list-component-${item._id}`}
                                {...item}
                                className={classNames({'default': item.isDefault})}
                                onClick={onClickMakeDefault}
                            />
                        )
                    }
                    <span onClick={onClickAddNew}>Add new</span>
                </div>
            </div>
        );
    }
}

export const EmptyState = ({ labelPart, textPart }) => {
    const label = `No ${labelPart} on file`;
    const text = `Please click 'Add' to enter a new ${textPart}`;
    return (
        <div className="accordion-address empty-state">
            <div className="shipping-tab__name">{label}</div>
            <div className="shipping-tab__address">{text}</div>
        </div>
    );
};
