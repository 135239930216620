import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter} from 'react-router-dom';
import { localStore } from '../../../helpers/browserStores';
import Tour from 'reactour';
import { sanitizeProductName } from '../../../helpers/static';
import './index.scss';
import {getProjectData} from "../../../projectMap";

const ModalAfterTutorial = withRouter(inject('StoreAsidePanel', 'StoreCheckout', 'StorePurchase', 'StoreTutorial')(({ StoreAsidePanel, StoreTutorial, StoreCheckout, StorePurchase, history }) =>
    <div className="tutorial-popup">
        <div className="tutorial-popup__badge">3 of 3</div>
        <div className="tutorial-popup__content">
            <p className="tutorial-popup__text">Remember, you'll need to pay shipping on your free gift.</p>
            <p className="tutorial-popup__text">Want FREE SHIPPING? Shop for another item, and we'll cover the cost of shipping both!</p>
            <div className="tutorial-popup__action-box">
                {/*go to popular*/}
                <button className="tutorial-popup__action" onClick={() => {
                    StoreTutorial.close();
                    const product = StoreCheckout.list[0];
                    history.push(`/${sanitizeProductName(product.get('title'))}/${product.get('id')}`);
                }}>I'll pay shipping for my gift</button>
                {/*go to step 2 purchase modal*/}
                <button
                    onClick={() => {
                        StoreTutorial.close();
                        history.push('/popular');
                        StoreAsidePanel.toggleElement('coins');
                    }}
                    className="tutorial-popup__action tutorial-popup__action--secondary"
                >I'll shop to get FREE SHIPPING</button>
            </div>
        </div>
    </div>
));

@inject('StoreApp')
@inject('StoreTutorial')
@observer
class QuickToTutorial extends Component {
    steps = [
        {
            selector: '.quickto-cart',
            content: () =>
                <>
                    <p className="tutorial__text">
                        Thanks for signing up!
                    </p>
                    <br/>
                    <p className="tutorial__text">
                        We've added your gift to your shopping bag!
                    </p>
                </>,
            stepInteraction: false
        },
        {
            selector: '.mp-header-coins',
            content: () =>
                <>
                    <p className="tutorial__text">
                        So you can get your gift for free, we’ve added 1000 coins to your account.
                    </p>
                    <p className="tutorial__text">
                        You can use these coins just like cash. Click
                        the <img className="tutorial__icon" src={getProjectData('coins-empty') } alt="logo"/> to see the coin panel.
                    </p>
                </>,
            stepInteraction: true
        }
    ];

    render() {
        const isTutorialPassed = localStore.get('tutorialPassed');
        const { StoreTutorial, StoreApp } = this.props;

        return (
            <div>
                { StoreApp.creditsCoinEnabled && !isTutorialPassed ?
                    <Fragment>
                        <Tour
                            getCurrentStep={curr => {
                                StoreTutorial.syncStep(curr);
                            }}
                            className="tutorial"
                            maskClassName='tutorial__mask'
                            accentColor="#4a4a4a"
                            disableDotsNavigation={true}
                            showNavigationNumber={false}
                            closeWithMask={false}
                            rounded={4}
                            badgeContent={(curr, total) => `${curr} of ${total + 1}`}
                            steps={this.steps}
                            showButtons={StoreTutorial.stepToGo === 0}
                            showNavigation={false}
                            showCloseButton={false}
                            isOpen={StoreTutorial.isOpen}
                            goToStep={StoreTutorial.stepToGo}
                            onAfterOpen={() => (document.body.style.overflowY = 'hidden')}
                            disableKeyboardNavigation={[ 'esc' ]}
                        />
                        {StoreTutorial.isModalShown && <div className="overlay"><ModalAfterTutorial/></div>}
                    </Fragment>
                    : null
                }
            </div>
        );
    }
}

export default QuickToTutorial;
