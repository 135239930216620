import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Input from '../../ui/Input';
import Button from '../../ui/Button';

import FormBinder from '../../../helpers/formBinder';
import { EMAIL } from '../../../constants/formFields';

import AuthService from '../../../services/auth';

@observer
class ForgotForm extends Component {
    form = null;

    constructor(props) {
        super(props);

        this.form = new FormBinder([
            { ...EMAIL, label: '' }
        ], {
            onSuccess: this.onSuccess.bind(this)
        }, {
            showErrorsOnChange: true
        });
    }

    async onSuccess(form) {
        this.props.toggleLoader(true);
        try {
            await AuthService.forgotPassword(form.values());
            form.clear();
            this.props.changeStep('reset');
        } catch(error) {
            form.$('email').invalidate('Email doesn\'t exist');
        }
        this.props.toggleLoader(false);
    }

    render() {
        const { form } = this;

        return (
            <form className="sign-reset-form" onSubmit={form.onSubmit}>
                <div>
                    <div className="sign-reset-form__title">Reset your password</div>
                    <div className="sign-reset-form__message">Enter your email address, and we’ll send you a link so you can reset your password.</div>
                    <Input
                        {...form.$('email').bind()}
                        error={form.$('email').error}
                    />
                </div>

                <Button
                    type="submit"
                    label="Submit"
                    className="dark-pink"
                    disabled={form.isPristine || !form.isValid}
                />
                <div className="forgot-link" onClick={() => this.props.changeStep('signin')}>Sign in</div>
            </form>
        );
    }
}

export default ForgotForm;
