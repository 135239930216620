import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import {getProjectData} from "../../../projectMap";

@inject('StoreApp')
@observer
class ComponentHtmlHead extends Component {
    render() {
        const { StoreApp } = this.props;

        if (StoreApp.title || StoreApp.description) {
            return (
                <Helmet>
                    <meta charSet="utf-8"/>
                    {StoreApp.title && <title>{`${getProjectData('title')} | ${StoreApp.title}`}</title>}
                    {StoreApp.description && <meta name="description" content={StoreApp.description}/>}
                </Helmet>
            );
        }

        return null;
    }
}

export default ComponentHtmlHead;
