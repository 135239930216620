import { action, observable } from 'mobx';
import * as remove from 'lodash/remove';
import * as each from 'lodash/each';
import * as get from 'lodash/get';

class StoreNotifications {
    errorMessages = {
        'accepted': 'You must indicate that you have read and agree to the Terms of Service and Privacy Policy',
        'required': ':attribute can\'t be blank',
        'email': 'Email address must be in the format someone@example.com',
        'min': ':attribute must be at least :min characters',
        'digits': ':attribute must be :digits digits',
        'integer': ':attribute must be an integer'
    };

    @observable items = [];

    @action add(data) {
        const newPopup = new ItemModel(data);
        this.items.push(newPopup);
    }

    @action addError(err) {
        const newPopup = new ItemModel({
            title: err.title || 'Please correct the following errors:',
            desc: typeof err === 'string' ? err : get(err, 'response.data.message', 'Oops, something going wrong, please try again.')
        });

        this.items.push(newPopup);
    }

    @action addSuccess(name) {
        const newPopup = new ItemModel({
            type: 'success',
            title: 'Saved successfully',
            desc: `${name} has been saved successfully`
        });

        this.items.push(newPopup);
    }

    @action addValidationErrors(errors, data) {
        data.timer = 0;
        this.clear(data.scope);

        const errorsArray = [];

        each(errors, (error) => {
            each(error, (e) => {
                errorsArray.push(e);
            });
        });

        data.descArray = errorsArray;
        const newPopup = new ItemModel(data);
        this.items.push(newPopup);
    }

    @observable clear(scope = null) {
        if (scope) {
            remove(this.items, (i) => {
                return !(i.scope === scope);
            });
        } else {
            this.items = [];
        }
    }
}

class ItemModel {
    @observable display = true;
    @observable data = {};

    constructor(data) {
        this.data = data;
        let time = 5000;

        if (!data.type) time = 10000;
        if (data.type === 'error') time = 10000;

        if (data.timer !== 0) {
            setTimeout(() => {
                this.close();
            }, time);
        }
    }

    @action close() {
        this.display = false;
    }
}

export default StoreNotifications;
