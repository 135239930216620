
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

import statesAbbr from '../constants/statesAbbr';
import AuthService from '../services/auth';
import CustomerService from '../services/customer';

validatorjs.register('phone', function(value) {
    const match = value.match(/\d/g);
    return match && match.length === 10;
}, 'Phone number must be 10 characters.');

validatorjs.register('state', function(value) {
    const fieldText = value.toLocaleLowerCase();
    return statesAbbr.some(({label, value}) => label.toLocaleLowerCase() === fieldText || value.toLocaleLowerCase() === fieldText);
}, 'This is not a valid U.S. state.');

validatorjs.registerAsync('checkResetToken', async function(value, attr, key, passes) {
    try {
        await AuthService.verifyToken(value);
        passes();
    } catch(error) {
        passes(false, 'Token is not valid!');
    }
});

validatorjs.registerAsync('checkUserEmail', async function(value, attr, key, passes) {
    try {
        const result = await CustomerService.checkCustomerEmail(encodeURIComponent(value));

        if (!result.exist) {
            return passes();
        }

        passes(false, 'User is already exist, please login instead');

    } catch(error) {
        passes(false, 'Could not verify email, please try again');
    }
});

const __defaultOptions = {
    showErrorsOnChange: true,
    showErrorsOnBlur: true,
    validateOnChange: true,
    showErrorsOnReset: false
};

const __defaultPlugins = { dvr: dvr(validatorjs) };

class FormBinder {

    constructor(fields, hooks = {}, extendOptions = {}, existingData = {}) {
        const formConfig = {
            options: { ...__defaultOptions, ...extendOptions },
            plugins: __defaultPlugins,
            hooks
        };
        return new MobxReactForm({
            fields: Object.keys(existingData).length ?
                fields.map(field => ({
                    ...field,
                    value: existingData[field.name]
                }))
                : fields
        }, formConfig);
    }

}

export default FormBinder;
