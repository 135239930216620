import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import ComponentCropper from '../../../components/static/Cropper';

import FormBinder from '../../../helpers/formBinder';
import Request from '../../../utils/Request';
import CustomerService from '../../../services/customer';
import { EMAIL, FIRST_NAME, LAST_NAME } from "../../../constants/formFields";

import "./profile.scss";
import Loader from "../../../components/ui/Loader";
import {getProjectData} from "../../../projectMap";

@inject('StoreApp')
@inject('StoreUser')
@inject('StoreNotifications')
@observer
export class PageSettingsProfile extends Component {
    form = null;

    @observable isLoading = false;
    @observable cropperState = {
        display: false,
        notSavedFile: false,
        imgSource: this.props.StoreUser.userLogo,
        imgBlob: null
    };

    constructor(props) {
        super(props);

        this.initForm();
    }

    initForm() {
        const { StoreUser: { profile } } = this.props;
        this.form = new FormBinder([
            { ...EMAIL, value: profile.email },
            { ...FIRST_NAME, value: profile.firstName },
            { ...LAST_NAME, value: profile.lastName }
        ], {
            onSuccess: this.onSuccess.bind(this)
        });
    }

    async onSuccess(form) {
        this.isLoading = true;

        try {
            const values = form.values();

            await CustomerService.updateCustomerProfile(values);

            this.props.StoreUser.updateItem('firstName', values.firstName);
            this.props.StoreUser.updateItem('lastName', values.lastName);

            this.initForm();
        } catch (error) {
            this.props.StoreNotification.addError(error);
        }

        this.isLoading = false;
    }

    handleClickOnFileInput = async(e) => {
        this.isLoading = true;
        if (this.cropperState.imgSource) {
            e.preventDefault();
            if (this.props.StoreUser.klicklyLogo) {
                this.cropperState.imgBlob = this.cropperState.imgSource;
            } else {
                this.cropperState.imgBlob = await Request.getBase64(this.cropperState.imgSource);
            }

            this.cropperState.display = true;
        }

        this.isLoading = false;
    };

    closeCropper() {
        this.cropperState.display = false;
    }

    handleLogoChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            this.cropperState.display = true;
            this.cropperState.notSavedFile = true;
            this.cropperState.imgBlob = reader.result;
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            this.cropperState.display = false;
        }
        /*
        * this is reset for file input. otherwise it will not fire onChage if you reselct same file
        * */
        e.target.value = null;
    };

    render() {
        const { form } = this;
        const { StoreUser } = this.props;
        return (
            <div className="mp-profile">
                <div className="profile-photo-container">
                    <label className="profile-photo" htmlFor="avatar-file">
                        <input name="file"
                            type="file" id="avatar-file"
                            onChange={this.handleLogoChange}
                            onClick={this.handleClickOnFileInput}
                            accept="image/*"/>
                        {
                            StoreUser.userLogo ?
                                <div className="fb-avatar">
                                    <img src={StoreUser.userLogo} alt="avatar" height="100" />
                                </div>
                                :
                                <div className="no-avatar-logo">
                                    <div className="no-avatar-logo__icon"/>
                                    <span className="no-avatar-logo__text">Add image</span>
                                </div>
                        }
                    </label>
                    {
                        this.props.StoreApp.creditsCoinEnabled &&
                        <div className="mp-profile-coins">
                            <div className="mp-profile-coins__value-container">
                                <img src={getProjectData('coins-active')} alt="" className="mp-profile-coins__icon"/>
                                <span className="mp-profile-coins__value">{this.props.StoreUser.userCredits}</span>
                            </div>
                            <span className="mp-profile-coins__text">{getProjectData('coin-label')} coins</span>
                        </div>
                    }
                </div>


                { this.cropperState.display && <ComponentCropper cropperState={this.cropperState} closeCropper={() => this.closeCropper()} /> }

                <div className="mp-profile-form">
                    <h2 className="mp-profile-form__title">My Profile</h2>

                    <div className="account-settings-form">
                        <form onSubmit={form.onSubmit} className="form">
                            <div className="two-inputs-row">
                                <Input error={form.$('firstName').error} {...form.$('firstName').bind() } />
                                <Input error={form.$('lastName').error} {...form.$('lastName').bind() } />
                            </div>
                            <Input error={form.$('email').error} {...form.$('email').bind() } disabled />
                        </form>
                        <div className="market-place-settings-content__actions">
                            <Button
                                onClick={form.onReset}
                                label="Cancel"
                                className="transparent" />
                            <Button type="submit"
                                onClick={form.onSubmit}
                                label="Save"
                                className="dark-pink header-btn"
                                disabled={form.isPristine || !form.isValid} />
                        </div>
                    </div>

                </div>
                <Loader display={this.isLoading} />
            </div>
        );
    }
}
