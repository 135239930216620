
import * as Parser from 'ua-parser-js';
import * as get from 'lodash/get';
import * as merge from 'lodash/merge';
import * as uuidv4 from 'uuid/v4';

import Request from '../utils/Request';
import { gaPromotion } from '../utils/GoogleAnalytics';
import { parseURL, timeElapsed } from '../helpers/static';
import {fingerprintAPI, shopAPI} from '../config/api';

import FingerprintScript from '../libs/fingerprint';

class TrackHelper {
    eventSource = 'marketplace';
    widgetSize = null;

    constructor() {
        this.parser = new Parser();
        this.browserInfo = this.getBrowserInfo();
    }

    getBrowserInfo() {
        const { browser, os, device } = this.parser.getResult();
        const isIframe = parent !== window;
        const screenWidth = isIframe ? '' : get(window, 'screen.width');
        const screenHeight = isIframe ? '' : get(window, 'screen.height');
        const referrer = isIframe ? parseURL(document.referrer) : '';
        const currentUrl = window.location.href;

        return {
            browser: browser.name,
            browserVersion: browser.version,
            device: device.model,
            os: os.name,
            screenWidth: screenWidth,
            screenHeight: screenHeight,
            referrer: referrer,
            currentUrl: currentUrl,
            isMobile: device.type === 'mobile'
        };
    }

    setEventSourceAndWidgetSize(eventSource, widgetSize) {
        this.eventSource = eventSource;
        this.widgetSize = widgetSize;
    }

    async sendEvent(eventName, promotions, typeOfUnit = null, additionalData = {}) {
        if (!promotions.length) {
            return false;
        }

        const adId = uuidv4();
        const isTrackEvent = eventName === 'clicked' || eventName === 'loaded';
        const servedInfo = {
            eventId: uuidv4(),
            widgetSize: this.widgetSize,
            timeElapsed: timeElapsed()
        };

        // loop only once & prepare track, pixel, uniq accounts data
        const data = promotions.reduce((acc, promotion, index) => {
            // If this event is clicked or loaded - collect for dynamo/redshift
            if (isTrackEvent) {
                acc.trackEvents.push(merge({}, {eventName}, this.browserInfo, servedInfo, additionalData, {
                    adId,
                    promotionId: promotion._id,
                    campaignId: promotion.campaign,
                    accountId: promotion.account,
                    position: index + 1
                }));
            }

            const pixelEvent = {
                promotionId: promotion._id,
                promotionExternalId: promotion.externalId,
                campaignId: promotion.campaign,
                accountId: promotion.account,
                shopDomain: promotion.shopDomain,
                referrer: parseURL(window.document.referrer),
                promotionName: promotion.title,
                eventSource: this.eventSource,
                widgetSize: this.widgetSize,
                eventName,
                typeOfUnit
            };

            acc.pixelEvents.push(pixelEvent);

            return acc;
        }, {
            trackEvents: [],
            pixelEvents: []
        });

        this.__trackFingerprint(promotions, {
            eventSource: this.eventSource,
            eventAction: eventName,
            eventSubAction: typeOfUnit,
            widgetSize: servedInfo.widgetSize,
            additionalData
        }, additionalData.klicklyOrderId || null);

        const { gaTrackingByAccount = [] } = await Request.post(shopAPI.endPoints.events.klickly, data);

        switch(eventName) {
        case 'loaded' :
            promotions.forEach(promotion => {
                gaPromotion(promotion, gaTrackingByAccount).recordView();
            });
            break;
        case 'clicked' :
            promotions.forEach(promotion => {
                gaPromotion(promotion, gaTrackingByAccount).recordClick();
            });
            break;
        case 'click-through' :
            promotions.forEach(promotion => {
                gaPromotion(promotion, gaTrackingByAccount).recordClickThrough();
            });
            break;
        case 'add-to-cart' :
            promotions.forEach(promotion => {
                gaPromotion(promotion, gaTrackingByAccount).recordAddToCart();
            });
            break;
        default:
            break;
        }

        return true;
    }

    async __trackFingerprint(promotions, trackingData, klicklyOrderId) {
        if (fingerprintAPI.enable) {
            if (trackingData.eventAction === 'clicked') {
                trackingData.eventAction = 'click';
            } else if (trackingData.eventAction === 'loaded') {
                trackingData.eventAction = 'view';
            }

            if (klicklyOrderId) {
                trackingData.klicklyOrderId = klicklyOrderId;
            }

            const data = await FingerprintScript.getKlicklyEventData(promotions, trackingData);
            return Request.post(fingerprintAPI.endPoints.track, data, 'fingerprint-api');
        }

        return false;
    }
}

const instance = new TrackHelper();
export default instance;
