import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Link } from 'react-router-dom';

import './footer.scss';

@observer
class ComponentFooter extends Component {

    @observable activeState = false;
    @action setState(status) {
        this.activeState = status;
    }

    render() {
        return (
            <div className={`mp-footer ${this.activeState ? 'is-active' : ''}`}
                onMouseOver={() => this.setState(true)}
                onMouseLeave={() => this.setState(false)}>
                <div className="mp-footer-content">
                    <div className="left-side">
                        <p className="mp-footer__copy mp-footer__link">&copy; Klickly</p>
                    </div>
                    <div className="right-side">
                        <Link to="/about" className="mp-footer__link">About</Link>
                        <a href="https://brands.klickly.com" rel="noopener noreferrer" className="mp-footer__link" target="_blank">Brands</a>
                        <a href="https://www.klickly.com/terms" rel="noopener noreferrer" className="mp-footer__link" target="_blank">Terms</a>
                        <a href="https://www.klickly.com/privacy" rel="noopener noreferrer" className="mp-footer__link" target="_blank">Privacy</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComponentFooter;
