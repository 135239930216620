import React from 'react';
import * as get from 'lodash/get';
import classNames from 'classnames';

import Radio from '../../ui/Input/Radio';

export const cardImages = {
    'Visa': require('../../../images/cards/visa-light.svg'),
    'Mastercard': require('../../../images/cards/master-card-light.svg'),
    'Discover': require('../../../images/cards/discover-light.svg'),
    'American Express': require('../../../images/cards/american-express-dark.svg')
};

export const CardItem = (props) => {
    const cardLogo = get(cardImages, props.brand, cardImages.Mastercard);

    return (
        <div className={classNames('card-item', props.className)}
            onClick={() => props.onClick ? props.onClick(props._id, props.isDefault) : null }>

            <div className="card-item__info">
                <span className="card-item-logo">
                    <Radio checked={props.isDefault} id={props._id} />
                    <img src={cardLogo} alt={`${props.brand}-logo`} />
                    <span>{`Ending in ${props.last4}`}</span>
                </span>
                <span className="expires">
                    {`${props.expMonth}/${props.expYear}`}
                </span>
            </div>

            <div className="card-item__address">
                {`${props.address1 || 'No billing address'}`}
            </div>
        </div>
    );
};
