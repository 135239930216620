import { action, observable } from 'mobx';
import * as remove from 'lodash/remove';
import * as isArray from 'lodash/isArray';
import * as get from "lodash/get";

import { GTM } from '../../utils/GTM';
import { localStore } from '../../helpers/browserStores';
import CustomerService from '../../services/customer';
import productsLoader from '../../helpers/productsLoader';
import { mergeArraysBy } from '../../helpers/static';

const FAVORITES_LOCAL_STORAGE_VALUES = 'favoriteProducts';
const FAVORITES_SYNC_WAIT = 1500;

class StoreFavorites {
    itemsSyncTimeout = null;
    @observable items = [];

    @action addProductToFavorites(product, isMobileView, callback) {
        this.items.unshift({
            status: 'available',
            product
        });

        this.trackAddToFavorites(product);
        this.updateFavorites();

        // If is mobile view, we will be not open favorites block
        if (!isMobileView) {
            callback();
        }
    }

    @action removeProductFromFavorites(productId, callback) {
        remove(this.items, (item) => productId === item.product.get('id'));
        this.updateFavorites();

        // If favorites items empty, we will close block
        if (this.items.length === 0) {
            callback();
        }
    }

    isFavoriteProduct(productId) {
        return this.items.some((item) => productId === item.product.get('id'));
    }

    toggleFavoriteProduct(product, isMobileView = false, showCallback, hideCallback) {
        if (this.isFavoriteProduct(product.get('id'))) {
            this.removeProductFromFavorites(product.get('id'), hideCallback);
        } else {
            this.addProductToFavorites(product, isMobileView, showCallback);
        }
    }

    updateFavorites() {
        this.setFavoritesToLocalStorage(this.items);
        clearTimeout(this.itemsSyncTimeout);
        this.itemsSyncTimeout = setTimeout(() => this.syncFavoritesProductsWithServer(), FAVORITES_SYNC_WAIT);
    }

    async syncFavoritesProductsWithServer(isFirstSync = false) {
        const data = {
            isFirstSync,
            favorites: isFirstSync
                ? localStore.get(FAVORITES_LOCAL_STORAGE_VALUES) || []
                : this.favoritesToShortFormat(this.items)
        };
        const favoritesItems = await CustomerService.syncFavorites(data);

        if (isArray(favoritesItems)) {
            const parsedFavoritesItems = favoritesItems.map(({ promotion, status }) => {
                const { _id, title = '--', images = [] } = promotion;
                const product = productsLoader.parseProduct(status === 'available' ? promotion : {id: _id, title, images, brandName: 'Not available' });

                return {
                    status,
                    product
                };
            });

            const mergedFavorites = mergeArraysBy(this.items, parsedFavoritesItems, 'product.id');
            this.items = mergedFavorites.filter(({ status }) => status !== 'deleted');
        }
    }

    setFavoritesToLocalStorage(favoritesProducts) {
        const favoritesToLocalStorage = this.favoritesToShortFormat(favoritesProducts);
        localStore.set(FAVORITES_LOCAL_STORAGE_VALUES, favoritesToLocalStorage);
    }

    clearFavoriteProducts() {
        this.items = [];
        localStore.set(FAVORITES_LOCAL_STORAGE_VALUES, []);
    }

    favoritesToShortFormat(items) {
        return items.map(item => ({
            status: item.status,
            promotion: item.product.get('id')
        }));
    }

    trackAddToFavorites(product) {
        const firstVariant = get(product.variants, '0', null);

        GTM.AddToWishlistEvent.send({
            content_name: product.get('title'),
            content_ids: product.get('promotionExternalId'),
            contents: firstVariant && firstVariant.get('sku')
        });
    }
}

export default StoreFavorites;
