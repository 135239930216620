import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import ScrollArea from 'react-scrollbar';

import EventListener from '../../../helpers/eventListener';
import changeHead from '../../../helpers/changeHead';
import './categoryFilter.scss';

@inject('StoreApp')
@inject('StoreProducts')
@inject('StoreCategoryFilter')
@inject('StoreSearch')
@observer
class ComponentCategoryFilter extends Component {

    eListener = new EventListener();
    filterBarRef = null;
    moreButtonRef = null;
    scrollAreaRef = null;

    constructor(props) {
        super(props);

        const { StoreApp, StoreCategoryFilter } = this.props;
        const { isMobile, isMobileLandscape } = StoreApp;
        StoreCategoryFilter.init(isMobile || isMobileLandscape);
    }

    componentDidMount() {
        const { StoreCategoryFilter } = this.props;
        if (this.scrollAreaRef) {
            StoreCategoryFilter.setWidthScrollContent(this.scrollAreaRef.scrollWidth);
        }

        StoreCategoryFilter.setInitialWidthOfItems(this.filterBarRef);
        StoreCategoryFilter.calculateFilterBarWidthAndVisibleItems(this.filterBarRef, this.moreButtonRef);

        window.addEventListener('resize', () => StoreCategoryFilter.resize(this.filterBarRef, this.moreButtonRef));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.props.StoreCategoryFilter.resize(this.filterBarRef, this.moreButtonRef));
    }

    componentDidUpdate() {
        const { StoreApp, StoreCategoryFilter } = this.props;
        const { isMobile, isMobileLandscape } = StoreApp;

        if (isMobile || isMobileLandscape) {
            StoreCategoryFilter.setWidthScrollContent(this.scrollAreaRef.scrollWidth);
        }

        this.hideMoreFilter();
    }

    hideMoreFilter = () => {
        const { StoreCategoryFilter } = this.props;
        if (StoreCategoryFilter.showMoreFilter) {
            this.eListener.addClick('more-filter', () => {
                StoreCategoryFilter.toggleShowMoreFilter();
            });
        } else {
            this.eListener.removeClick();
        }
    };

    changeCategory(category) {
        const { StoreCategoryFilter, StoreProducts, StoreSearch, StoreApp } = this.props;

        if (StoreCategoryFilter.changeFilterCategory(category.key)) {
            const url = `/${category.key}`;
            this.props.history.push(url);
            StoreProducts.proceedSearchCategory(category.key);
            StoreSearch.clearSearchQuery();

            changeHead(category);

            StoreApp.setLastURL(url);
        }
    }

    render() {
        const { StoreApp, StoreCategoryFilter, isFixed } = this.props;
        const { isMobile, isMobileLandscape } = StoreApp;

        return (
            <div className="category-filter-wrapper">
                <div className={classNames(
                    'market-place-content-top',
                    StoreApp.scrollDirection,
                    { 'fixed': isFixed }
                )}>
                    <div className="filter-bar-wrapper">
                        {
                            isMobile || isMobileLandscape ?
                                <ScrollArea contentStyle={{width: StoreCategoryFilter.widthScrollContent}} speed={1} contentClassName="content" horizontal={true} vertical={false}>
                                    <div ref={(ref) => {
                                        this.scrollAreaRef = ref;
                                        return true;
                                    }} className="filter-bar">
                                        {
                                            StoreCategoryFilter.availableItems.map((item, key) =>
                                                <div key={`mobile-filter-bar-item-${key}`}
                                                    className={classNames('filter-bar__item', { 'is-active': item.key === StoreCategoryFilter.searchCategory })}
                                                    onClick={() => this.changeCategory(item)}
                                                >
                                                    <div className={`filter-bar__icon ${item.imageClass}`} />
                                                    <div className="filter-bar__text">{item.text}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </ScrollArea>
                                :
                                <div className="filter-bar" ref={(ref) => {
                                    this.filterBarRef = ref;
                                    return true;
                                }}>
                                    {
                                        StoreCategoryFilter.visibleItems.map((item, key) =>
                                            <div id={`desktop-filter-bar-item---${item.key}`}
                                                key={`desktop-filter-bar-item-${key}`}
                                                className={classNames('filter-bar__item', { 'is-active': item.key === StoreCategoryFilter.searchCategory })}
                                                onClick={() => this.changeCategory(item)}
                                            >
                                                <div className="filter-bar__text">{item.text}</div>
                                            </div>
                                        )
                                    }
                                </div>
                        }
                        <div ref={(ref) => {
                            this.moreButtonRef = ref;
                            return true;
                        }}
                        onClick={() => StoreCategoryFilter.toggleShowMoreFilter()}
                        className="more-bar">
                            <div className={classNames('filter-bar__item more-filter', {'is-opened': StoreCategoryFilter.showMoreFilter })}>
                                <div className="filter-bar__text">More</div>
                                <div className="wrapper-select">
                                    <div className="select">
                                        {
                                            StoreCategoryFilter.notVisibleItems.map((item, key) =>
                                                <div key={`filter-${key}`}
                                                    className="select__item"
                                                    onClick={() => {
                                                        StoreCategoryFilter.changeFilterWithHiddenCategory(item);
                                                        this.changeCategory(item);
                                                    }}
                                                >
                                                    <div className="select__text">
                                                        {item.text}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ComponentCategoryFilter);
