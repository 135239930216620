
const imageLoader = (imageSrc) => {
    return new Promise((resolve) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => resolve(image.src);
    });
};

export default imageLoader;
