
import axios from 'axios';
import { shopAPI, fingerprintAPI } from '../config/api';

const axiosInstance = axios.create({
    withCredentials: true
});

class Request {

    makeURL(url, provider = 'shop-api') {
        if (provider === 'fingerprint-api') {
            return `${fingerprintAPI.baseURL}${url}`;
        }

        return `${shopAPI.baseURL}${url}`;
    }

    get(urlReq, data, cancelToken, provider = 'shop-api') {
        const params = data && data.length ? data.join('&') : null;
        return axiosInstance.get(`${this.makeURL(urlReq, provider)}${params ? '?' + params : ''}`, {
            cancelToken
        })
            .then((res) => res.data);
    }

    post(urlReq, data, provider = 'shop-api') {
        return axiosInstance.post(this.makeURL(urlReq, provider), data)
            .then((res) => res.data);
    }

    put(urlReq, data, provider = 'shop-api') {
        return axiosInstance.put(this.makeURL(urlReq, provider), data)
            .then((res) => res.data);
    }

    remove(urlReq, id, provider = 'shop-api') {
        return axiosInstance.delete(this.makeURL(`${urlReq}${id ? `/${id}` : ''}`, provider))
            .then((res) => res.data);
    }

    getBase64(url) {
        return axios
            .get(url, {
                responseType: 'arraybuffer'
            })
            .then(response => `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`);
    }
}

const instance = new Request();
export default instance;
