
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import * as get from 'lodash/get';
import Button from '../../../../components/ui/Button';
import './index.scss';
import {getProjectData} from "../../../../projectMap";

@inject('StoreUser')
@inject('StorePurchase')
@inject('StoreFavorites')
@observer
class LoggedInAs extends Component {
    handleLogout = async() => {
        this.props.StorePurchase.toggleLoader(true);

        await this.props.StoreUser.logout();

        this.props.StoreFavorites.clearFavoriteProducts();
        this.props.StorePurchase.setValue('profile', {});

        this.props.StorePurchase.changeStep('customerInfo');
        this.props.StorePurchase.toggleLoader(false);
    };

    render() {
        return (
            <div className="logged-in">
                <div className="logged-in__logo-wrapper">
                    <img className="logged-in__logo"
                        src={getProjectData('logo')}
                        alt="klickly logo"
                    />
                </div>

                <h2 className="logged-in__heading">You’re logged in as</h2>
                <div className="logged-in-form">
                    <div className="logged-in-form__item">
                        <label onClick={this.handleLogout} className="logged-in-form__label">Not you?</label>
                        <div className="logged-in-form__field">
                            { get(this.props.StorePurchase, 'store.profile.email', 'loading...') }
                        </div>
                    </div>
                    <Button
                        className="dark-pink logged-in-form__submit"
                        label="Continue"
                        onClick={() => this.props.StorePurchase.changeStep('shipping', true)}
                    />
                </div>
            </div>

        );
    }


}

export default LoggedInAs;
