import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import SignUpStep from '../../components/marketplace/SignUp';
import { Loader } from "../../components/ui/Loader";
import QuicktoHelper from '../../helpers/quickto';
import { localStore } from "../../helpers/browserStores";
import {getProjectData} from "../../projectMap";

@inject('StorePurchase')
@inject('StoreTutorial')
@inject('StoreApp')
@observer
export class PageSignUpClass extends Component {
    form = null;

    @observable loader = false;
    @action toggleLoader = (status) => {
        this.loader = status;
    };

    constructor(props) {
        super(props);
        document.body.classList.add('open-modal');
    }

    componentWillUnmount() {
        document.body.classList.remove('open-modal');
    }

    closeSignInModal = () => {
        const { history, StoreApp, isStepMode } = this.props;
        if(!isStepMode) {
            history.push(StoreApp.lastMarketplaceURL);
        }

        if(this.props.onSuccess) {
            this.props.onSuccess();
        }
    };

    render() {
        const { isSecondaryType = false, isStepMode = false, StorePurchase: { isStreamlineProduct } } = this.props;
        const isCloseButtonDisabled = QuicktoHelper.paramsToRedirect.tutorial
            && !localStore.get('tutorialPassed')
            && this.props.StoreTutorial.stepToGo === 3;
        const formTitle = isSecondaryType ? 'FREE gifts just for online shopping!' : isStreamlineProduct ? 'Sign up today to get access to exclusive pricing from top brands – available only through QuickTo!' : 'The world\'s best offers and rewards!';
        return (
            <div className={classnames('overlay overlay-y overlay-auth', {'full-sized': isStreamlineProduct})}>
                <div className="auth sign-up">
                    { !isSecondaryType && !isStepMode && <span onClick={this.closeSignInModal} className={classnames('auth__close', {'disabled': isCloseButtonDisabled})}/>}
                    <div className="auth-logo">
                        <img className="auth-logo__img" src={getProjectData('logo')} alt=""/>
                    </div>
                    <h2 className={classnames('form__title', {'small': isStreamlineProduct})}>
                        { formTitle }
                    </h2>
                    <SignUpStep
                        facebookButtonText="Sign up with Facebook"
                        onSuccess={this.closeSignInModal}
                        toggleLoader={this.toggleLoader}
                    >
                        {
                            !isSecondaryType && !isStepMode &&
                            <div className="form__text sign-in">
                                Already have an account?
                                <span className="form__link"
                                    onClick={() => this.props.history.push('/sign-in')}>&nbsp;Log in</span>
                            </div>
                        }
                        { !this.props.StoreApp.creditsCoinEnabled &&
                            <div className="form__text privacy-info">
                                By continuing, you agree to { getProjectData('title') }’s<br/>
                                <a href="https://www.klickly.com/terms" rel="noopener noreferrer" target="_blank" className="form__link">
                                    Terms of Service
                                </a>
                                &nbsp;and&nbsp;
                                <a href="https://www.klickly.com/privacy" rel="noopener noreferrer" target="_blank" className="form__link">
                                    Privacy Policy
                                </a>
                            </div>
                        }
                    </SignUpStep>

                    <Loader display={this.loader} />

                    {
                        !this.props.StoreApp.creditsCoinEnabled &&
                            <Fragment>
                                <a href="http://brands.klickly.com" className="auth-footer">
                                    Continue as a business
                                </a>

                                <div className="circle-box">
                                    <div className="circle-box__wrapper">
                                        <h3 className="circle-box__title">Find. Buy. Earn.</h3>
                                        <p className="circle-box__text">Earn rewards by shopping the world's best-voted ads...</p>
                                        <button
                                            onClick={() => this.props.history.push('/about')}
                                            className="circle-box__link">
                                            How it works
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        );
    }
}

export const PageSignUp = withRouter(PageSignUpClass);
