import { computed, observable, action, extendObservable } from 'mobx';
import * as get from 'lodash/get';
import * as find from 'lodash/find';
import * as each from 'lodash/each';
import QuicktoHelper from '../../helpers/quickto';
import AuthService from '../../services/auth';

import constants from '../../constants/main';

class StoreUser {
    @observable profile = null;

    @action setProfile(profile) {
        this.profile = profile;
    }

    @computed get userCredits() {
        let defaultCredits = QuicktoHelper.paramsToRedirect.coins ? constants.DEFAULT_CREDITS_AMOUNT : 0;
        return this.isAuthenticated ? this.profile.creditsEarned : defaultCredits;
    }

    @action async logout() {
        await AuthService.logout();
        this.setProfile(null);
    }

    @computed get isAuthenticated() {
        return Boolean(this.profile);
    }

    @computed get facebookLogo() {
        return get(this.profile, 'facebook.picture.data.url', null);
    }

    @computed get klicklyLogo() {
        return get(this.profile, 'logoImage.Location', null);
    }

    @computed get userLogo() {
        return this.klicklyLogo || this.facebookLogo;
    }

    @action changeLogo(location) {
        if (this.profile.logoImage) {
            this.profile.logoImage.Location = location;
        } else {
            extendObservable(this.profile, { logoImage: { Location: location } });
        }
    }

    @computed get defaultAddress() {
        const addresses = get(this.profile, 'shippingAddresses', []);
        return find(addresses, { isDefault: true });
    }

    @computed get defaultCard() {
        const addresses = get(this.profile, 'cards', []);
        return find(addresses, { isDefault: true });
    }

    @action changeDefaultItem(item, _id) {
        if (this.profile[item]) {
            each(this.profile[item], (listItem) => {
                listItem.isDefault = listItem._id === _id;
            });
        }
    }

    @action addItem(item, data) {
        this.profile[item].push(data);
    }

    @action updateItem(item, data) {
        if (this.profile) {
            this.profile[item] = data;
        }
    }
}

export default StoreUser;
