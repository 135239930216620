import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { prepareImageUrl } from '../../../helpers/static';
import { roundToLocaleString } from '../../../helpers/static';
import { quickToCartThin } from '../../../images/quickTo';
import './index.scss';
import {getProjectData} from "../../../projectMap";

const CartItem = (props) => {
    return(
        <div className="quickto-cart-product">
            <div className="quickto-cart-product__img-wrapper">
                <img src={props.image} alt="" className="quickto-cart-product__img"/>
            </div>
            <div className="quickto-cart-product__info">
                <span className="quickto-cart-product__name">{props.title}</span>
                <span className="quickto-cart-product__brand">{props.brand}</span>
                <span className="quickto-cart-product__price-box">
                    <img src={getProjectData('coins-active')} alt="" className="quickto-cart-product__coins-icon"/>
                    <span className="quickto-cart-product__price">{props.coins} + ${roundToLocaleString(props.price)}</span>
                </span>
                <button className="quickto-cart-product__delete" onClick={props.onDelete}>
                    Delete
                </button>
            </div>
        </div>
    );
};

@inject('StoreAsidePanel')
@inject('StorePurchase')
@inject('StoreCheckout')
@inject('StoreNotifications')
@observer
class QuickToCart extends Component {

    renderCartItems() {
        const { StoreCheckout, StoreAsidePanel } = this.props;

        return StoreCheckout.list.map((product) =>
            <CartItem key={`product-${product.id}`}
                id={product.id}
                title={product.get('title')}
                image={`${prepareImageUrl(product.get('image'), '_60x$1')}`}
                brand={product.get('brandName')}
                coins={0}
                price={product.price}
                onDelete={() => {
                    StoreCheckout.remove(product.id);

                    if (StoreCheckout.isEmpty()) {
                        StoreAsidePanel.hideCart();
                    }
                }}
            />
        );
    }

    render() {
        const { StoreCheckout, StoreNotifications } = this.props;

        return(
            <div className="quickto-cart">
                <div className="quickto-cart__head">
                    <h4 className="quickto-cart-title">
                        <img src={quickToCartThin} alt="cart" className="quickto-cart-title__icon"/>
                        <span>Shopping bag</span>
                    </h4>
                    <span className="quickto-cart__count">{StoreCheckout.count()}</span>
                </div>
                <div className="quickto-cart__list">
                    {this.renderCartItems()}
                </div>
                <button className="quickto-cart__checkout" onClick={() => {
                    StoreNotifications.addError('oops, try again later');
                }}>
                    <span>Checkout</span>
                </button>
            </div>
        );
    }
}

export default QuickToCart;
