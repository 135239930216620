
import React from 'react';

import './checkbox.scss';

const Checkbox = (props) => {
    return (
        <div className="klickly-checkbox-wraper">
            <input
                className="klickly-checkbox"
                type="checkbox"
                { ...props }
            />
            <label
                className="klickly-checkbox__label"
                htmlFor={props.id}
            >
                {props.content}
            </label>
        </div>
    );
};

export default Checkbox;
