import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import customerFlow from '../../../../../helpers/customerFlow';
import './index.scss';

@inject('StorePurchase')
@observer
class QtyCounter extends Component {

    updateWidth = () => {
        this.props.changeProductOptionValuesWidth('100%');
    };

    componentDidMount() {
        this.updateWidth();
    }

    componentDidUpdate() {
        this.updateWidth();
    }

    render() {
        const { option, changeQuantity, StorePurchase } = this.props;
        let offer = null;

        if (StorePurchase.store.product) {
            offer = customerFlow.getStreamlinedSpecialOffer(StorePurchase.store.product.get('id'));
        }

        return (
            <div className="mp-modal-qty">
                <button className="mp-modal-qty__button" onClick={() => changeQuantity(option.selected - 1)}>-</button>
                <input
                    className="mp-modal-qty__value"
                    type="number"
                    onChange={({currentTarget}) => changeQuantity(currentTarget.value)}
                    value={offer && offer.strictQty ? offer.qty : option.selected} />
                <button className="mp-modal-qty__button" onClick={() => changeQuantity(option.selected + 1)}>+</button>
            </div>
        );
    }
}

export default QtyCounter;
