

const projectMap = {
    quickto: {
        'title': 'Quickto',
        'landing-header__logo': 'quickto',
        'mailto': 'hello@quickto.shop',
        'logo': require('./images/quickTo/quickto-logo.svg'),
        'coins-active': require('./images/quickTo/quickto-logo-circle.svg'),
        'coin-label': 'Quick',
        'coins-empty': require('./images/quickTo/quickto-logo-circle--inactive.svg')
    },
    prosocial: {
        'title': 'ProSocial',
        'landing-header__logo': 'proSocial',
        'mailto': 'hello@prosocialcommerce.com',
        'logo': require('./images/prosocialcommerce/prosocialcommerce-logo.svg'),
        'coin-label': 'PS',
        'coins-active': require('./images/prosocialcommerce/prosocialcommerce-logo-circle.svg'),
        'coins-empty': require('./images/prosocialcommerce/prosocialcommerce-logo-circle--inactive.svg')
    }
};

export const getProjectData = (selector) => projectMap[process.env.INSTANCE_TYPE][selector] || '';
