import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import RenderIf from '../../../helpers/renderIf';

import './input.scss';

@observer
class Input extends Component {

    render() {
        const {
            label,
            type,
            value,
            name,
            id,
            defaultWrapperClass,
            error,
            placeholder,
            wrapperClass,
            isShowError,
            errorMessage,
            showLabel,
            ...rest } = this.props;

        const message = errorMessage ? errorMessage : error;

        const showError = error && isShowError;

        return (
            <div className={classNames(defaultWrapperClass, wrapperClass, {'input-with-error': Boolean(error) })}>
                <RenderIf condition={Boolean(label && showLabel)}>
                    <label htmlFor={ id || name }>{label}</label>
                </RenderIf>

                <input
                    type={type}
                    id={id || name}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    {...rest}
                />

                <div className="error-message">{showError ? message : ' '}</div>
            </div>
        );
    }
}

Input.defaultProps = {
    type: 'text',
    disabled: false,
    isShowError: true,
    showLabel: true,
    defaultWrapperClass: 'common-input-wraper',
    onBlur: (target) => null
};

export default Input;
