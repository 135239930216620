
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import * as get from 'lodash/get';

import {
    Accordion,
    Headline,
    ActionButton,
    AccordionItems
} from '../../../../components/reusable/AccordionWithForm';

import { AddressItem } from '../../../../components/reusable/AddressItem';
import { CardItem } from '../../../../components/reusable/CardItem';

import { Loader } from '../../../../components/ui/Loader';
import Button from '../../../../components/ui/Button';
import OrdersService from '../../../../services/orders';
import CustomerService from '../../../../services/customer';
import TrackHelper from '../../../../helpers/track';
import customerFlow from '../../../../helpers/customerFlow';
import { GTM } from '../../../../utils/GTM';

import './index.scss';

const images = {
    comodo: require('../../../../images/security/comodo@3x.png'),
    stripe: require('../../../../images/security/stripe@3x.png'),
    shopify: require('../../../../images/security/shopfiy@3x.png')
};

@inject('StoreUser')
@inject('StorePurchase')
@inject('StoreNotifications')
@inject('StoreApp')
@inject('StoreCheckout')
@observer
class Confirmation extends Component {

    __handlerItemAction(type, action) {
        const { StorePurchase } = this.props;
        if (type === 'address') {
            StorePurchase.setValue('addNewShipping', action === 'add');
            StorePurchase.changeStep('shipping', false, false);
        } else if (type === 'card') {
            StorePurchase.setValue('addNewBilling', action === 'add');
            StorePurchase.changeStep('billing', false, false);
        }
    }

    onClickMakeDefaultShipping = async(_id, isDefault) => {
        if (!isDefault) {
            const { StorePurchase, StoreUser } = this.props;

            StorePurchase.toggleLoader(true);

            await CustomerService.setDefaultAddress(_id);

            StoreUser.changeDefaultItem('shippingAddresses', _id);

            StorePurchase.setValue('profile', StoreUser.profile);

            this.__updateOrCreateDraftOrder();
        }
    };

    onClickMakeDefaultCard = async(_id, isDefault) => {
        if (!isDefault) {
            const { StorePurchase, StoreUser } = this.props;

            StorePurchase.toggleLoader(true);

            await CustomerService.setDefaultCreditCard({ _id });

            StoreUser.changeDefaultItem('cards', _id);

            StorePurchase.setValue('profile', StoreUser.profile);

            this.__updateOrCreateDraftOrder();
        }
    };

    __enoughDataToProceed() {
        const { StorePurchase } = this.props;
        const billingData = StorePurchase.editableBillingData;
        return (StorePurchase.shippingExist || !StorePurchase.requiresShipping) && (StorePurchase.billingExist && billingData.address1 || StorePurchase.isGiveAwayProduct);
    }

    async __sendTrackingGA(orderId) {
        try {
            const { StorePurchase } = this.props;

            await TrackHelper.sendEvent('purchase', [ StorePurchase.store.product.object ], null, { klicklyOrderId: orderId });

            GTM.PurchaseEvent.send({
                value_of_sale: StorePurchase.totalPurchasePrice,
                content_name: StorePurchase.store.product.get('title'),
                content_ids: StorePurchase.store.product.get('externalId'),
                contents: StorePurchase.store.checkout.variant.sku || ''
            });

            GTM.PurchaseEventGA.send({
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: orderId,
                            revenue: StorePurchase.totalPurchasePrice
                        },
                        products: [ {
                            name: StorePurchase.store.product.get('title'),
                            id: StorePurchase.store.product.get('externalId'),
                            price: StorePurchase.store.product.price,
                            brand: StorePurchase.store.product.get('brandName'),
                            category: StorePurchase.store.product.get('category'),
                            variant: StorePurchase.store.checkout.variant.title,
                            quantity: StorePurchase.store.checkout.qty
                        } ]
                    }
                }
            });
        } catch (error) {
            // nothing to do...
        }
    }

    async __updateOrCreateDraftOrder() {
        const { StorePurchase, StoreNotifications } = this.props;

        if (this.__enoughDataToProceed()) {
            try {
                StorePurchase.toggleLoader(true);

                const { draftOrder, checkout, profile, product } = StorePurchase.store;

                let __draftOrder;

                if (draftOrder) {
                    __draftOrder = await OrdersService.draftUpdate(draftOrder.id, {
                        accountId: product.get('account'),
                        checkout,
                        profile
                    });
                } else {
                    __draftOrder = await OrdersService.draftCreate({
                        accountId: product.get('account'),
                        checkout,
                        profile
                    });
                }

                StorePurchase.setValue('draftOrder', __draftOrder);
                StorePurchase.setCheckoutValue('taxPrice', StorePurchase.parseFloat(__draftOrder.total_tax));
                const specialOffer = customerFlow.getStreamlinedSpecialOffer(product.get('id'));
                if (!specialOffer) {
                    StorePurchase.setCheckoutValue('shippingPrice', StorePurchase.parseFloat(__draftOrder.total_shipping));
                }
            } catch (error) {
                StoreNotifications.addError(error);
            } finally {
                StorePurchase.toggleLoader(false);
            }
        }

    }

    // temp version
    __submitOrder() {
        const { StoreNotifications } = this.props;
        this.__sendTrackingGA('');
        StoreNotifications.add({
            type: 'error',
            title: 'Not able to complete order',
            desc: 'Sorry, your order couldn\'t be processed right now! Please try again later.'
        });
    }

    async __submitOrderToServer() {
        const { StoreApp, StorePurchase, StoreCheckout, StoreUser, StoreNotifications } = this.props;

        try {
            StorePurchase.toggleLoader(true);

            const payload = StorePurchase.getPayload(StoreApp.creditsGiftMode);

            if (customerFlow.isFlowEnabled) {
                payload.flow = {
                    name: customerFlow.params.flow,
                    productID: customerFlow.params.productID,
                    offer: customerFlow.params.offer
                };
            }

            const result = await OrdersService.draftComplete(StorePurchase.store.draftOrder.id, payload);

            StorePurchase.setValue('result', result);
            StoreUser.updateItem('creditsEarned', result.creditsEarned);
            StorePurchase.changeStep('orderCompleted');

            StoreCheckout.clear();

            this.__sendTrackingGA(result.orderId);
        } catch (error) {
            StoreNotifications.addError(error);
        } finally {
            StorePurchase.toggleLoader(false);
        }
    }

    componentDidMount () {
        this.__updateOrCreateDraftOrder();
    }

    render() {
        const { StorePurchase } = this.props;

        const requiresShipping = get(StorePurchase, 'store.checkout.requiresShipping', true);
        const addressesItemsList = get(StorePurchase, 'store.profile.shippingAddresses', []);
        const cardsItemsList = get(StorePurchase, 'store.profile.cards', []);

        return (
            <div className={classNames('mp-modal-purchase__confirmation', { 'not-authenticated': !this.props.StoreUser.isAuthenticated })}>
                <div className="user-email">
                    <span onClick={() => StorePurchase.changeStep('loggedInAs')}>Not you?</span>
                    <div>{StorePurchase.store.profile.email}</div>
                </div>

                {
                    requiresShipping &&
                        <Accordion>
                            <Headline title="Shipping information">
                                <div>
                                    {
                                        this.props.StorePurchase.shippingExist ?
                                            <ActionButton onClick={() => this.__handlerItemAction('address', 'edit')} name="Edit" />
                                            :
                                            <ActionButton onClick={() => this.__handlerItemAction('address', 'add')} name="Add" />
                                    }
                                </div>
                            </Headline>

                            <AccordionItems
                                labelPart="addresses"
                                textPart="shipping address"
                                itemsList={addressesItemsList}
                                listItemComponent={AddressItem}
                                onClickAddNew={() => this.__handlerItemAction('address', 'add')}
                                onClickMakeDefault={this.onClickMakeDefaultShipping}
                            />

                            <Loader size="30px" display={this.isLoading} />
                        </Accordion>
                }

                {!StorePurchase.isGiveAwayProduct &&
                    <Accordion>
                        <Headline title="Billing information">
                            <div>
                                {
                                    StorePurchase.billingExist ?
                                        <ActionButton onClick={() => this.__handlerItemAction('card', 'edit')} name="Edit" />
                                        :
                                        <ActionButton onClick={() => this.__handlerItemAction('card', 'add')} name="Add" />
                                }
                            </div>
                        </Headline>

                        <AccordionItems
                            labelPart="cards"
                            textPart="credit card"
                            itemsList={cardsItemsList}
                            listItemComponent={CardItem}
                            onClickMakeDefault={this.onClickMakeDefaultCard}
                            onClickAddNew={() => this.__handlerItemAction('card', 'add')}
                        />

                        <Loader size="30px" display={this.isLoading} />
                    </Accordion>
                }

                <span className="security-logos">
                    <img className="security-logos__icon" src={images.comodo} alt=""/>
                    <img className="security-logos__icon" src={images.stripe} alt=""/>
                    <img className="security-logos__icon" src={images.shopify} alt=""/>
                </span>

                <div className="wrapper-for-button">
                    <Button
                        onClick={() => this.__submitOrderToServer()}
                        label="Place your order"
                        className="dark-pink"
                        disabled={!this.__enoughDataToProceed()}
                    />
                </div>

                {
                    !this.props.StoreApp.creditsCoinEnabled &&
                    <div className="mp-modal__special-link-text">
                        By placing your order you agree to Klickly’s
                        <a href="https://www.klickly.com/terms" rel="noopener noreferrer" target="_blank" className="mp-modal__special-link"> Terms </a>
                        and
                        <a href="https://www.klickly.com/privacy" rel="noopener noreferrer" target="_blank" className="mp-modal__special-link"> Privacy Policy</a>
                    </div>
                }

            </div>
        );
    }

}

export default Confirmation;
