import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';

import RenderIf from "../../../helpers/renderIf";
import DiscountsService from '../../../services/discounts';

import './styles.scss';

@inject('StoreUser')
@inject('StoreDiscount')
@inject('StoreFavorites')
@inject('StoreProducts')
@observer
class ComponentFlashSale extends Component {

    @computed get discountText() {
        const { StoreDiscount, StoreFavorites } = this.props;
        const { discount: { type, rate, amountOfProducts = 5, freeShipping = false } } = StoreDiscount;

        if (type === 'usual') {
            return `1 DAY MEMBER FLASH SALE: ${rate}% OFF + FREE SHIPPING`;
        } else if (type === 'favorites') {
            const difference = amountOfProducts - StoreFavorites.items.length;

            if (difference <= 0) {
                return `MEMBER FLASH SALE: ${rate}% OFF${freeShipping ? ' + FREE SHIPPING' : ''}`;
            }

            return `ADD ${difference} ${difference === 1 ? 'PRODUCT' : 'PRODUCTS'} TO YOUR FAVORITES TO UNLOCK ${rate}% OFF`;

        }

        return null;
    }

    @computed get appliedDiscount() {
        const { StoreDiscount, StoreFavorites } = this.props;
        const { discount } = StoreDiscount;

        if (discount.type === 'usual') {
            return discount;
        } else if (discount.type === 'favorites') {
            const difference = discount.amountOfProducts - StoreFavorites.items.length;

            if (difference <= 0) {
                return discount;
            }
        }

        return null;
    }

    async componentDidMount () {
        const discount = await DiscountsService.getList();
        this.props.StoreDiscount.setAvailableDiscount(discount);
    }

    render() {
        const { StoreUser } = this.props;

        return (
            <div className={`flash-sale-wrapper ${this.discountText ? 'display' : ''}`}>
                <div className="flash-sale">
                    <h3 className="flash-sale__text">
                        { this.discountText }
                        <RenderIf condition={!StoreUser.isAuthenticated && this.appliedDiscount !== null}>
                            <Link to={'/sign-up'} className="flash-sale__link">SIGN UP</Link>
                        </RenderIf>
                    </h3>
                </div>
            </div>
        );
    }
}

export default ComponentFlashSale;
