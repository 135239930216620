import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import * as get from 'lodash/get';
import * as isEmpty from 'lodash/isEmpty';

import {
    Accordion,
    Headline,
    ActionButton,
    AccordionItems
} from '../../../components/reusable/AccordionWithForm';

import ShippingAddressForm from '../../../components/reusable/ShippingAddressForm';
import CreditCardForm from '../../../components/reusable/CreditCardForm';
import { AddressItem } from '../../../components/reusable/AddressItem';
import { CardItem } from '../../../components/reusable/CardItem';

import { Loader } from "../../../components/ui/Loader";
import CustomerService from "../../../services/customer";

import './accountSettings.scss';

@inject('StoreUser')
@observer
export class PageAccountSettings extends Component {

    @observable isFormOpened = false;
    formType = null;
    formExistingData = {};

    @action handlerAddItem(type) {
        this.isFormOpened = true;
        this.formType = type;
    }

    @action handlerEditItem(type) {
        if (type === 'address') {
            this.formExistingData = this.props.StoreUser.defaultAddress;
        } else {
            this.formExistingData = this.props.StoreUser.defaultCard;
        }

        this.isFormOpened = true;
        this.formType = type;
    }

    @action handlerCloseForm = () => {
        this.isFormOpened = false;
        this.formType = null;
        this.formExistingData = {};
    };

    render() {
        return (
            <div className="account-settings shipping-and-payment">
                { !this.isFormOpened ?
                    <Fragment>
                        <SettingsShippingInformation
                            enableDeleteItems={true}
                            handlerEditItem={(type) => this.handlerEditItem(type)}
                            handlerAddItem={(type) => this.handlerAddItem(type)}
                        />

                        <SettingsCreditCards
                            enableDeleteItems={true}
                            handlerEditItem={(type) => this.handlerEditItem(type)}
                            handlerAddItem={(type) => this.handlerAddItem(type)}
                        />
                    </Fragment>
                    :
                    <Fragment>
                        { this.formType === 'address' ?
                            <ShippingAddressForm
                                handlerOnSuccess={this.handlerCloseForm}
                                handlerOnCancel={this.handlerCloseForm}
                                existingData={this.formExistingData}
                            /> : null }

                        { this.formType === 'card' ?
                            <CreditCardForm
                                handlerOnSuccess={this.handlerCloseForm}
                                handlerOnCancel={this.handlerCloseForm}
                                existingData={this.formExistingData}
                                defaultAddress={this.props.StoreUser.defaultAddress}
                                editMode={Boolean(Object.keys(this.formExistingData).length)}
                            /> : null }
                    </Fragment>
                }
            </div>
        );
    }
}

@inject('StoreUser')
@observer
export class SettingsShippingInformation extends Component {
    @observable isLoading = false;

    @action onClickMakeDefault = async(_id, isDefault) => {
        if (!isDefault) {
            this.isLoading = true;

            await CustomerService.setDefaultAddress(_id);
            this.props.StoreUser.changeDefaultItem('shippingAddresses', _id);

            this.isLoading = false;
        }
    };

    @action handlerDeleteItem = async() => {
        this.isLoading = true;

        const { _id } = this.props.StoreUser.defaultAddress;

        const addresses = await CustomerService.removeAddress(_id);
        this.props.StoreUser.updateItem('shippingAddresses', addresses);

        this.isLoading = false;
    };

    render() {
        const { handlerAddItem, handlerEditItem, enableDeleteItems = false } = this.props;
        const itemsList = get(this.props, 'StoreUser.profile.shippingAddresses', []);

        return (
            <Accordion>
                <Headline title="Shipping information">
                    <div>
                        { itemsList.length > 1 && enableDeleteItems && <ActionButton onClick={this.handlerDeleteItem} name="Delete" className="remove" /> }
                        {
                            !isEmpty(itemsList) ?
                                <ActionButton onClick={() => handlerEditItem('address')} name="Edit" />
                                :
                                <ActionButton onClick={() => handlerAddItem('address')} name="Add" />
                        }
                    </div>
                </Headline>

                <AccordionItems
                    labelPart="addresses"
                    textPart="shipping address"
                    itemsList={itemsList}
                    listItemComponent={AddressItem}
                    onClickAddNew={() => handlerAddItem('address')}
                    onClickMakeDefault={this.onClickMakeDefault}
                />

                <Loader size="30px" display={this.isLoading} />
            </Accordion>
        );
    }
}

@inject('StoreUser')
@observer
export class SettingsCreditCards extends Component {
    @observable isLoading = false;

    @action onClickMakeDefault = async(_id, isDefault) => {
        if (!isDefault) {
            this.isLoading = true;

            await CustomerService.setDefaultCreditCard({ _id });
            this.props.StoreUser.changeDefaultItem('cards', _id);

            this.isLoading = false;
        }
    };

    @action handlerDeleteItem = async() => {
        this.isLoading = true;

        const { _id } = this.props.StoreUser.defaultCard;

        const addresses = await CustomerService.removeCreditCard(_id);
        this.props.StoreUser.updateItem('cards', addresses);

        this.isLoading = false;
    };

    render() {
        const { handlerAddItem, handlerEditItem, enableDeleteItems = false } = this.props;
        const itemsList = get(this.props, 'StoreUser.profile.cards', []);

        return (
            <Accordion>
                <Headline title="Billing information">
                    <div>
                        { itemsList.length > 1 && enableDeleteItems && <ActionButton onClick={this.handlerDeleteItem} name="Delete" className="remove" /> }
                        {
                            !isEmpty(itemsList) ?
                                <ActionButton onClick={() => handlerEditItem('card')} name="Edit" />
                                :
                                <ActionButton onClick={() => handlerAddItem('card')} name="Add" />
                        }
                    </div>
                </Headline>

                <AccordionItems
                    labelPart="cards"
                    textPart="credit card"
                    itemsList={itemsList}
                    listItemComponent={CardItem}
                    onClickMakeDefault={this.onClickMakeDefault}
                    onClickAddNew={() => handlerAddItem('card')}
                />

                <Loader size="30px" display={this.isLoading} />
            </Accordion>
        );
    }
}

