
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import CreditCardForm from '../../../../components/reusable/CreditCardForm';
import './index.scss';

@inject('StorePurchase')
@inject('StoreApp')
@inject('StoreUser')
@observer
class Billing extends Component {

    handlerOnSuccess = (cards, email) => {
        const { StorePurchase, StoreUser } = this.props;

        // Specific case for streamlined flow ( guest checkout where email is entered in shipping or billing section )
        if (!StoreUser.isAuthenticated && email) {
            StorePurchase.setValue('profile.email', email);
        }

        StorePurchase.setValue('profile.cards', cards);
        StorePurchase.changeStep('confirmation', true);
    };

    render() {
        const defaultAddress = this.props.StorePurchase.shippingExist ? this.props.StorePurchase.editableShippingData : null;
        const { creditsCoinEnabled } = this.props.StoreApp;
        return (
            <div className="mp-modal-purchase__billing">
                <CreditCardForm
                    isModal={true}
                    handlerOnSuccess={this.handlerOnSuccess}
                    existingData={this.props.StorePurchase.editableBillingData}
                    defaultAddress={defaultAddress}
                    editMode={Boolean(Object.keys(this.props.StorePurchase.editableBillingData).length)}
                    creditsCoinEnabled={creditsCoinEnabled}
                />
            </div>
        );
    }

}

export default Billing;
