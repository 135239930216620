
import changeHead from '../../helpers/changeHead';

import React from 'react';

export const PageQuickToLand = () => {
    changeHead(null, null, 'Free Gifts');
    return (
        <iframe title="quickto.shop Land" style={{ width: '100%', height: '100%', minHeight: '100vh' }} frameBorder="0" src="https://quicktoshop.carrd.co/" />
    );

};

