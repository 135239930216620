
import { computed } from "mobx";
import * as get from 'lodash/get';
import StoreRoot from "../stores";

class ProductVariantModel {
    object = null;
    maxQty = null;
    calculateKlicklyPrice = null;

    constructor(variant, maxQty, calculateKlicklyPrice) {
        this.object = variant;
        this.maxQty = maxQty;
        this.calculateKlicklyPrice = calculateKlicklyPrice;

        if (this.object.originalPrice <= this.object.price) {
            this.object.originalPrice = 0;
        }
    }

    get(field, defaultValue = '') {
        return get(this.object, field, defaultValue);
    }

    get klicklyOriginalPrice() {
        return this.get('price', 0);
    }

    get externalId() {
        return this.get('externalId');
    }

    @computed get quantity() {
        return this.maxQty(this.get('quantity', 0));
    }

    @computed get klicklyPrice() {
        if (StoreRoot.StoreApp.creditsGiftMode) {
            const creditsRequired = this.get('creditsRequired', 0);
            const klicklyPrice = this.calculateKlicklyPrice(creditsRequired);

            return klicklyPrice ? klicklyPrice : creditsRequired;
        }
        return this.calculateKlicklyPrice(this.get('price', 0));
    }

    @computed get creditsRequired() {
        return this.get('creditsRequired', 0);
    }

    @computed get creditsEarned() {
        return this.get('creditsEarned', 0);
    }

    @computed get price() {
        return this.klicklyPrice ? this.klicklyPrice : this.get('price', 0);
    }

    @computed get originalPrice() {
        if (StoreRoot.StoreApp.creditsGiftMode) {
            return this.klicklyPrice ? this.object.price : this.object.originalPrice;
        }
        return this.object.originalPrice ? this.object.originalPrice : this.klicklyPrice ? this.object.price : 0;
    }
}

export default ProductVariantModel;
