
import * as each from 'lodash/each';
import * as capitalize from 'lodash/capitalize';
import statesAbbr from '../constants/statesAbbr';
import * as values from "lodash/values";
import * as merge from "lodash/merge";
import * as keyBy from "lodash/keyBy";

export const sanitizeProductName = (value) => {
    return value
        .replace(/[^a-zA-Z0-9\s]+/g, '')
        .replace(/\s/g, '_');
};

export const minTwoDigits = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
};

export const roundToLocaleString = (value, maxDigits = 2) => {
    const toLocaleStringOptions = {
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits
    };

    return Number(value)
        .toLocaleString('en-US', toLocaleStringOptions)
        .replace(/,$/, '.');
};

export const round = (value, factor = 100) => {
    return Math.round(value * factor) / factor;
};

export const onlyDigits = (str) => {
    return str.match(/\d+/g).join([]);
};

export const prepareImageUrl = (src, pattern = '_180x$1') => {
    // Replace only last instance of image file extension
    return src.replace(/(\.jpg(?=[^.jpg]*$)|\.png(?=[^.png]*$)|\.gif(?=[^.gif]*$)|\.jpeg(?=[^.jpeg]*$))/, pattern);
};

export const discountFormula = (price, discountRate) => {
    return Math.round((price - price * (discountRate / 100)) * 100) / 100;
};

export const parseURL = (url) => {
    if (!url) {
        return '';
    }

    if (window.URL) {
        return new URL(url).host;
    }
    const match = url.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && match[2];
};

export const timeElapsed = () => {
    const now = new Date().getTime();
    return ((now - performance.timing.navigationStart) / 1000).toFixed(1);
};

export const mergeArraysBy = (a, b, key) => {
    return values(merge(keyBy(a, key), keyBy(b, key)));
};

export const compareAddresses = (keys, address1, address2) => {
    let theSame = true;

    each(keys, (key) => {
        if ((address1[key] || address2[key]) && (address1[key] !== address2[key])) {
            theSame = false;
            return false;
        }
    });

    return theSame;
};

export const generateStateField = (fieldValue) => {
    const stateField = fieldValue.toLocaleLowerCase();
    const foundStateByAbbreviation = statesAbbr
        .find(({ label }) => stateField === label.toLocaleLowerCase());
    return capitalize(foundStateByAbbreviation ? foundStateByAbbreviation.value : fieldValue);
};

export const calculateCreditsAndDollarAmount = (price, userCredits, multiplier) => {
    const prices = {
        dollarAmount: 0,
        credits: price
    };

    if (price <= userCredits) {
        return prices;
    }

    prices.dollarAmount = round((price - userCredits) / multiplier);
    prices.credits = userCredits;
    return prices;
};

export const getURLParam = (name, url) => {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[[]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const loadURL = (url) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};
