import React, { Component, Fragment } from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Route } from 'react-router-dom';
import classNames from 'classnames';

import * as pages from '../index';

import ComponentModalTutorial from '../../components/marketplace/ModalTutorial';
import ComponentFlashSale from '../../components/marketplace/FlashSale';
import ComponentHeader from '../../components/marketplace/Header';
import ComponentBanner from '../../components/marketplace/Banner';
import ComponentCategoryFilter from '../../components/marketplace/CategoryFilter';
import ComponentFooter from '../../components/marketplace/Footer';
import ComponentProductsList from '../../components/marketplace/ProductsList';
import QuicktoHelper from '../../helpers/quickto';
import changeHead from '../../helpers/changeHead';
import AuthService from '../../services/auth';

@inject('StoreTutorial')
@inject('StoreApp')
@inject('StoreSearch')
@inject('StoreProducts')
@inject('StoreFavorites')
@inject('StoreUser')
@inject('StorePurchase')
@inject('StoreCategoryFilter')
@inject('StoreDiscount')
@observer
export class PageMarketplace extends Component {
    fixedElementsRef = null;
    categoryFilterRef = null;

    constructor (props) {
        super(props);

        QuicktoHelper.initTheQuickToParams();

        const {
            location: { search, pathname },
            match: { params: { categoryName = 'popular' } },
            StoreSearch,
            StoreProducts,
            StoreCategoryFilter,
            StoreApp
        } = this.props;

        if (categoryName === 'search-results') {
            const searchQuery = decodeURI(search.slice(3));
            StoreSearch.searchQueryOnChange(searchQuery);
            StoreProducts.proceedSearchQuery(searchQuery);
            changeHead(null, searchQuery);

            StoreApp.setLastURL(`${pathname}${search}`);
        } else {
            const category = StoreCategoryFilter.changeFilterCategory(categoryName);
            if (category) {
                StoreProducts.proceedSearchCategory(category.key);
                changeHead(category);
                StoreApp.setLastURL(`/${category.key}`);
            }
        }
    }

    @observable makeFloatingElementsFixed = false;
    windowScrollHandlerApp = () => {
        const { StoreApp } = this.props;
        if (this.fixedElementsRef && this.categoryFilterRef) {
            const filterDiv = this.categoryFilterRef.getBoundingClientRect();
            const additionalHeight = this.props.StoreDiscount.isDiscountAvailable ? 0 : 40;
            const pageOffset = filterDiv.top - this.fixedElementsRef.scrollHeight + additionalHeight + (StoreApp.scrollDirection === 'scrollDown' ? filterDiv.height : 0);

            this.makeFloatingElementsFixed = pageOffset < 0;
        }
    };

    async componentDidMount() {
        window.addEventListener('scroll', this.windowScrollHandlerApp);

        try {
            const profile = await AuthService.checkAuth();

            this.props.StoreUser.setProfile(profile);
            this.props.StorePurchase.setProfile(profile);
        } catch (error) {
            this.props.StoreUser.setProfile(null);
            this.props.StorePurchase.setProfile(null);
        }

        this.props.StoreFavorites.syncFavoritesProductsWithServer(true);
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.windowScrollHandlerApp);
    }

    __categoryProductList() {
        return (
            <Fragment>
                <div ref={(ref) => {
                    this.categoryFilterRef = ref;
                }}>
                    <ComponentCategoryFilter isFixed={this.makeFloatingElementsFixed} />
                </div>

                <ComponentProductsList isFavoritesFixed={this.makeFloatingElementsFixed} />
            </Fragment>
        );
    }

    render() {
        const { StoreCategoryFilter } = this.props;
        return (
            <Fragment>
                {
                    !this.props.StoreApp.creditsCoinEnabled &&
                    <ComponentModalTutorial />
                }
                <div className={classNames('market-place', { 'sale': this.props.StoreDiscount.isDiscountAvailable })}>
                    <div ref={(ref) => {
                        this.fixedElementsRef = ref;
                    }}>
                        <ComponentFlashSale />
                        <ComponentHeader />
                    </div>

                    {
                        !this.props.StoreApp.creditsCoinEnabled && StoreCategoryFilter.searchCategory === 'popular' &&
                        <ComponentBanner />

                    }
                    { this.__categoryProductList() }

                    { !this.props.StoreApp.creditsCoinEnabled && <ComponentFooter /> }

                </div>

                <Route path="/sign-in" component={pages.PageSignIn}/>
                <Route path="/sign-up" component={pages.PageSignUp}/>
                <Route path="/sign-up2" render={props => {
                    const { PageSignUp } = pages;
                    return <PageSignUp {...props} isSecondaryType={true}/>;
                }}/>
                <Route path="/about" component={pages.PageAbout}/>
                <Route path="/:productName/:productId/:modalStep?" component={pages.PageProduct}/>
            </Fragment>
        );
    }
}
