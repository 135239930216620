import { observable, action, computed, extendObservable } from 'mobx';
import * as find from 'lodash/find';
import * as set from 'lodash/set';
import * as sortBy from 'lodash/sortBy';
import * as get from 'lodash/get';
import { localStore, sessionStore } from '../../helpers/browserStores';
import customerFlow from '../../helpers/customerFlow';

import { round, calculateCreditsAndDollarAmount } from '../../helpers/static';
import constants from '../../constants/main';

class StorePurchase {
    purchaseOn = process.env.INSTANCE_TYPE;
    widgetSize = null;

    get customerFlow() {
        const { flow } = sessionStore.get('quickto', { flow: null });
        return flow;
    }

    get isGiveAwayProduct() {
        const { flow, productID } = sessionStore.get('quickto', {});
        const isGiftMode = localStore.get('creditsCoinMode', 'buy') === 'gift' && Boolean(parseInt(process.env.CREDITS_COIN_ENABLED, 10));
        return !isGiftMode && flow === 'giveaway' && productID === get(this, 'store.product.id');
    }

    get isFreeProduct() {
        const { flow, productID } = sessionStore.get('quickto', {});
        const isGiftMode = localStore.get('creditsCoinMode', 'buy') === 'gift' && Boolean(parseInt(process.env.CREDITS_COIN_ENABLED, 10));
        return !isGiftMode && flow === 'freeproduct' && productID === get(this, 'store.product.id');
    }

    get isStreamlineProduct() {
        const { flow, productID } = sessionStore.get('quickto', {});
        const isGiftMode = localStore.get('creditsCoinMode', 'buy') === 'gift' && Boolean(parseInt(process.env.CREDITS_COIN_ENABLED, 10));
        return !isGiftMode && flow === 'streamline' && productID === get(this, 'store.product.id');
    }

    get isShipFreeProduct() {
        const { flow, productID } = sessionStore.get('quickto', {});
        const isGiftMode = localStore.get('creditsCoinMode', 'buy') === 'gift' && Boolean(parseInt(process.env.CREDITS_COIN_ENABLED, 10));
        return !isGiftMode && flow === 'shipfree' && productID === get(this, 'store.product.id');
    }

    get hasProfileEmail() {
        return Boolean(get(this, 'store.profile.email', false));
    }

    get productInExpandableUnit() {
        return this.purchaseOn === 'widget-buy';
    }

    get typeOfUnit() {
        return this.purchaseOn === 'widget-buy' ? 'expandableWidget' : null;
    }

    handleCloseModalFunction = null;
    handleCloseModal() {
        if (this.handleCloseModalFunction) {
            this.handleCloseModalFunction();
        }
    }

    handleSimilarProductClickFunction = null;
    handleSimilarProductClick(product) {
        if (this.handleSimilarProductClickFunction) {
            this.handleSimilarProductClickFunction(product);
        }
    }

    handleModalStepChangeFunction = null;
    handleModalStepChange(product, step) {
        if (this.handleModalStepChangeFunction) {
            this.handleModalStepChangeFunction(product, step);
        }
    }

    @observable store = {};

    @action initNewStore(profile) {
        this.store = {};

        extendObservable(this.store, {
            step: 'productInfo',
            addNewShipping: false,
            addNewBilling: false,
            product: null,
            options: [],
            isLoading: false,
            checkout: {},
            profile,
            draftOrder: null
        });
    }

    @action clearFormActionStatuses() {
        this.store.addNewShipping = false;
        this.store.addNewBilling = false;
    }

    @action setValue(field, value) {
        set(this.store, field, value);
    }

    @computed get editableShippingData() {
        if (!this.store.addNewShipping && this.store.profile) {
            return find(this.store.profile.shippingAddresses, { isDefault: true }) || {};
        }

        return {};
    }

    @computed get editableBillingData() {
        if (!this.store.addNewBilling && this.store.profile) {
            return find(this.store.profile.cards, { isDefault: true }) || {};
        }

        return {};
    }

    @computed get shippingExist() {
        return Object.keys(this.editableShippingData).length !== 0;
    }

    @computed get billingExist() {
        return Object.keys(this.editableBillingData).length !== 0;
    }

    @action setCheckout(variant, qty, isFreeShipping) {
        const options = sortBy(this.store.product.options.filter((option) => option.position !== 0), 'position');

        this.store.checkout = {
            variant: variant,
            variantExternalId: variant.get('externalId'),
            subtotalPrice: variant.klicklyOriginalPrice * qty,
            requiresShipping: variant.get('requiresShipping'),
            shippingPrice: null,
            taxPrice: null,
            options,
            qty,
            isFreeShipping
        };
    }

    @action setQty(qty) {
        this.store.checkout.qty = qty;
        this.store.checkout.subtotalPrice = this.store.checkout.variant.klicklyOriginalPrice * qty;
    }

    @computed get memberCreditsDiscountPrice() {
        return round(this.store.product.memberDiscountPrice(
            this.store.checkout.variant.price,
            this.store.checkout.variant.creditsRequired,
            this.store.checkout.qty,
            this.store.checkout.isFreeShipping ? this.store.checkout.shippingPrice * this.creditsMultiplier : 0
        ) / this.creditsMultiplier);
    }

    @computed get memberDiscountPrice() {
        const { store: { product, checkout }, requiresShipping } = this;

        let discount = null;

        if (customerFlow.isFlowEnabled) {
            if (customerFlow.isFreeProductFlow) {
                discount = checkout.variant.price * checkout.qty;
            }

            if (customerFlow.isGiveAwayFlow || customerFlow.isShipFreeFlow) {
                discount = checkout.variant.price * checkout.qty;
                discount += checkout.taxPrice === null ? checkout.variant.price * 0.1 : checkout.taxPrice;
                if (requiresShipping) {
                    discount += checkout.shippingPrice === null ? 2.99 : checkout.shippingPrice;
                }
            }

            const specialOffer = customerFlow.getStreamlinedSpecialOffer(product.get('id'));

            if (specialOffer) {
                discount = specialOffer.discount;
            }
        } else {
            discount = round(product.memberDiscountPrice(
                checkout.variant.price,
                checkout.variant.klicklyOriginalPrice,
                checkout.qty,
                requiresShipping ? checkout.shippingPrice : 0
            ));
        }

        return discount;
    }

    @computed get requiresShipping() {
        return this.store.checkout && this.store.checkout.requiresShipping;
    }

    @observable userCredits = constants.DEFAULT_CREDITS_AMOUNT;
    creditsMultiplier = 100;
    @action setCreditsData(credits, multiplier = null) {
        this.userCredits = credits;
        if (multiplier !== null) {
            this.creditsMultiplier = multiplier;
        }
    }

    @computed get coinsEarned() {
        return this.store.checkout.variant.creditsEarned * this.store.checkout.qty;
    }

    @computed get totalCreditsPrice() {
        const productCreditsDiscount = round(this.store.product.memberDiscountPrice(
            this.store.checkout.variant.price,
            this.store.checkout.variant.klicklyOriginalPrice,
            this.store.checkout.qty,
            0));
        const productPrice = this.store.checkout.variant.price * this.store.checkout.qty - productCreditsDiscount;
        const gift = calculateCreditsAndDollarAmount(productPrice, this.userCredits, this.creditsMultiplier);

        const shippingPrice = this.parseFloat(this.store.checkout.shippingPrice);
        const taxPrice = this.parseFloat(this.store.checkout.taxPrice);

        gift.dollarAmount = round(gift.dollarAmount + taxPrice + (!this.store.checkout.isFreeShipping ? shippingPrice : 0));
        if (gift.dollarAmount !== 0 && gift.dollarAmount < constants.MINIMUM_STRIPE_AMOUNT) {
            gift.dollarAmount = constants.MINIMUM_STRIPE_AMOUNT;
        }
        return gift;
    }

    @computed get totalPurchaseCreditsPrice() {
        return round(this.store.checkout ?
            this.parseFloat(this.store.checkout.subtotalPrice) +
            this.parseFloat(this.store.checkout.shippingPrice) +
            this.parseFloat(this.store.checkout.taxPrice) -
            this.parseFloat(this.memberCreditsDiscountPrice)
            : 0);
    }

    @computed get totalPurchasePriceWithoutDiscount() {
        const { checkout } = this.store;
        let subtotalPrice = checkout.subtotalPrice;
        let shippingPrice = checkout.shippingPrice;
        let taxPrice = checkout.taxPrice;

        if (this.requiresShipping) {
            if (checkout.shippingPrice === null) {
                shippingPrice = 2.99;
            }
            const specialOffer = customerFlow.getStreamlinedSpecialOffer(this.store.product.get('id'));
            if (specialOffer) {
                shippingPrice = specialOffer.shipping;
            }
        }

        if (checkout.taxPrice === null) {
            taxPrice = checkout.subtotalPrice * 0.1;
        }

        return round(checkout ?
            this.parseFloat(subtotalPrice) +
            this.parseFloat(shippingPrice) +
            this.parseFloat(taxPrice)
            : 0);
    }

    @computed get totalPurchasePrice() {
        const { checkout } = this.store;
        let subtotalPrice = checkout.subtotalPrice;
        let shippingPrice = checkout.shippingPrice;
        let taxPrice = checkout.taxPrice;

        if (this.requiresShipping) {
            if (checkout.shippingPrice === null) {
                shippingPrice = 2.99;
            }
            const specialOffer = customerFlow.getStreamlinedSpecialOffer(this.store.product.get('id'));
            if (specialOffer) {
                shippingPrice = specialOffer.shipping;
            }
        }

        if (checkout.taxPrice === null) {
            taxPrice = checkout.subtotalPrice * 0.1;
        }

        return round(checkout ?
            this.parseFloat(subtotalPrice) +
            this.parseFloat(shippingPrice) +
            this.parseFloat(taxPrice) -
            this.parseFloat(this.memberDiscountPrice)
            : 0);
    }

    parseFloat(value) {
        return value ? round(parseFloat(value)) : 0;
    }

    @action setProfile(profile) {
        this.store.profile = profile;
    }

    @action setCheckoutValue(field, value) {
        if (typeof this.store.checkout[field] !== 'undefined') {
            this.store.checkout[field] = value;
        } else {
            const obj = {};
            obj[field] = value;
            extendObservable(this.store.checkout, obj);
        }
    }

    @action setProduct(product) {
        this.store.product = product;
    }

    @action changeStep(step, smartRedirect = false, clearFormActionStatuses = true) {
        if (clearFormActionStatuses) {
            this.clearFormActionStatuses();
        }

        if (!smartRedirect) {
            this.store.step = step;
        } else {
            const { isGiveAwayProduct } = this;

            const { profile = null } = this.store;
            if (profile && profile.email) {
                const requiresShipping = this.store.checkout.requiresShipping;
                const defaultCreditCard = find(profile.cards, { isDefault: true });
                if (!(profile.shippingAddresses && profile.shippingAddresses.length) && requiresShipping) {
                    this.store.step = 'shipping';
                } else if (!(defaultCreditCard && defaultCreditCard.address1) && !isGiveAwayProduct) {
                    this.store.step = 'billing';
                } else {
                    this.store.step = 'confirmation';
                }
            } else {
                this.store.step = 'customerInfo';
            }
        }

        this.handleModalStepChange(this.store.product, this.store.step);
    }

    @action toggleLoader(status = null) {
        if (status === null) {
            this.store.isLoading = !this.store.isLoading;
        } else {
            this.store.isLoading = status;
        }
    }

    @observable isImageViewInMobile = false;
    @action openImageViewInMobile() {
        this.isImageViewInMobile = true;
    }

    @action closeImageViewInMobile() {
        this.isImageViewInMobile = false;
    }

    getPayload(creditsGiftMode) {
        let shippingPrice = 0;

        if (this.requiresShipping) {
            const specialOffer = customerFlow.getStreamlinedSpecialOffer(this.store.product.get('id'));
            if (specialOffer) {
                shippingPrice = specialOffer.shipping;
            } else {
                shippingPrice = this.store.checkout.shippingPrice;
            }
        }

        return {
            purchaseOn: this.purchaseOn,
            profile: this.store.profile,
            mode: creditsGiftMode ? 'gift' : 'buy',
            checkout: {
                ...this.store.checkout,
                accountId: this.store.product.get('account'),
                promotionId: this.store.product.id,
                campaignId: this.store.product.get('campaign'),
                memberDiscountPrice: this.memberDiscountPrice,
                totalPurchasePrice: creditsGiftMode ? this.totalPurchaseCreditsPrice : this.totalPurchasePrice,
                totalCreditsPrice: this.totalCreditsPrice,
                shippingPrice
            },
            shippingLine: this.store.draftOrder.shipping_line
        };
    }

}

export default StorePurchase;
