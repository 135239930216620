
import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import * as capitalize from "lodash/capitalize";
import * as classNames from 'classnames';

import { prepareImageUrl, roundToLocaleString } from '../../../../../helpers/static';
import DiscountTooltip from "../../../../../components/reusable/DiscountTooltip";
import { circleInfo } from '../../../../../images/quickTo';
import './index.scss';
import customerFlow from "../../../../../helpers/customerFlow";
import {getProjectData} from "../../../../../projectMap";

const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

@inject('StorePurchase')
@inject('StoreDiscount')
@inject('StoreApp')
@observer
class PurchaseInfo extends Component {

    constructor(props) {
        super(props);
        this.specialOffer = customerFlow.getStreamlinedSpecialOffer(this.props.StorePurchase.store.product.get('id'));
    }


    __convertAmountToString(value) {
        const { StorePurchase } = this.props;
        const { store: { step } } = StorePurchase;

        if (step === 'confirmation') {
            if (StorePurchase.requiresShipping) {
                if (!(StorePurchase.shippingExist && StorePurchase.billingExist) && !StorePurchase.isGiveAwayProduct) {
                    return <span className="calculated-on-step">Need shipping and billing</span>;
                }
            } else if (!StorePurchase.billingExist && !StorePurchase.isGiveAwayProduct) {
                return <span className="calculated-on-step">Need billing</span>;
            } else if (!StorePurchase.editableBillingData.address1 && !StorePurchase.isGiveAwayProduct) {
                return <span className="calculated-on-step">Need billing address</span>;
            }
        }

        return value === null ? <span className="calculated-on-step">Calculated at final step</span> :
            value ? `$${roundToLocaleString(value)}` : '-';
    }

    get subtotal() {
        return `$${roundToLocaleString(this.props.StorePurchase.store.checkout.subtotalPrice)}`;
    }

    get shipping() {
        const { StorePurchase } = this.props;

        if (StorePurchase.requiresShipping) {
            if (this.specialOffer) {
                return `$${roundToLocaleString(this.specialOffer.shipping)}`;
            }
            if (!StorePurchase.store.checkout.shippingPrice) {
                return `$${roundToLocaleString(2.99)}`;
            }
        }

        return this.__convertAmountToString(StorePurchase.store.checkout.shippingPrice);
    }

    get tax() {
        const { StorePurchase: { store } } = this.props;

        if (store.checkout.taxPrice === null) {
            return `$${roundToLocaleString(store.checkout.subtotalPrice * 0.1)}`;
        }

        return this.__convertAmountToString(store.checkout.taxPrice);
    }

    get discount() {
        const { StorePurchase, StoreApp } = this.props;

        return `-$${roundToLocaleString(StoreApp.creditsGiftMode
            ? StorePurchase.memberCreditsDiscountPrice
            : StorePurchase.memberDiscountPrice
        )}`;
    }

    get totalBeforeTax() {
        const { StorePurchase: { store, requiresShipping } } = this.props;

        if (!requiresShipping) {
            return `$${roundToLocaleString(store.checkout.subtotalPrice)}`;
        }

        let shipping = !store.checkout.shippingPrice ? 2.99 : store.checkout.shippingPrice;

        if (this.specialOffer) {
            shipping = this.specialOffer.shipping;
        }

        return `$${roundToLocaleString(store.checkout.subtotalPrice + shipping)}`;
    }

    get total() {
        if (this.props.StorePurchase.totalPurchasePrice < 0) {
            return '-';
        }

        return `$${roundToLocaleString(this.props.StorePurchase.totalPurchasePrice)}`;
    }

    get totalWithoutDiscount() {
        if (this.props.StorePurchase.totalPurchasePriceWithoutDiscount < 0) {
            return '-';
        }

        return `$${roundToLocaleString(this.props.StorePurchase.totalPurchasePriceWithoutDiscount)}`;
    }

    render() {
        const { StorePurchase, StoreApp } = this.props;
        const { product, checkout, step } = StorePurchase.store;
        const { creditsGiftMode } = StoreApp;
        const { isGiveAwayProduct, isFreeProduct, isShipFreeProduct } = StorePurchase;
        const isCustomerFlow = isGiveAwayProduct || isFreeProduct || isShipFreeProduct;

        const isBilling = step === 'billing';
        const isShipping = step === 'shipping';
        const isEstimatedLabel = isCustomerFlow && (isBilling || isShipping);
        const discountLabel = isCustomerFlow ? 'New Member Bonus' : 'Member discount';
        const shippingLabel = isEstimatedLabel ? 'Estimated shipping' : 'Shipping';
        const taxLabel = isEstimatedLabel ? 'Estimated tax' : 'Tax';
        // Quickto balance means that some flow is enabled and customer has some discount via flow
        const isQuickToBalance = customerFlow.isFlowEnabled && StorePurchase.memberDiscountPrice > 0;

        return (
            <div className="details">
                <div className="details-order">
                    <h3 className="details__title">Order details</h3>
                    <OrderRow label="Items" classPrefix="subtotal" value={this.subtotal}/>
                    <OrderRow label={shippingLabel} value={StorePurchase.requiresShipping ? this.shipping : '-'}/>
                    <OrderRow label="Total before tax" value={this.totalBeforeTax}/>
                    <OrderRow label={taxLabel} value={this.tax}/>
                    {!isQuickToBalance && <OrderRow label={discountLabel} value={this.discount} classPrefix="discount" tooltipOnLabel={true}/>}
                    <OrderRow
                        label="Total"
                        classPrefix={creditsGiftMode ? 'coins-total' : 'total'}
                        value={isQuickToBalance ? this.totalWithoutDiscount : this.total}
                        iconSrc={getProjectData('coins-active')}/>
                    {
                        isQuickToBalance &&
                        <Fragment>
                            <OrderRow label={`${getProjectData('title')} balance`} value={this.discount.slice(1)}/>
                            <OrderRow
                                label="Total owed"
                                classPrefix='total'
                                value={this.total}
                                tooltipInfo={`Your ${getProjectData('title')} account is a smart way to pay across all of ${getProjectData('title')}'s offerings. We've pre-loaded your account with ${this.discount.slice(1)} to get you started.`}/>
                        </Fragment>
                    }
                </div>

                <div className="details-product-wrapper">
                    <h3 className="details__title">Product details</h3>
                    <div className="details-product">
                        <div className="details-product__image-wrapper">
                            <img src={`${prepareImageUrl(product.get('image'), '_105x$1')}`} alt="" className="details-product__image"/>
                        </div>
                        <div className="details-product__info">
                            <div className="wrapper-tooltip">
                                <ReactTooltip effect="float" place="top" html />
                            </div>
                            <span className="details-product__name" data-tip={ product.get('title') }>
                                { product.get('title') }
                            </span>
                            <span className="details-product__brand" data-tip={ product.get('brandName') }>
                                { product.get('brandName') }
                            </span>
                            <span className="details-product__option">Qty: { checkout.qty }</span>
                            {
                                checkout.options.map((option) => {
                                    return (
                                        <span className="details-product__option" key={`product__option-${option.position}`}>
                                            {capitalize(option.name)}: {checkout.variant.get(`option${option.position}`)}
                                        </span>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

class OrderRow extends React.Component {

    overlay;
    ref;

    componentDidMount() {
        if (!this.props.tooltipInfo) {
            return;
        }
        this.overlay = document.querySelector('.overlay');
        this.overlay.addEventListener('scroll', this.hideTooltip);
        this.overlay.addEventListener('wheel', this.hideTooltip);
    }

    componentWillUnmount() {
        if (!this.props.tooltipInfo) {
            return;
        }
        this.overlay.removeEventListener('scroll', this.hideTooltip);
        this.overlay.removeEventListener('wheel', this.hideTooltip);
    }

    hideTooltip = () => {
        this.ref = null;
        ReactTooltip.hide();
    };

    render () {
        const { label, value, classPrefix = '', tooltipOnLabel = false, tooltipInfo, iconSrc } = this.props;
        const additionalClass = { [`details-order__row--${classPrefix}`]: classPrefix };
        const toolTipProps = {
            'data-for': label,
            'data-tip': tooltipInfo,
            'data-event': 'click'
        };

        return (
            <div className={classNames('details-order__row', additionalClass)}>
                <div className="details-order__label" {...(isMobileDevice ? toolTipProps : {})}
                >
                    { tooltipOnLabel ?
                        <DiscountTooltip
                            authText="Your"
                            notAuthText="Member"
                            discountLabelText="discount"
                        >
                            { label }
                        </DiscountTooltip> :

                        label
                    }
                    { tooltipInfo &&
                    <Fragment>
                        <ReactTooltip
                            id={label}
                            effect="solid"
                            place={'right'}
                            html
                            globalEventOff='click'
                        />
                        <img
                            ref={ref => {
                                this.ref = ref;
                            }}
                            src={circleInfo}
                            className="icon info-icon"
                            alt="Information about quickto"
                            {...(!isMobileDevice ? toolTipProps : {})}
                        />
                    </Fragment>
                    }
                </div>
                <span className="details-order__value">
                    { iconSrc && classPrefix === 'coins-total' &&
                        <div className="details-order__project-icon"><img src={iconSrc} alt="icon"/></div> }
                    { value }
                </span>
            </div>
        );
    }
}

export default PurchaseInfo;
