import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observable, action } from "mobx";
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import * as map from 'lodash/map';

import RequireAuthenticationHOC from "../../components/static/RequireAuthenticationHOC";
import ComponentHeader from '../../components/marketplace/Header';

import { PageSettingsProfile } from './profile';
import { PageOrderHistory } from './orderHistory';
import { PageAccountSettings } from './accountSettings';
import RenderIf from '../../helpers/renderIf';
import Loader from "../../components/ui/Loader";

const constantTabs = {
    '/settings/profile': 'Profile',
    '/settings/account-settings': 'Account settings',
    '/settings/order-history': 'Order history'
};

@inject('StoreApp')
@inject('StoreUser')
@inject('StoreFavorites')
@inject('StoreNotifications')
@observer
export class PageSettings extends Component {
    @observable showMobileUserMenu = true;
    @action toggleMobileUserMenu(status) {
        if (status) {
            this.showMobileUserMenu = status;
        } else {
            this.showMobileUserMenu = !this.showMobileUserMenu;
        }
    }

    @observable displayLogoutLoader = false;
    @action handleLogoutClick = async(event) => {
        event.stopPropagation();
        event.preventDefault();

        this.displayLogoutLoader = true;
        try {
            await this.props.StoreUser.logout();
            this.props.StoreFavorites.clearFavoriteProducts();

            this.props.history.push('/');
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }

        this.displayLogoutLoader = false;
    };

    @action handleSelectTabClick = (path) => {
        if (this.showMobileUserMenu) {
            this.toggleMobileUserMenu(false);
        }
        return this.props.history.push(path);
    };

    @action handleBackArrowClick = () => {
        if (this.showMobileUserMenu) {
            this.props.history.push('/');
        } else {
            this.toggleMobileUserMenu();
        }
    };

    render() {
        const { location: { pathname }, StoreUser } = this.props;
        const { profile } = StoreUser;
        const { isMobile, creditsCoinEnabled } = this.props.StoreApp;
        return (
            <Fragment>
                <div className="settings-wrapper">
                    <ComponentHeader displaySearchBlock={false} displayFavoritesBlock={false} className="settings-header" />
                    <div className={classNames('settings-container', {'settings-container--quick-to': creditsCoinEnabled})}>

                        <div className="aside">
                            <RenderIf condition={isMobile}>
                                <div className={classNames('aside-mobile-header', {'menu-opened': this.showMobileUserMenu})}>
                                    <span onClick={this.handleBackArrowClick} className="header-arrow" />
                                    <span className="header-title">{this.showMobileUserMenu ? `Welcome${profile && profile.firstName ? `, ${profile.firstName}` : ''}!` : constantTabs[pathname]}</span>
                                </div>
                            </RenderIf>

                            <div className={classNames('tabs-container', {'menu-opened': this.showMobileUserMenu})}>
                                <RenderIf condition={isMobile}>
                                    <SettingsTab
                                        label="Marketplace"
                                        isActive={false}
                                        onClick={this.handleBackArrowClick}
                                    />
                                </RenderIf>
                                {map(constantTabs, (tabLabel, path) =>
                                    <SettingsTab
                                        key={`settings-tab-${path}`}
                                        label={tabLabel}
                                        isActive={pathname === path}
                                        onClick={() => this.handleSelectTabClick(path)}
                                    />
                                )}
                                <RenderIf condition={isMobile}>
                                    <Fragment>
                                        <SettingsTab
                                            label="Sign out"
                                            isActive={false}
                                            onClick={this.handleLogoutClick}
                                        />
                                        <Loader display={this.displayLogoutLoader} />
                                    </Fragment>
                                </RenderIf>
                            </div>
                        </div>

                        <div className="market-place-settings-content">
                            <RequireAuthenticationHOC>
                                <Switch>
                                    {
                                        !isMobile && <Redirect exact from="/settings" to="/settings/profile"/>
                                    }
                                    <Route path="/settings/profile" component={PageSettingsProfile} />
                                    <Route path="/settings/order-history" component={PageOrderHistory} />
                                    <Route path="/settings/account-settings" component={PageAccountSettings} />
                                </Switch>
                            </RequireAuthenticationHOC>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

class SettingsTab extends Component {
    render() {
        const { onClick, isActive, label } = this.props;
        return (
            <div onClick={onClick} className={classNames('settings-tab', {'tab_active': isActive})}>
                <span className="settings-tab__link">{ label }</span>
            </div>
        );
    }
}
