import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import * as shuffle from 'lodash/shuffle';

import RenderIf from '../../../helpers/renderIf';
import Button from '../../ui/Button';
import { Loader } from '../../ui/Loader';
import { ProductCard } from '../ProductCard';
import { sanitizeProductName } from "../../../helpers/static";
import { withRouter } from 'react-router-dom';

import './banner.scss';

const BANNER_PRODUCTS_LENGTH = 3;

@inject('StoreUser')
@inject('StoreProducts')
@observer
class ComponentBanner extends Component {

    randomProducts = [];

    render() {
        const { StoreUser, StoreProducts } = this.props;
        const { products = [] } = StoreProducts;

        if (this.randomProducts.length < BANNER_PRODUCTS_LENGTH && products.length) {
            this.randomProducts = shuffle(products).slice(0, BANNER_PRODUCTS_LENGTH);
        }

        return (
            <div className="banner">
                <div className="banner__body">
                    <div className="banner__info">
                        <h4 className="banner__title">Discover. Vote. Earn.</h4>
                        <p className="banner__sub-title">
                            Klickly helps you discover trending products, as voted by your friends.
                        </p>
                        <div className="banner__list">
                            <p className="banner__list__item">
                                <span className="banner__list__number">1</span>
                                Find hot new stuff from top U.S. brands.
                            </p>
                            <p className="banner__list__item">
                                <span className="banner__list__number">2</span>
                                Give your opinion and vote them up or down.
                            </p>
                            <p className="banner__list__item">
                                <span className="banner__list__number">3</span>
                                Unlock savings and rewards on top performers.
                            </p>
                        </div>
                    </div>
                    <div className="banner__cards">
                        <div className="banner__cards__wrapper">
                            <Loader
                                size="90px"
                                display={!this.randomProducts.length}
                            />
                            {
                                this.randomProducts.length
                                    ? this.randomProducts.map((product, key) => {
                                        return (
                                            <ProductCard
                                                key={key}
                                                product={product}
                                                onClick={(product) => this.props.history.push(`/${sanitizeProductName(product.get('title'))}/${product.get('id')}`)}
                                            />
                                        );
                                    })
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <RenderIf condition={!StoreUser.isAuthenticated}>
                    <div className="banner__footer">
                        <Link to={'/sign-up'}>
                            <Button className="dark-pink banner-button" label="Sign up free" />
                        </Link>
                    </div>
                </RenderIf>
            </div>
        );
    }
}

export default withRouter(ComponentBanner);
