import React from 'react';
import classNames from 'classnames';
import './loader.scss';

export const Loader = (props) => {
    const { display, text, size = '91px', minHeight = 0, auxClass = '' } = props;
    return (
        <div style={{ minHeight }} className={classNames(`loader ${auxClass}`, { 'hide': !display })}>
            <div className="loader__content">
                <Spinner size={size} />
                {text && <p className="text">{text}</p>}
            </div>
        </div>
    );
};

export const Spinner = (props) => {
    const { size = '91px' } = props;
    return (
        <img
            width={size}
            className="spinner-img"
            src={require('../../../images/klickly-spinner.svg')}
            alt="spinner"
        />
    );
};

export default Loader;
