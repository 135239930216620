import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ComponentHeader from '../../components/marketplace/Header';
import QuickToBanner from '../../components/marketplace/QuickToBanner';
import QuicktoHelper from '../../helpers/quickto';
import QuickToLanding from './landing';

const bannerConfig = {
    'freegift': {
        title: 'Free gifts every time you shop!',
        hasExtraClass: true
    },
    'freeoffer': {
        title: 'Get gifts for shopping!',
        hasExtraClass: true
    },
    'giftoffer': {
        title: 'Get gifts for shopping!',
        hasExtraClass: false
    }
};

const QuickTo = (props) => {
    const path = props.match.path.replace('/', '');
    const { title, hasExtraClass } = bannerConfig[path];

    return (
        <Fragment>
            <ComponentHeader displayFavoritesBlock={false} displaySearchBlock={false}/>
            <QuickToBanner title={title} hasExtraClass={hasExtraClass} hasSubtitle={true}/>
        </Fragment>
    );
};

export const PageQuickTo = withRouter(QuickTo);

@inject('StoreUser')
export class PageQuickToMainPage extends Component {

    constructor(props) {
        super(props);
        QuicktoHelper.initTheQuickToParams();
    }

    render() {
        return (
            <Fragment>
                <div className="market-place">
                    <QuickToLanding/>
                </div>
            </Fragment>
        );
    }
}
