import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import ComponentButton from '../../ui/Button';
import imageLoader from '../../../helpers/imageLoader';
import { sessionStore } from '../../../helpers/browserStores';
import mainConstants from '../../../constants/main';

import './modalTutorial.scss';

const images = {
    desktop: require('./images/tutorial-images-sprite-big.png'),
    mobile: require('./images/tutorial-images-sprite-small.png')
};

const modalTutorialSessionKey = 'SHOULD_HIDE_TUTORIAL_MODAL';

@observer
class ComponentModalTutorial extends Component {

    async componentDidMount () {
        this.backgroundImage = await imageLoader(images.desktop);
        if (!sessionStore.get(modalTutorialSessionKey)) {
            this.showModal = true;
        }
    }

    backgroundImage = null;
    @observable showModal = false;
    @observable stepData = this.stepWelcome();

    @action changeStep(stepId) {
        if (this[stepId]) {
            this.stepData = this[stepId]();
        }
    }

    @action closeModal() {
        this.showModal = false;
        sessionStore.set(modalTutorialSessionKey, true);
    }

    stepWelcome() {
        return {
            className: 'step-welcome',
            component: <StepWelcome />,
            nextStep: 'stepFavorite',
            label: 'Show me how!'
        };
    }

    stepFavorite() {
        return {
            className: 'step-favorite',
            component: <StepFavorite />,
            nextStep: 'stepConfirm',
            label: 'Ok, and then ... ?'
        };
    }

    stepConfirm() {
        return {
            className: 'step-confirm',
            component: <StepConfirm />,
            nextStep: 'closeModal',
            label: 'Cool, let’s do it!'
        };
    }

    render() {
        if (!this.showModal) {
            return null;
        }

        return (
            <div className="tutorial-modal__wrapper overlay overlay-y">
                <div className="tutorial-modal">
                    <div className="tutorial-modal__top-line" />
                    <img onClick={() => this.closeModal()} className="tutorial-modal__close" src={mainConstants.CLOSE_ICON} alt="close"/>
                    <header className="tutorial-modal__header">
                        <img className="tutorial-modal__header__logo" src={mainConstants.KLICKLY_LOGO} alt="Klickly logo"/>
                        <span className="tutorial-modal__header__title">Brands</span>
                        <div className="tutorial-modal__header__line" />
                    </header>
                    <Step stepData={this.stepData} onClick={() => this.changeStep(this.stepData.nextStep)}>
                        <div key="modal-tutorial-image" className="tutorial-modal__block-img">
                            <div className="tutorial-modal__block-img__img" style={{ backgroundImage: `url(${this.backgroundImage})` }} />
                        </div>
                    </Step>
                </div>
            </div>
        );
    }
}

const Step = ({ stepData, onClick, children }) => {
    return (
        <main className={`tutorial-modal__main ${stepData.className}`}>

            { stepData.component }
            { children }

            <div className="tutorial-modal__block-btn">
                <ComponentButton
                    onClick={onClick}
                    className="common-button dark-pink"
                    label={stepData.label} />
            </div>
        </main>
    );
};

const StepWelcome = () => {
    return (
        <div>
            <h4 className="tutorial-modal__title">
                Welcome to Klickly!
            </h4>
            <h6 className="tutorial-modal__sub-title">
                Find cool products from hot, new brands.
            </h6>
            <h6 className="tutorial-modal__sub-title">
                Earn <span className="text-bold">20% off</span> everything!
            </h6>
        </div>
    );
};

const StepFavorite = () => {
    return (
        <div>
            <h6 className="tutorial-modal__sub-title">
                If you find a product you like, favorite it!
            </h6>
        </div>
    );
};

const StepConfirm = () => {
    return (
        <div>
            <h6 className="tutorial-modal__sub-title">
                Add 5+ products to Favorites – unlock <span className="text-bold">20% off</span> everything!
            </h6>
        </div>
    );
};

export default ComponentModalTutorial;
