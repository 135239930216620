import React, { Component } from 'react';

import Switch from '../../reusable/SwitchCheckbox';
import CircleProgress from '../../reusable/CircleProgress';
import './index.scss';
import {getProjectData} from "../../../projectMap";

class QuickCoin extends Component {

    render() {
        return (
            <div className="quick-coin">
                <div className="quick-coin-heading">
                    <h4 className="quick-coin-heading__title">
                        <img src={getProjectData('coins-active')} alt="quick-to-icon" className="quick-coin-heading__icon"/>
                        <span className="quick-coin-heading__text">{getProjectData('coin-label')} Coin</span>
                    </h4>
                    <div className="quick-coin-heading__checkbox">
                        <Switch/>
                        <span className="quick-coin__checkbox-label">Gift mode</span>
                    </div>
                </div>
                <div className="quick-coin__content">
                    <div className="quick-coin__progress">
                        <CircleProgress/>
                    </div>
                    <h3 className="quick-coin__title">You’ve got coin!</h3>
                    <ul className="quick-coin-list">
                        Use this coin to score yourself a free gift!
                        <li className="quick-coin-list__item">- Use the switch above to activate gift-mode</li>
                        <li className="quick-coin-list__item">- Choose any product that’s available</li>
                        <li className="quick-coin-list__item">- Use points just like cash</li>
                        <li className="quick-coin-list__item">- Checkout the same way you always would!</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default QuickCoin;
