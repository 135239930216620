
import PropTypes from 'prop-types';

const RenderIf = ({ condition, children }) => {
    return condition ? children : null;
};

RenderIf.propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired
};

export default RenderIf;
