export const differencesData = [
    {
        icon: require('../../../images/quickTo/landing/quickto-deals.svg'),
        text: 'Exclusive deals with brands'
    },
    {
        icon: require('../../../images/quickTo/landing/quickto-purchase.svg'),
        text: 'Points from every purchase'
    },
    {
        icon: require('../../../images/quickTo/landing/quickto-cash.svg'),
        text: 'Points work just like cash'
    }
];

export const bestsellersData = [
    {
        image: require('../../../images/quickTo/landing/makeup-essentials.jpg'),
        name: 'Everyday Makeup Essentials',
        price: '$38.98'
    },
    {
        image: require('../../../images/quickTo/landing/slide-sandal.jpg'),
        name: 'Seychelles Stanza Slide Sandal',
        price: '$162.50'
    },
    {
        image: require('../../../images/quickTo/landing/sunglasses.jpg'),
        name: 'TOMS Harlan Sunglasses',
        price: '$154.75'
    },
    {
        image: require('../../../images/quickTo/landing/womans-apparel.jpg'),
        name: 'Fitness Women\'s Apparel',
        price: '$50.50'
    }
];

export const brandsImages = [
    require('../../../images/quickTo/bearded-bastard.png'),
    require('../../../images/quickTo/briogeo-hair.png'),
    require('../../../images/quickTo/flag-anthem.png'),
    require('../../../images/quickTo/moon-juice.png'),
    require('../../../images/quickTo/my-intent.png'),
    require('../../../images/quickTo/nature-lab.png'),
    require('../../../images/quickTo/spongelle.png'),
    require('../../../images/quickTo/sullen.png')
];

export const footerLinks = [ 'About Us', 'Contact Us', 'Locations', 'My Account', 'Orders History', 'Advanced Search',
    'Privacy Policy', 'Help', 'Return Policy'
];

export const widgetLinks = {
    'development': 'https://widget.klickly-dev.com',
    'rc': 'https://widget.klickly-rc.com',
    'production': 'https://widget.klickly.com'
};
