
import { keys } from '../config/stripe';
import loadScript from './LoadScript';

class UtilStripe {

    stripeInstance = null;
    stripeCard;

    styles (fontSize) {
        return {
            base: {
                color: '#4a4a4a',
                fontFamily: 'proxima_nova_semibold, Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontWeight: 'normal',
                fontSize,
                '::placeholder': {
                    fontWeight: 'normal',
                    color: '#bbbbc2',
                    fontFamily: 'proxima_nova_semibold, Helvetica, sans-serif',
                    fontSize
                }
            },
            invalid: {
                color: '#f64316',
                iconColor: '#fa755a'
            }
        };
    }

    __getBlobURL = (code, type) => {
        const blob = new Blob([ code ], { type });
        return URL.createObjectURL(blob);
    };

    async init (purchaseOn, onCardChangeForm, fontSize) {
        if (purchaseOn === 'widget-buy') {
            return this.__safeFrameWrapper(onCardChangeForm, fontSize);
        }

        if (!this.stripeInstance) {
            await loadScript('https://js.stripe.com/v3/');
            this.stripeInstance = window.Stripe(keys.public); // eslint-disable-line
        }

        if (!this.stripeCard) {
            const elements = this.stripeInstance.elements();
            this.stripeCard = elements.create('card', { style: this.styles(fontSize), hidePostalCode: true });
        }

        this.stripeCard.mount('#stripe-iframe-wrapper');
        this.stripeCard.on('change', (obj) => this.__onChange(onCardChangeForm, obj));
    }

    __safeFrameWrapper(onCardChangeForm, fontSize) {
        const source = `
            <html lang="en">
              <head>
                <title>Klickly Stripe SafeFrame wrapper</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <script src="https://js.stripe.com/v3/"></script>
                <script>
                    function dispatchEvent(detail = null) {
                        var event = new CustomEvent('stripeEvent', { detail });
                        window.parent.document.dispatchEvent(event);
                    }
                    
                    document.addEventListener('DOMContentLoaded', () => {
                        var stripeInstance = window.Stripe('${keys.public}');
                        var elements = stripeInstance.elements();
                        var stripeCard = elements.create('card', { style: ${JSON.stringify(this.styles(fontSize))}, hidePostalCode: true });
                        stripeCard.mount('#card-element');
                        
                        stripeCard.on('change', ({ error, complete }) => {
                            dispatchEvent();
                            
                            if (error) {
                                dispatchEvent({ error: error.message });
                            } else if (complete) {
                                dispatchEvent({ loading: true });
                                
                                stripeInstance.createToken(stripeCard)
                                    .then(({ token, error }) => {
                                        if (error) {
                                            dispatchEvent({ loading: false, error: error.message });
                                        } else {
                                            dispatchEvent({ loading: false, token: token });
                                        }                                        
                                    })
                                    .catch((error) => {
                                        dispatchEvent({ loading: false, error: error.message });
                                    });
                            } 
                        });
                        
                        
                    });
                </script>
              </head>
              <body>
                <div id="card-element" class='form-control__input'/>
              </body>
            </html>
          `;

        const url = this.__getBlobURL(source, 'text/html');

        const iframe = document.createElement('iframe');
        iframe.setAttribute('id', 'stripe-iframe');
        iframe.setAttribute('src', url);
        document.getElementById('stripe-iframe-wrapper').appendChild(iframe);

        window.document.addEventListener('stripeEvent', (e) => onCardChangeForm(e.detail));
    }

    async __onChange(onCardChangeForm, { error, complete }) {
        onCardChangeForm();

        if (error) {
            onCardChangeForm({ error: error.message });
        } else if (complete) {
            onCardChangeForm({ loading: true });
            const { token, error } = await this.stripeInstance.createToken(this.stripeCard);
            if (error) {
                onCardChangeForm({ loading: false, error: error.message });
            } else {
                onCardChangeForm({ loading: false, token: token });
            }
        }
    }

    destroy(onChangeFunction) {
        this.stripeCard.off('change', onChangeFunction);
        this.stripeCard.unmount();
    }
}

const instance = new UtilStripe();
export default instance;
