import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, computed, toJS } from 'mobx';
import * as get from 'lodash/get';
import * as upperFirst from 'lodash/upperFirst';
import Select from 'react-select';
import * as moment from 'moment';
import classnames from 'classnames';

import { roundToLocaleString } from '../../../helpers/static';
import OrderService from "../../../services/orders";
import { Spinner } from "../../../components/ui/Loader";

import './orderHistory.scss';

const noOrderImg = require('../../../images/package-empty.svg');
const defaultFilterOption = {
    value: '',
    label: 'All Time'
};

@inject('StoreNotifications')
@inject('StoreApp')
@observer
export class PageOrderHistory extends Component {

    @observable isLoading = true;
    @observable orders = [];
    @observable dateFilterOptions = [];
    @observable selectedDateFilter = '';

    @action changeLoadingStatus(status) {
        this.isLoading = status;
    }

    @computed get filterParams() {
        if (!this.selectedDateFilter) {
            return '';
        }

        return [
            `month=${moment(this.selectedDateFilter).month()}`,
            `year=${moment(this.selectedDateFilter).year()}`
        ];
    }

    @action onDateFilterChange = (item) => {
        this.selectedDateFilter = item.value;

        this.loadOrders();
    };

    @action async loadOrders() {
        this.isLoading = true;

        try {
            if(this.dateFilterOptions.length === 0) {
                const { orders, dates } = await OrderService.list([ 'dates=true' ]);
                this.dateFilterOptions = [ defaultFilterOption, ...dates ];
                this.orders = orders;
            } else {
                const { orders } = await OrderService.list(this.filterParams);
                this.orders = orders;
            }
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }

        this.isLoading = false;
    }

    componentDidMount() {
        this.loadOrders();
    }

    render() {

        return (
            <div className="order-history">
                <div className="order-history-top">
                    <div className="order-history__title">Orders</div>
                    <div className="order-history__filter">
                        <Select defaultValue={ defaultFilterOption }
                            clearable={ false }
                            searchable={ false }
                            placeholder="Filter by date"
                            options={ toJS(this.dateFilterOptions) }
                            onChange={ this.onDateFilterChange }
                            classNamePrefix="order-history-select"
                        />
                        <div className="order-history__filter-icon"/>
                    </div>
                </div>

                <div className="order-table">
                    <div className="order-table__header">
                        <div className="order-table__row">
                            <div className="order-table__column column-date">Date</div>
                            <div className="order-table__column column-product">Product</div>
                            <div className="order-table__column column-type">Payment</div>
                            {
                                this.props.StoreApp.creditsCoinEnabled &&
                                <div className="order-table__column column-type">Type</div>
                            }
                            <div className="order-table__column column-status">Status</div>
                            <div className="order-table__column column-amount">Amount</div>
                        </div>
                    </div>
                    <div className="order-table__body">
                        {
                            this.orders.length ?
                                this.orders.map((order) => {
                                    const amounts = [];
                                    if (this.props.StoreApp.creditsCoinEnabled && order.creditsAmount && order.creditsAmount !== 0) {
                                        amounts.push(order.creditsAmount);
                                    }
                                    if (order.price !== 0) {
                                        amounts.push(`$${roundToLocaleString(order.price)}`);
                                    }

                                    return (
                                        <div key={order._id} className="order-table__row">
                                            <div className="order-table__column column-date">
                                                {moment(order.createdAt).format('MMM DD, YYYY')}
                                            </div>
                                            <div className="order-table__column column-product">
                                                {get(order, 'promotionTitle', '-')}
                                            </div>
                                            <div className="order-table__column column-type">
                                                {get(order, 'cardInfo.cardType', '-')}
                                                    &nbsp;
                                                {get(order, 'cardInfo.last4', '-')}
                                            </div>
                                            {
                                                this.props.StoreApp.creditsCoinEnabled &&
                                                    <div className="order-table__column column-type">
                                                        {upperFirst(order.mode)}
                                                    </div>
                                            }
                                            <div className="order-table__column column-status">
                                                {order.status}
                                            </div>
                                            <div className={classnames("order-table__column column-amount", {'column-coins': order.mode ==='gift'})}>
                                                {amounts.join(' - ')}
                                            </div>
                                        </div>
                                    );
                                }
                                ) :
                                this.isLoading ? <Spinner style={{ margin: '0 auto'}} /> :
                                    <div className="order-table__row no-orders">
                                        <img alt="No orders found" className="no-orders__img" src={noOrderImg} />
                                        <div className="no-orders__title">No orders found</div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

