import { observable, action } from 'mobx';
import { localStore } from '../../helpers/browserStores';

class StoreTutorial {
    @observable isPassing = false;
    @observable isOpen = false;
    @observable stepToGo = 0;
    @observable isModalShown = false;

    @action syncStep(step) {
        if (this.stepToGo !== step) {
            this.setStepToGo(step);
        }
    }

    @action hideTutorial = () => {
        this.isOpen = false;
    }

    @action showTutorial = () => {
        this.isOpen = true;
    }

    @action showModal = () => {
        this.isModalShown = true;
    }

    @action hideModal = () => {
        this.isModalShown = false;
    }

    @action close = () => {
        localStore.set('tutorialPassed', true);
        document.body.style.overflowY = 'auto';
        this.hideTutorial();
    }

    @action
    setStepToGo = (step) => {
        this.stepToGo = step;
    }

    @action
    goToNextStep = () => {
        this.stepToGo +=1;
    }
}

export default StoreTutorial;
