
import axios from 'axios';
import * as qs from 'qs';

import Request from '../utils/Request';
import ProductHelper from '../models/product';

class ProductsLoader {
    nextPage = 1;
    category = '';
    searchQuery = '';
    isCanLoad = true;

    axiosCancelToken = axios.CancelToken;
    axiosLastSource = null;

    async submit(forceRequest = false, category = -1, searchQuery = -1, nextPage = null, perPage = 24) {
        if (category !== -1) {
            this.category = category;
        }
        if (searchQuery !== -1) {
            this.searchQuery = searchQuery;
        }
        if (nextPage !== null) {
            this.nextPage = nextPage;
        }

        if (forceRequest && !this.isCanLoad) {
            this.axiosLastSource.cancel('Switch to another category');
            this.isCanLoad = true;
        }

        if (this.nextPage && this.isCanLoad) {
            // Start load state
            this.isCanLoad = false;
            this.axiosLastSource = this.axiosCancelToken.source();

            try {
                const routePath = this.searchQuery ? 'promotions/search' : 'promotions';
                const fullUrl = `/${routePath}/?${this.__buildSearchQuery(this.category, this.searchQuery, this.nextPage, perPage)}`;

                const {
                    pagination,
                    promotions
                } = await Request.get(fullUrl, null, this.axiosLastSource.token);

                this.nextPage = pagination.next;
                this.isCanLoad = true;
                return promotions;
            } catch (error) {
                if (axios.isCancel(error)) {
                    return { isCancel: true };
                }

                this.isCanLoad = true;
                return Promise.reject(error);
            }
        }
    }

    __buildSearchQuery(category, searchQuery, nextPage, perPage) {
        const parsedSearchQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        parsedSearchQuery.page = nextPage;
        parsedSearchQuery.tabName = category;

        if (searchQuery && searchQuery !== '') {
            parsedSearchQuery.q = searchQuery;
            delete parsedSearchQuery.tabName;
        }

        if (!parsedSearchQuery.perPage) {
            parsedSearchQuery.perPage = perPage;
        }


        return qs.stringify(parsedSearchQuery);
    }

    parseProducts(products) {
        return products.map(product => this.parseProduct(product));
    }

    parseProduct(product) {
        return new ProductHelper({
            _id: product.id || product._id,
            id: product.id || product._id,
            account: product.account,
            campaign: product.campaign,
            startDate: Date.parse(product.createdAt),
            title: product.title,
            brandName: product.brandName,
            images: product.images,
            description: product.description,
            options: product.options,
            variants: product.variants,
            shopDomain: product.shopDomain,
            externalId: product.externalId,
            category: product.category,
            creditsMultiplier: product.creditsMultiplier
        });
    }
}

const instance = new ProductsLoader();
export default instance;
