export const quickToBuy = require('./quickto-buy.svg');
export const quickToCollect = require('./quickto-collect.svg');
export const quickToChoose = require('./quickto-choose.svg');
export const quickToLogo = require('./quickto-logo.svg');
export const quickToCircleLogo = require('./quickto-logo-circle.svg');
export const quickToCircleLogoInactive = require('./quickto-logo-circle--inactive.svg');
export const quickToCart = require('./quickto-cart.svg');
export const quickToCartActive = require('./quickto-cart--active.svg');
export const quickToCartThin = require('./quickto-cart-thin.svg');
export const sliderArrowLeft = require('./arrow-left.svg');
export const sliderArrowRight = require('./arrow-right.svg');
export const circleInfo = require('./circled-info.svg');
export const brandLogos = [
    require('./bearded-bastard.png'),
    require('./briogeo-hair.png'),
    require('./flag-anthem.png'),
    require('./moon-juice.png'),
    require('./my-intent.png'),
    require('./nature-lab.png'),
    require('./spongelle.png'),
    require('./sullen.png'),
    require('./tenzo-tea.png'),
    require('./willie-nelson.png')
];
