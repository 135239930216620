
const Fingerprint2 = require('fingerprintjs2');

function FingerprintScript() {
    this.fingerPrint = null;
}

FingerprintScript.prototype.__getBrowserFingerPrint = function() {
    return new Promise((resolve) => {
        if (this.fingerPrint) {
            return resolve(this.fingerPrint);
        }
        setTimeout(() => {
            Fingerprint2.getPromise({
                excludes: {
                    adBlock: true,
                    fontsFlash: true,
                    enumerateDevices: true
                }
            }).then((components) => {
                const fingerprintComponents = components.reduce((result, component) => {
                    if (component.value === 'not available') {
                        result[component.key] = 'unknown';
                        return result;
                    }

                    if (component.key === 'plugins') {
                        let value = [];

                        if (component.value.length) {
                            value = component.value.map((p) => {
                                const mimeTypes = p[2] && p[2].length ? p[2].map((mt) => {
                                    if (mt.join) {
                                        return mt.join('~');
                                    }
                                    return mt;
                                }).join(',') : '';
                                return [ p[0], p[1], mimeTypes ].join('::');
                            });
                        }
                        result['plugins'] = value.length ? Fingerprint2.x64hash128(value.join('~')) : '';
                        result['pluginsClear'] = value;
                        return result;
                    }

                    if ([ 'canvas', 'webgl', 'fonts' ].indexOf(component.key) !== -1) {
                        result[component.key] = Fingerprint2.x64hash128(component.value.join('~'), 31);
                        return result;
                    }

                    if ([ 'webglVendorAndRenderer' ].indexOf(component.key) !== -1) {
                        result[component.key] = Fingerprint2.x64hash128(component.value, 31);
                        return result;
                    }

                    if ([ 'sessionStorage', 'localStorage', 'indexedDb', 'addBehavior', 'openDatabase' ].indexOf(component.key) !== -1) {
                        if (component.value) {
                            result[component.key] = 1;
                            return result;
                        }
                    }

                    if (component.value) {
                        result[component.key] = component.value.join ? component.value.join(';') : component.value;
                        return result;
                    }

                    result[component.key] = component.value;
                    return result;
                }, {});

                this.fingerPrint = fingerprintComponents;

                resolve(fingerprintComponents);
            });
        }, 500);
    });
};

FingerprintScript.prototype.getKlicklyEventData = function(promotions, trackingData) {
    if (!promotions.length) {
        return;
    }

    return this.__getBrowserFingerPrint().then((fingerprintComponents) => {
        trackingData.components = fingerprintComponents;
        trackingData.eventType = 'in-site';
        trackingData.referrer = window.document.referrer || window.location.href;

        trackingData.promotions = promotions.map((promotion) => {
            return {
                accountId: promotion.account,
                promotionId: promotion._id,
                promotionTitle: promotion.title,
                promotionExternalId: promotion.externalId,
                campaignId: promotion.campaign,
                shopDomain: promotion.shopDomain,
                mouseoverLength: promotion.mouseoverLength
            };
        });

        return trackingData;
    });
};

FingerprintScript.prototype.getShopifyEventData = function(shopDomain, eventName, externalId, checkout, cart, additionalData) {
    const eventNameMapper = {
        externalClicks: 'click',
        externalViews: 'view',
        externalCartAdds: 'add-to-cart'
    };

    const eventAction = checkout ? 'purchase' : eventNameMapper[eventName];

    return this.__getBrowserFingerPrint().then((fingerprintComponents) => {

        const data = {
            components: fingerprintComponents,
            eventSource: 'shopify',
            eventAction,
            eventSubAction: null,
            eventType: 'off-site',
            additionalData,
            referrer: window.location.href,
            promotions: []
        };

        if (checkout) {
            data.checkout = checkout;
        }

        if (cart) {
            data.cart = cart;
        }

        if (externalId) {
            data.promotions.push({
                promotionExternalId: externalId,
                shopDomain: shopDomain
            });
        } else {
            data.promotions.push({
                shopDomain: shopDomain
            });
        }

        return data;
    });
};

const instance = new FingerprintScript();
module.exports = instance;
