import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Slider from 'react-slick';

import { prepareImageUrl } from '../../../helpers/static';
import RenderIf from '../../../helpers/renderIf';
import Loader from "../../ui/Loader";
import './index.scss';

@observer
class SliderImage extends Component {

    @observable notLoaded = true;

    render() {
        const { image, isMobile, activeImageSrc } = this.props;
        return (
            <Fragment>
                <img onLoad={() => {
                    this.notLoaded = false;
                }} alt=" " className={classNames('product-carousel__item-img', {'is-active': activeImageSrc === image.src})}
                src={`${prepareImageUrl(image.src, isMobile ? '_280x$1' : '_70x$1')}`}
                />
                <Loader display={this.notLoaded} size="30px"/>
            </Fragment>
        );
    }

}

@observer
class SliderBox extends Component {
    render() {
        const { images, activeImageSrc, isImageViewInMobile, isMobile, selectImage } = this.props;

        const slides = images.map((image, key) =>
            <div key={`product-carousel-item-${key}`} className="product-carousel__item" onClick={() => selectImage(image.src)}>
                <SliderImage activeImageSrc={activeImageSrc} isMobile={isMobile} image={image} />
            </div>
        );

        const slidesCount = images.length >= 7 ? 7 : images.length;
        const sliderProps = {
            arrows: false,
            speed: 500,
            slidesToShow: slidesCount,
            slidesToScroll: slidesCount,
            infinite: slidesCount !== 1,
            autoplay: false,
            autoplaySpeed: 2000,
            className: 'slider',
            dots: isImageViewInMobile,
            vertical: !isMobile && !isImageViewInMobile,
            rows: 1
        };

        const mobileSliderProps = {
            ...sliderProps,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: null,
            prevArrow: null,
            dots: true
        };

        return (
            <Fragment>
                {/* Mobile view */}
                <RenderIf condition={isMobile && !isImageViewInMobile}>
                    <div className="image-view">
                        <div className="product-carousel">
                            <Slider {...mobileSliderProps}>
                                { slides }
                            </Slider>
                        </div>
                    </div>
                </RenderIf>

                {/* Image mobile window view */}
                <RenderIf condition={isMobile && isImageViewInMobile}>
                    <div className="product-carousel">
                        <Slider {...sliderProps}>
                            { slides }
                        </Slider>
                    </div>
                </RenderIf>

                {/* Desktop view */}
                {
                    !isMobile && !isImageViewInMobile &&
                    <div className="product-carousel--desktop">{slides.splice(0, slidesCount)}</div>
                }
            </Fragment>
        );
    }
}

export default SliderBox;
