import { action, observable } from 'mobx';
import ProductService from '../../services/product';
import productsLoader from '../../helpers/productsLoader';
import { sanitizeProductName } from '../../helpers/static';

class StoreCheckout {
    @observable list = [];

    hasProduct = (id) => {
        return this.list.findIndex((product) => product.get('id') === id) !== -1;
    };

    isEmpty = () => {
        return this.count() === 0;
    };

    count = () => {
        return this.list.length;
    };

    getFirstProductInCartURI = () => {
        const [ product ] = this.list;
        return `/${sanitizeProductName(product.get('title'))}/${product.get('id')}`;
    };

    @action add = (product) => {
        this.list.unshift(product);
    };

    @action remove = (id) => {
        const index = this.list.findIndex((product) => product.get('id') === id);

        if (index !== -1) {
            this.list.splice(index, 1);
        }
    };

    @action clear = () => {
        this.list = [];
    };

    @action addProductAsync = async (id) => {
        const product = await ProductService.getProductById(id);
        this.add(productsLoader.parseProduct(product));
    };
}


export default StoreCheckout;
