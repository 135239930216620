import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({ label, defaultClassName, className, ...rest }) => {
    return (
        <button className={classNames(defaultClassName, className)} {...rest}>
            { label }
        </button>
    );
};

Button.propTypes = {
    label: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]).isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    defaultClassName: PropTypes.string
};

Button.defaultProps = {
    type: 'button',
    disabled: false,
    defaultClassName: 'common-button',
    onClick: () => null
};

export default Button;
