import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import ProductService from '../../services/product';
import { Loader } from '../../components/ui/Loader';

import './index.scss';
import {getProjectData} from "../../projectMap";

@inject('StoreNotifications')
@observer
export class OfferLanding extends Component {
    @observable productImage = null;
    @action async setProductImage(productId) {
        try {
            const product = await ProductService.getProductById(productId);
            this.productImage = product.images[0].src;
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }
    }

    componentDidMount() {
        const { match: { params: { productId } } } = this.props;
        this.setProductImage(productId);
    }

    render() {
        return(
            <div className="offer-landing">
                <h1 className="offer-landing__title">
                    Welcome to
                    <img className="offer-landing__title-image"
                        src={getProjectData('logo')}
                        alt=""/>
                </h1>
                <div className="offer-landing__content">
                    <div className="offer-landing__image-container">
                        <Loader display={!this.productImage}/>
                        <img className="offer-landing__image"
                            src={this.productImage}
                            alt=""/>
                    </div>
                    <div className="offer-landing__subtitle-container">
                        <span className="offer-landing__subtitle">
                            Quickto is a site that pays you to window-shop and shop online!
                        </span>
                    </div>
                    <div className="offer-landing__list-container">
                        <ul className="offer-landing-list">
                            <li className="offer-landing-list__item">
                                Browse or purchase
                            </li>
                            <li className="offer-landing-list__item">
                                Earn points you can use like cash
                            </li>
                            <li className="offer-landing-list__item">
                                Use points towards your next purhcase!
                            </li>
                        </ul>
                    </div>
                    <div className="offer-landing__subtitle-container--second">
                        <span className="offer-landing__subtitle">
                            Get your free gift by choosing what you want then signing up!
                        </span>
                    </div>
                    <div className="offer-landing__action-wrapper">
                        <Link to="/under10?tutorial&coins=1" className="offer-landing__action">
                            Get started
                        </Link>
                        <Link to="/" className="offer-landing__action offer-landing__action--secondary">
                            Learn more
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
