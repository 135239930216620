
import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

const INFO_ICON = require('../../../images/circled-info.svg');

@inject('StoreApp')
@inject('StoreDiscount')
@inject('StoreUser')
class DiscountTooltip extends Component {

    render() {
        const {
            StoreDiscount,
            StoreUser,
            StoreApp,
            authText = 'You',
            notAuthText = 'Members',
            discountLabelText = 'save:'
        } = this.props;

        if (StoreDiscount.discount.rate && StoreDiscount.discount.type) {
            return (
                <Fragment>
                    {StoreUser.isAuthenticated ? authText : notAuthText} {discountLabelText}
                    <Fragment>
                        <ReactTooltip
                            id="favorites-discount-tooltip"
                            effect="float"
                            place={StoreApp.isMobile || StoreApp.isMobileLandscape ? 'right' : 'bottom'}
                            html
                        />
                        <img
                            src={INFO_ICON}
                            className="icon info-icon"
                            alt="Information about discount"
                            data-for="favorites-discount-tooltip"
                            data-tip={StoreDiscount.discountText}
                        />
                    </Fragment>
                </Fragment>
            );
        }

        return this.props.children ? this.props.children : null;
    }
}

export default DiscountTooltip;
