import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import HowItWorks from "../../components/marketplace/HowItWorks";

@inject('StoreApp')
export class PageAbout extends Component {

    componentDidMount() {
        document.body.classList.add('open-modal');
    }

    componentWillUnmount() {
        document.body.classList.remove('open-modal');
    }

    render() {
        return (
            <HowItWorks onClick={() => {
                this.props.history.push(this.props.StoreApp.lastMarketplaceURL);
            }} />
        );
    }
}

export default withRouter(HowItWorks);
