import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { prepareImageUrl, roundToLocaleString, calculateCreditsAndDollarAmount, loadURL } from '../../../helpers/static';
import RenderIf from '../../../helpers/renderIf';
import track from '../../../helpers/track';

import './productCard.scss';
import {getProjectData} from "../../../projectMap";

const images = {
    favoritesEmpty: require('../../../images/heart-empty-new.svg'),
    favoritesActive: require('../../../images/heart-active-new.svg')
};

@inject('StoreAsidePanel')
@inject('StoreApp')
@inject('StoreFavorites')
@inject('StoreProducts')
@observer
export class ProductCard extends Component {

    async toggleFavoriteProduct(event) {
        event.stopPropagation();
        event.preventDefault();

        const { product, StoreFavorites, StoreApp, StoreAsidePanel } = this.props;
        await StoreFavorites.toggleFavoriteProduct(product, StoreApp.isMobile, StoreAsidePanel.showFavorites, StoreAsidePanel.hideFavorites);
        await this.trackProductClicks();
    }

    trackProductClicks() {
        // load click exclusion pixel
        const { StoreApp } = this.props;

        if (!StoreApp.isClickExclude) {
            loadURL('https://cdn.klickly.com/pixels/click-exclude.js');
            StoreApp.isClickExclude = true;
        }

        const eventData = [ this.props.product.object ];
        return track.sendEvent('clicked', eventData, this.props.typeOfUnit || '');
    }

    handleOnClick = () => {
        this.trackProductClicks();
        this.props.onClick(this.props.product);
    };

    render() {
        const { product, StoreFavorites, enableAddToFavorites = true, similarProduct = false } = this.props;

        return (
            <section className="relative">
                <div
                    className="product-card-link"
                    onClick={this.handleOnClick}
                >
                    <div className="product-card">
                        <div className="product-card__body">
                            <img className="product-card__img"
                                alt={`${product.get('title')} product`}
                                src={`${prepareImageUrl(product.get('image'), '_250x$1')}`}
                            />
                            {
                                enableAddToFavorites &&
                                <div onClick={(event) => this.toggleFavoriteProduct(event)}
                                    className="product-card__favorites">
                                    <img
                                        key={product.get('id')}
                                        alt="Add product to favorites button icon"
                                        className="product-card__favorites__img"
                                        src={StoreFavorites.isFavoriteProduct(product.get('id')) ? images.favoritesActive : images.favoritesEmpty }
                                    />
                                </div>
                            }
                        </div>

                        {
                            this.props.StoreApp.creditsBuyMode &&
                            <div className="product-card-coins">
                                <span className="product-card-coins__text">earn</span>
                                <img src={getProjectData('coins-active')} alt="" className="product-card-coins__icon"/>
                                <span className="product-card-coins__count">{ product.get('creditsEarned') }</span>
                            </div>
                        }


                        <ProductMainInfo
                            title={product.get('title')}
                            brandName={product.get('brandName')}
                            price={product.price}
                            originalPrice={product.originalPrice}
                            multiplier={product.get('creditsMultiplier')}
                            similarProduct={similarProduct}
                        />
                    </div>
                </div>
            </section>
        );
    }

}

@inject('StoreUser')
@inject('StoreApp')
export class ProductMainInfo extends Component {
    render() {
        const { title, brandName, price, originalPrice, multiplier } = this.props;

        let displayOriginalPrice = Number(originalPrice) > 0;
        let productPrice = null;

        if (this.props.StoreApp.creditsGiftMode) {
            const giftPrices = calculateCreditsAndDollarAmount(price, this.props.StoreUser.userCredits, multiplier);
            productPrice =
            <Fragment>
                <img className="product-main-info__price-icon" src={getProjectData('coins-active')} alt="" /> {giftPrices.credits}
                { giftPrices.dollarAmount ? ` + $${roundToLocaleString(giftPrices.dollarAmount)}` : null }
            </Fragment>;

            displayOriginalPrice = false;
        } else {
            productPrice = `$${roundToLocaleString(price)}`;
        }
        return (
            <div className="product-main-info">
                <div className="product-main-info__left">
                    <div className="product-main-info__title">{title}</div>
                    <div className="product-main-info__brand">{brandName}</div>
                </div>
                <div className="product-main-info__right">
                    <RenderIf condition={Number(price) > 0}>
                        <div className="product-main-info__price-current">
                            {productPrice}
                        </div>
                    </RenderIf>
                    {
                        displayOriginalPrice &&
                        <div className="product-main-info__price-old">
                            ${roundToLocaleString(originalPrice)}
                        </div>
                    }
                </div>
            </div>
        );
    }
}
