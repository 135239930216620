import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import QuicktoHelper from '../../helpers/quickto';
import { localStore } from "../../helpers/browserStores";
import SignInSteps from '../../components/marketplace/SignIn/_steps';
import ComponentFacebook from '../../components/static/Facebook';
import { Loader } from '../../components/ui/Loader';
import {getProjectData} from "../../projectMap";

@inject('StoreTutorial')
@inject('StoreApp')
@observer
class PageSignInClass extends Component {
    @observable loader = false;
    @action toggleLoader = (status) => {
        this.loader = status;
    };

    componentDidMount() {
        document.body.classList.add('open-modal');
    }

    componentWillUnmount() {
        document.body.classList.remove('open-modal');
    }

    closeSignInModal = () => {
        this.props.StoreApp.changeStep('signin');
        this.props.history.push(this.props.StoreApp.lastMarketplaceURL);
    };

    render() {
        const isCloseButtonDisabled = QuicktoHelper.paramsToRedirect.tutorial
            && !localStore.get('tutorialPassed')
            && this.props.StoreTutorial.stepToGo === 3;
        return (
            <div className="overlay overlay-y overlay-auth">
                <div className="auth sign-in">
                    <span onClick={this.closeSignInModal} className={classnames('auth__close', {'disabled': isCloseButtonDisabled})}/>
                    <div className="auth-logo">
                        <img className="auth-logo__img" src={getProjectData('logo')} alt=""/>
                    </div>
                    <h2 className="form__title">The world's best offers and rewards!</h2>
                    <div className="auth__form">
                        <Fragment>
                            <div className="form-row">
                                <ComponentFacebook
                                    text="Connect with Facebook"
                                    onSuccess={this.closeSignInModal}
                                    toggleLoader={this.toggleLoader}
                                />
                            </div>
                            <div className="auth-devidor">
                                <span className="auth-devidor__line auth-devidor__line_left"/>
                                <span className="auth-devidor__text">or</span>
                                <span className="auth-devidor__line auth-devidor__line_right"/>
                            </div>
                        </Fragment>

                        <SignInSteps
                            toggleLoader={this.toggleLoader}
                            onSuccess={this.closeSignInModal}
                            onCreateAccountClick={() => this.props.history.push('/sign-up')}
                        />

                        <Loader display={this.loader} />
                    </div>
                    {
                        !this.props.StoreApp.creditsCoinEnabled &&
                            <Fragment>
                                <a href="http://brands.klickly.com" className="auth-footer">
                                    Continue as a business
                                </a>

                                <div className="circle-box">
                                    <div className="circle-box__wrapper">
                                        <h3 className="circle-box__title">Find. Buy. Earn.</h3>
                                        <p className="circle-box__text">Earn rewards by shopping the world's best-voted ads...</p>
                                        <button
                                            onClick={() => this.props.history.push('/about')}
                                            className="circle-box__link">
                                            How it works
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                    }

                </div>
            </div>
        );
    }
}

export const PageSignIn = withRouter(PageSignInClass);
