
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observable } from "mobx";
import { observer, inject } from 'mobx-react';
import Masonry from 'react-masonry-component';

import { Spinner } from '../../ui/Loader';
import { ProductCard } from '../ProductCard';
import productsLoader from '../../../helpers/productsLoader';
import { sanitizeProductName } from "../../../helpers/static";
import QuickToTutorial from '../QuickToTutorial';
import RightAsidePanel from '../AsidePanel';

@inject('StoreAsidePanel')
@inject('StoreApp')
@inject('StoreFavorites')
@inject('StoreProducts')
@inject('StoreCategoryFilter')
@observer
class ComponentProductsList extends Component {
    infinityContainerRef = null;
    contentWrapperRef = null;
    favoritesComponentWidth = null;

    isCanScrollMore() {
        const offsetTop = this.props.offsetTop ? this.props.offsetTop : 0;
        if (this.infinityContainerRef) {
            return this.infinityContainerRef.getBoundingClientRect().bottom < window.innerHeight + offsetTop;
        }
        return false;
    }

    loadNextProductsPage() {
        const { StoreProducts } = this.props;
        const fewProductsOnThePage = StoreProducts.products.length < StoreProducts.productsPerPage;

        if (fewProductsOnThePage || !productsLoader.nextPage) {
            return false;
        }

        StoreProducts.loadProducts();
    }

    getWidthForProductsContainer() {
        const { StoreApp, StoreAsidePanel: { display } } = this.props;
        const contentWrapperWidth = this.contentWrapperRef && this.contentWrapperRef.getBoundingClientRect().width;
        if (display.favorites) {
            return !StoreApp.isMobile ? contentWrapperWidth - this.favoritesComponentWidth : contentWrapperWidth;
        } else if (display.coins || display.cart) {
            return contentWrapperWidth - this.favoritesComponentWidth;
        }
        return contentWrapperWidth;
    }

    windowScrollHandlerProductList = () => {
        if (this.isCanScrollMore()) {
            this.loadNextProductsPage();
        }
    };

    @observable productsLoadedInDom = false;
    @observable productsLayoutCompleted = false;

    handleMasonryLayoutComplete = (items) => {
        if (items.length) {
            setTimeout(() => {
                this.productsLayoutCompleted = true;
            }, 1000);
        }
    }

    handleMasonryItemsLoaded = (items) => {
        if(items.images.length &&
            this.props.StoreCategoryFilter.isCategoryExist(this.props.match.params.categoryName)
        ) {
            this.productsLoadedInDom = true;
        }
    }

    @observable widthForProductsContainer = 0;
    windowResizeHandlerProductList = () => {
        this.widthForProductsContainer = this.getWidthForProductsContainer();
    };

    setWidthForFavoritesComponent = (width) => {
        this.favoritesComponentWidth = width;
    };

    componentDidMount() {
        document.addEventListener('scroll', this.windowScrollHandlerProductList);
        window.addEventListener('resize', this.windowResizeHandlerProductList);
        this.windowResizeHandlerProductList();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.windowScrollHandlerProductList);
        window.removeEventListener('resize', this.windowResizeHandlerProductList);
    }

    componentDidUpdate() {
        this.widthForProductsContainer = this.getWidthForProductsContainer();
    }

    render() {
        const { StoreProducts, StoreAsidePanel, isFavoritesFixed } = this.props;
        const { products = [], displayProductsLoader } = StoreProducts;
        const isRightAsideShown = Object.keys(StoreAsidePanel.display).some(item => StoreAsidePanel.display[item] === true);

        return (
            <div className="market-place-content">
                { this.productsLoadedInDom && this.productsLayoutCompleted &&
                  <QuickToTutorial />
                }
                <div ref={(ref) => {
                    this.contentWrapperRef = ref;
                }}
                className="market-place-content-wrapper">
                    <div className="market-place-content-products" style={{ width: `${this.widthForProductsContainer}px` }}>
                        <div ref={(ref) => {
                            this.infinityContainerRef = ref;
                        }}>
                            <Masonry
                                className="masonry"
                                options={{ fitWidth: true }}
                                style={{ width: `${this.widthForProductsContainer}px` }}
                                onImagesLoaded={this.handleMasonryItemsLoaded}
                                onLayoutComplete={this.handleMasonryLayoutComplete}
                            >
                                {
                                    products.map((item, key) =>
                                        <ProductCard
                                            key={`category-product-masonry-${key}`}
                                            product={item}
                                            onClick={(product) => this.props.history.push(`/${sanitizeProductName(product.get('title'))}/${product.get('id')}`)}
                                        />
                                    )
                                }
                            </Masonry>
                            <div className="market-place-content-bottom">
                                { displayProductsLoader && <Spinner /> }
                            </div>
                        </div>
                        {
                            !products.length && !displayProductsLoader &&
                            <div className="market-place-content-products__noresults">
                                Sorry, nothing found
                            </div>
                        }
                    </div>
                    {
                        isRightAsideShown &&
                        <RightAsidePanel setComponentWidth={this.setWidthForFavoritesComponent} isFixed={isFavoritesFixed}/>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ComponentProductsList);
