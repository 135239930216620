import React from 'react';

import './howitworks.scss';

const images = {
    heartIcon: require('../../../images/about/heart.svg'),
    tagIcon: require('../../../images/about/tag.svg'),
    thumbsIcon: require('../../../images/about/thumbs-up.svg')
};

const HowItWorks = (props) => {
    const { onClick } = props;
    return (
        <div className="how-works--wrapper">
            <div className="how-works">
                <div className="line-rectangle line-rectangle__top" />
                <div
                    className="close"
                    onClick={onClick}
                />
                <div className="how-works--top">
                    <h4 className="how-works__title">Earn rewards by shopping the world's best-voted ads...</h4>
                    <button
                        onClick={onClick}
                        className="how-works__button">
                        Get started!
                    </button>
                </div>
                <div className="how-works--gallery">
                    <div className="how-works--gallery__img how-works--gallery__lockmart" />
                    <div className="how-works--gallery__img how-works--gallery__shorts" />
                    <div className="how-works--gallery__img how-works--gallery__spirits" />
                    <div className="how-works--gallery__img how-works--gallery__box" />
                    <div className="how-works--gallery__img how-works--gallery__glasses" />
                    <div className="how-works--gallery__img how-works--gallery__paper" />
                    <div className="how-works--gallery__img how-works--gallery__picture" />
                    <div className="how-works--gallery__img how-works--gallery__bag" />
                    <div className="how-works--gallery__img how-works--gallery__notebook" />
                </div>
                <div className="how-works--info-box">
                    <div className="how-works--info-box__col">
                        <div className="how-works--info-box__top">
                            <img src={images.heartIcon} className="how-works--info-box__icon" alt="..."/>
                        </div>
                        <h5 className="how-works--info-box__name">Find</h5>
                        <p className="how-works--info-box__text">
                            Favorite what you love. Get suggestions on more things.
                        </p>
                    </div>
                    <div className="how-works--info-box__col">
                        <div className="how-works--info-box__top">
                            <img src={images.tagIcon} className="how-works--info-box__icon" alt="..."/>
                        </div>
                        <h5 className="how-works--info-box__name">Buy</h5>
                        <p className="how-works--info-box__text">
                            Buy easily directly in ads. Get the things you can’t live without.
                        </p>
                    </div>
                    <div className="how-works--info-box__col">
                        <div className="how-works--info-box__top">
                            <img src={images.thumbsIcon} className="how-works--info-box__icon" alt="..."/>
                        </div>
                        <h5 className="how-works--info-box__name">Earn</h5>
                        <p className="how-works--info-box__text">
                            Get $$ towards each purchase! The more you buy, the more you earn.
                        </p>
                    </div>
                </div>
                <div className="line-rectangle line-rectangle__bottom" />
            </div>
        </div>
    );
};

export default HowItWorks;
