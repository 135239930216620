
import React, { Component, Fragment } from 'react';
import { observer, inject } from "mobx-react";
import classnames from 'classnames';
import SignInSteps from '../../../../components/marketplace/SignIn/_steps';
import FormBinder from "../../../../helpers/formBinder";
import CustomerService from "../../../../services/customer";
import { EMAIL } from '../../../../constants/formFields';
import Input from '../../../../components/ui/Input';
import Button from '../../../../components/ui/Button';
import SignUpStep from "../../../../components/marketplace/SignUp";
import ComponentFacebook from "../../../../components/static/Facebook";

import './index.scss';
import {getProjectData} from "../../../../projectMap";

@inject('StoreNotifications')
@inject('StorePurchase')
@inject('StoreUser')
@inject('StoreApp')
@observer
class CustomerInfo extends Component {
    form = null;
    componentRef = null;

    constructor(props) {
        super(props);

        this.form = new FormBinder([
            { ...EMAIL, label: '' }
        ], {
            onSuccess: this.onSuccess.bind(this)
        });
    }

    async onSuccess(form) {
        this.props.StorePurchase.toggleLoader(true);

        try {
            const { email } = form.values();

            const { exist } = await CustomerService.checkCustomerEmail(email.toLocaleLowerCase());

            if (!exist) {
                this.props.StorePurchase.setValue('profile.email', email);
                this.props.StorePurchase.changeStep('shipping', true);
            } else {
                this.props.StoreNotifications.addError('User already exists');
            }
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }

        this.props.StorePurchase.toggleLoader(false);
    }

    onSignInSuccess(profile) {
        this.props.StorePurchase.setProfile(profile);
        this.props.StorePurchase.setQty(this.props.StorePurchase.store.product.maxQty(this.props.StorePurchase.store.checkout.qty));
        this.props.StorePurchase.setCreditsData(this.props.StoreUser.userCredits);
        this.props.StorePurchase.changeStep('shipping', true);
    }

    __guestForm() {
        const { form } = this;

        return (
            <div className="mp-sign-in__part">
                <form onSubmit={form.onSubmit} className="mp-sign-in__form">
                    <div className="mp-sign-in__form-content">
                        <p className="mp-sign-in__title">I’m new here</p>
                        <p className="mp-sign-in__subtitle">Check out as a guest</p>
                        <p className="mp-sign-in__subtitle">(you can create an account later)</p>
                        <Input
                            {...form.$('email').bind() }
                            error={form.$('email').error}
                            errorMessage={EMAIL.errorMessage}
                        />
                    </div>
                    <Button
                        className="dark-pink"
                        label="Continue"
                        onClick={form.onSubmit}
                        disabled={form.isPristine || !form.isValid}
                    />
                </form>
            </div>
        );
    }

    __leftForm(hideGuestForm) {
        if (hideGuestForm) {
            return null;
        }

        if (!this.props.StoreApp.creditsCoinEnabled) {
            return this.__guestForm();
        }

        return (
            <div className="mp-sign-in__part">
                <p className="mp-sign-in__title">I’m new here</p>
                <p className="mp-sign-in__subtitle">Create an account to save your coins!</p>
                <SignUpStep inPurchaseModal={true} onSuccess={(profile) => this.onSignInSuccess(profile)} toggleLoader={(status) => this.props.StorePurchase.toggleLoader(status)} />
                <span className="mp-sign-in__bottom-label">You need an account to save your coins!</span>
            </div>
        );
    }

    onBackButtonClick = () => {
        this.props.StoreApp.changeStep('signin');
        this.props.StorePurchase.changeStep('productInfo');
    };

    componentDidMount() {
        if (this.props.StorePurchase.purchaseOn === 'widget-buy' && this.props.StoreApp.isMobile) {
            const elementToScroll = this.componentRef.closest('.overlay');
            elementToScroll && elementToScroll.scrollTo(0, 0);
        }
    }

    render() {
        const { purchaseOn } = this.props.StorePurchase;
        const { signInFormStep } = this.props.StoreApp;
        const hideGuestForm = purchaseOn === 'widget-buy' && signInFormStep === 'forgot';

        return (
            <div className="mp-sign-in" ref={(ref) => {
                this.componentRef = ref;
            }}>
                <span className="mp-modal__back" onClick={this.onBackButtonClick} />
                <div className="mp-sign-in__logo-wrapper">
                    <img className="mp-sign-in__logo"
                        src={getProjectData('logo')}
                        alt="klickly logo"
                    />
                </div>

                <main className="mp-sign-in__box">
                    { this.__leftForm(hideGuestForm) }

                    <div className={classnames('mp-sign-in__part', {'single': hideGuestForm})}>
                        <div className="mp-sign-in__form">
                            <SignInSteps
                                hideSignUpLink={true}
                                toggleLoader={(status) => this.props.StorePurchase.toggleLoader(status)}
                                onSuccess={(profile) => this.onSignInSuccess(profile)}
                            >
                                <div className="mp-sign-in__form-content">
                                    <p className="mp-sign-in__title">Sign in</p>
                                    <p className="mp-sign-in__subtitle">Already have an account?</p>
                                    <p className="mp-sign-in__subtitle">Access your deals here</p>
                                    {!this.props.StoreApp.creditsCoinEnabled &&
                                        <Fragment>
                                            <div className="mp-sign-in__fb">
                                                <ComponentFacebook
                                                    text="Sign in with Facebook"
                                                    toggleLoader={(status) => this.props.StorePurchase.toggleLoader(status)}
                                                    onSuccess={(profile) => this.onSignInSuccess(profile)}
                                                />
                                            </div>
                                            <span className="mp-sign-in__form-divider">or</span>
                                        </Fragment>
                                    }
                                </div>
                            </SignInSteps>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default CustomerInfo;
