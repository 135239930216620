import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Input from '../../ui/Input';
import Button from '../../ui/Button';
import ComponentFacebook from '../../static/Facebook';

import QuicktoHelper from '../../../helpers/quickto';
import customerFlow from '../../../helpers/customerFlow';
import FormBinder from '../../../helpers/formBinder';
import AuthService from '../../../services/auth';
import { GTM } from '../../../utils/GTM';

import { EMAIL, FIRST_NAME, LAST_NAME, PASSWORD } from '../../../constants/formFields';

@inject('StoreUser')
@inject('StoreApp')
@inject('StoreFavorites')
@inject('StoreNotifications')
@inject('StoreCheckout')
@inject('StoreAsidePanel')
@inject('StoreTutorial')
@observer
export class SignUpStep extends Component {
    form = null;

    constructor(props) {
        super(props);

        this.form = new FormBinder([
            { ...FIRST_NAME, label: '' },
            { ...LAST_NAME, label: '' },
            { ...EMAIL, label: '' },
            { ...PASSWORD, label: '' }
        ], {
            onSuccess: this.onSuccess.bind(this)
        });

        document.body.classList.add('open-modal');
    }

    componentWillUnmount() {
        const { StoreUser } = this.props;

        if (customerFlow.isFlowEnabled && !StoreUser.isAuthenticated) {
            customerFlow.disableFlow();
        }
    }

    async onSuccess(form) {
        try {
            this.props.toggleLoader(true);
            const values = form.values();

            if (customerFlow.isFreeOfferFlow) {
                QuicktoHelper.enableParam('coins');
            }

            const profile = await AuthService.register({ defaultCoinsEnabled: QuicktoHelper.paramsToRedirect.coins, ...values });

            GTM.CompleteRegistrationEvent.send({
                content_name: profile.email
            });

            this.props.StoreUser.setProfile(profile);
            this.props.StoreFavorites.syncFavoritesProductsWithServer(true);
            this.props.toggleLoader(false);

            if (this.props.onSuccess) {
                await this.props.onSuccess(profile);
            }

            await this.checkOffer();
        } catch (error) {
            this.props.StoreNotifications.addError(error);
            this.props.toggleLoader(false);
        }
    }

    onFacebookSignUpSuccess = async () => {
        const { onSuccess } = this.props;

        if (onSuccess) {
            await onSuccess();
        }

        await this.checkOffer();
    };

    checkOffer = async () => {
        const { history, StoreCheckout, StoreAsidePanel, StoreTutorial } = this.props;

        if (!customerFlow.isFlowEnabled) {
            return;
        }

        await StoreCheckout.addProductAsync(customerFlow.params.productID);

        if (!StoreCheckout.isEmpty()) {
            StoreAsidePanel.showElement('cart');

            if (customerFlow.isFreeOfferFlow) {
                return setTimeout(() => {
                    StoreTutorial.showTutorial();
                }, 500);
            }

            return history.push(StoreCheckout.getFirstProductInCartURI());
        }
    };

    render() {
        const { form } = this;
        const { inPurchaseModal, facebookButtonText = 'Connect with Facebook' } = this.props;

        return (
            <div className="auth__form">
                <Fragment>
                    <div className="form-row">
                        <ComponentFacebook
                            text={facebookButtonText}
                            onSuccess={this.onFacebookSignUpSuccess}
                            toggleLoader={this.props.toggleLoader} />
                    </div>
                    <div className="auth-devidor">
                        <span className="auth-devidor__line auth-devidor__line_left"/>
                        <span className={ inPurchaseModal ? 'mp-sign-in__form-divider' : 'auth-devidor__text'}>or</span>
                        <span className="auth-devidor__line auth-devidor__line_right"/>
                    </div>
                </Fragment>

                <div className="sign-up-form">
                    <form className="form" onSubmit={form.onSubmit}>
                        <div className="two-inputs-row">
                            <Input error={form.$('firstName').error} {...form.$('firstName').bind() } />
                            <Input error={form.$('lastName').error} {...form.$('lastName').bind() } />
                        </div>
                        <Input error={form.$('email').error} {...form.$('email').bind() } />
                        <Input error={form.$('password').error} {...form.$('password').bind() } />
                        <Button type="submit"
                            onClick={form.onSubmit}
                            label="Continue"
                            className="dark-pink"
                            disabled={form.isPristine || !form.isValid}/>
                    </form>
                    { this.props.children }
                </div>
            </div>
        );
    }
}

export default withRouter(SignUpStep);
