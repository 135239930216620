import { action, observable, computed, extendObservable } from 'mobx';
import * as find from 'lodash/find';
import * as findLastIndex from 'lodash/findLastIndex';
import * as orderBy from 'lodash/orderBy';
import * as sortBy from 'lodash/sortBy';
import * as filter from 'lodash/filter';

// Constants
import FILTER_CATEGORIES_ITEMS from './constans';

class StoreCategoryFilter {
    @observable filterBar;
    @observable widthScrollContent = '0';
    @observable showMoreFilter = false;
    @observable showMoreButtonVisible = true;
    @observable items = FILTER_CATEGORIES_ITEMS;
    @observable selectedCategory = null;

    @action init(isMobileView) {
        if (isMobileView) {
            this.items = sortBy(this.items, [ (item) => {
                if (item.order !== null) {
                    return `${item.order}${item.text}`;
                }
                return `999${item.text}`;

            } ]);
        }
        this.items.forEach((i, key) => {
            if (i.order !== null) {
                i.order = key;
            } else {
                extendObservable(i, { order: key });
            }
        });
    }

    @action toggleShowMoreFilter() {
        this.showMoreFilter = !this.showMoreFilter;
    }

    @action setWidthScrollContent(widthFilterBar) {
        this.widthScrollContent = widthFilterBar + 'px';
    }

    @action setInitialWidthOfItems(ref) {
        ref && ref.childNodes.forEach((child) => {
            const [ , refChildKey = null ] = child.id.split('---');
            if (refChildKey) {
                const item = find(this.items, {key: refChildKey});
                item.width = child.clientWidth;
            }
        });
    }

    @action calculateFilterBarWidthAndVisibleItems(ref, refMoreButton) {
        const availableWidth = ref && ref.clientWidth || 0;
        let accumulativeItemsWidth = refMoreButton && refMoreButton.clientWidth || 0;
        if (this.selectedCategory) {
            accumulativeItemsWidth += this.selectedCategory.width;
        }
        const availableItems = this.availableItems;
        availableItems.forEach((item) => {
            if (this.selectedCategory && this.selectedCategory.key === item.key) {
                item.visible = true;
            } else {
                item.visible = (accumulativeItemsWidth + item.width) < availableWidth;
                accumulativeItemsWidth += item.width;
            }
        });

        this.showMoreButtonVisible = this.anyHiddenCategory;
    }

    @computed get anyHiddenCategory() {
        return Boolean(find(this.items, { visible: false }));
    }

    @computed get availableItems() {
        return orderBy(this.items, [ 'order' ], [ 'asc' ]);
    }

    @computed get visibleItems() {
        return orderBy(filter(this.items, { visible: true }), [ 'order' ], [ 'asc' ]);
    }

    @computed get notVisibleItems() {
        return orderBy(filter(this.items, { visible: false }), [ 'text' ], [ 'asc' ]);
    }

    @computed get searchCategory() {
        return this.selectedCategory && this.selectedCategory.key ? this.selectedCategory.key : null;
    }

    @action isCategoryExist(itemKey) {
        return this.items.find(({key}) => key === itemKey);
    }

    @action changeFilterCategory(itemKey) {
        const foundCategory = this.isCategoryExist(itemKey);

        if (foundCategory) {
            this.selectedCategory = foundCategory;
            return foundCategory;
        }

        this.selectedCategory = this.items[0];
        return this.items[0];
    }

    @action clearFilterCategory() {
        this.selectedCategory = null;
    }

    @action changeFilterWithHiddenCategory(item) {
        const availableItems = this.availableItems;
        const lastVisibleItemIndex = findLastIndex(availableItems, { visible: true });
        this.changeOrderInItemsList(item, availableItems, lastVisibleItemIndex);
    }

    @action changeOrderInItemsList(item, availableItems, index) {
        item.order = availableItems[index].order;
        availableItems[index].visible = false;
        item.visible = true;

        for (let i = index; i < availableItems.length; i += 1) {
            if (availableItems[i].key !== item.key) {
                availableItems[i].order += 1;
            }
        }
    }

    @action resize(filterBarRef, moreButtonRef) {
        this.calculateFilterBarWidthAndVisibleItems(filterBarRef, moreButtonRef);
    }
}

export default StoreCategoryFilter;
