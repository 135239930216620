import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Input from '../../ui/Input';
import Button from '../../ui/Button';

import { EMAIL, PASSWORD } from '../../../constants/formFields';

import FormBinder from '../../../helpers/formBinder';
import AuthService from '../../../services/auth';

@inject('StoreApp')
@inject('StoreUser')
@inject('StorePurchase')
@inject('StoreFavorites')
@inject('StoreNotifications')
@observer
class SignInForm extends Component {
    form = null;

    constructor(props) {
        super(props);

        this.form = new FormBinder([
            { ...EMAIL, label: '' },
            { ...PASSWORD, label: '' }
        ], {
            onSuccess: this.onSuccess.bind(this)
        });
    }

    async onSuccess(form) {
        this.props.toggleLoader(true);

        try {
            const { email, password } = form.values();

            await AuthService.login({
                email: email.toLocaleLowerCase(),
                password
            });

            const profile = await AuthService.checkAuth();

            this.props.StoreUser.setProfile(profile);
            await this.props.StoreFavorites.syncFavoritesProductsWithServer(true);

            this.props.toggleLoader(false);

            if (this.props.onSuccess) {
                this.props.onSuccess(profile);
            }
        } catch (error) {
            this.props.StoreNotifications.addError(error);
            this.props.toggleLoader(false);
        }
    }

    render() {
        const { form } = this;

        return (
            <div className="sign-in">
                <form className="sign-in-form" onSubmit={form.onSubmit}>
                    <Input
                        {...form.$('email').bind()}
                        error={form.$('email').error}
                        errorMessage={EMAIL.errorMessage}
                    />
                    <Input
                        {...form.$('password').bind()}
                        error={form.$('password').error}
                    />
                    <Button
                        type="submit"
                        label="Log in"
                        className="dark-pink"
                        disabled={form.isPristine || !form.isValid}
                    />
                </form>

                {!this.props.StoreApp.creditsCoinEnabled &&
                    <div className="forgot-link" onClick={() => this.props.changeStep('forgot')}>Forgot password?</div>
                }
            </div>
        );
    }
}

export default SignInForm;
