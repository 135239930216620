import React, { Component } from 'react';
import { observable, action } from "mobx";
import { inject, observer } from 'mobx-react';
import ReactImageMagnify from 'react-image-magnify';

import SliderBox from '../../static/SliderBox';
import Loader from '../../ui/Loader';

import { prepareImageUrl } from '../../../helpers/static';

import './imageCarousel.scss';

const MAX_ZOOMED_IMAGE_SIZE = '_1200x$1';

@inject('StoreApp')
@inject('StorePurchase')
@observer
class ImageCarousel extends Component {

    @observable imageState = {
        image: null,
        isImageLoaded: true,
        width: 0,
        height: 0
    };

    @action changeState(field, value) {
        this.imageState[field] = value;
    }

    async componentDidMount() {
        this.imageState.isImageLoaded = true;
        this.imageState.image = this.props.product.get('image');

        await this.setImageSizeToState();
    }

    setActiveImage = (imageSrc) => {
        this.imageState.isImageLoaded = true;
        this.imageState.image = imageSrc;

        if(this.props.StoreApp.isMobile || this.props.StoreApp.isMobileLandscape) {
            this.props.StorePurchase.openImageViewInMobile();
        }

        return this.setImageSizeToState();
    };

    async setImageSizeToState() {
        const { width, height } = await this.getImageSize(prepareImageUrl(this.imageState.image, MAX_ZOOMED_IMAGE_SIZE));
        this.imageState.width = width;
        this.imageState.height = height;
    }

    getImageSize(imageSrc) {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => resolve({
                height: image.naturalHeight,
                width: image.naturalWidth
            });
        });
    }

    render() {
        const {image, width, height, isImageLoaded} = this.imageState;

        if (!image) {
            return null;
        }

        const {product, StoreApp: {isMobile, isMobileLandscape}, StorePurchase: { isImageViewInMobile }} = this.props;
        const smallImageSize = isImageViewInMobile ? '_480x$1' : '_734x$1';
        const isMobileView = isMobile || isMobileLandscape;

        const reactImageMagnifyProps = {
            smallImage: {
                alt: 'Product img',
                isFluidWidth: true,
                src: prepareImageUrl(image, smallImageSize),
                onLoad: () => this.changeState('isImageLoaded', false)
            },
            largeImage: {
                src: prepareImageUrl(image, MAX_ZOOMED_IMAGE_SIZE),
                width,
                height
            },
            imageClassName: 'carousel__slide-img',
            enlargedImageContainerClassName: `${isImageViewInMobile ? 'mobile' : 'desktop'}-zoomed-image-wrapper`,
            enlargedImageContainerDimensions: {
                width: width / 2,
                height: height / 2
            },
            isActivatedOnTouch: true,
            enlargedImagePosition: isMobileView ? 'over' : 'beside',
            isHintEnabled: isImageViewInMobile,
            hintTextTouch: 'Click and hold to zoom'
        };

        const images = product.get('images', []);

        return (
            <div className="carousel">
                {
                    images.length
                        ? <SliderBox
                            images={images}
                            selectImage={this.setActiveImage}
                            activeImageSrc={image}
                            isImageViewInMobile={isImageViewInMobile}
                            isMobile={isMobileView}
                        />
                        : <div className="slider-box" />
                }

                {
                    !isMobileView || isImageViewInMobile ?
                        <div className="carousel__slide">
                            <Loader
                                size="50px"
                                display={isImageLoaded}
                            />

                            <ReactImageMagnify
                                {...reactImageMagnifyProps}
                            />
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default ImageCarousel;
