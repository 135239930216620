import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { action, computed, observable } from "mobx";

import * as get from 'lodash/get';
import * as find from 'lodash/find';
import * as findIndex from 'lodash/findIndex';
import * as filter from 'lodash/filter';
import * as each from 'lodash/each';
import * as reduce from 'lodash/reduce';
import * as isMatch from 'lodash/isMatch';

import ReactTooltip from 'react-tooltip';
import ScrollArea from 'react-scrollbar';
import classNames from "classnames";

import Button from '../../../../../components/ui/Button';
import DiscountTooltip from '../../../../../components/reusable/DiscountTooltip';
import RenderIf from '../../../../../helpers/renderIf';
import { roundToLocaleString, calculateCreditsAndDollarAmount } from '../../../../../helpers/static';
import TrackHelper from '../../../../../helpers/track';
import QuicktoHelper from '../../../../../helpers/quickto';
import { localStore } from '../../../../../helpers/browserStores';
import customerFlow from '../../../../../helpers/customerFlow';

import QtyCounter from '../ProductQty';
import ProductOptionValues from '../ProductOptionValues';
import { getProjectData } from '../../../../../projectMap';
import './index.scss';

@inject('StoreCheckout')
@inject('StoreTutorial')
@inject('StoreAsidePanel')
@inject('StoreFavorites')
@inject('StorePurchase')
@inject('StoreDiscount')
@inject('StoreUser')
@inject('StoreApp')
@observer
class ProductDetails extends Component {
    optionScrollAreaRef = null;
    tabs = [ 'buy', 'details' ];

    @observable productOptionValuesWidth = '100%';
    @observable errorMessage = null;
    @observable activeTab = 0;

    @action changeTab(tab) {
        this.activeTab = tab;
        this.errorMessage = null;
    }

    @action changeProductOptionValuesWidth(width) {
        this.productOptionValuesWidth = width;
    }

    @observable activeOptionPosition = 0;
    @action changeOptionPosition(position) {
        this.errorMessage = null;
        if (this.optionScrollAreaRef) {
            this.optionScrollAreaRef.scrollLeft(0);
        }
        this.activeOptionPosition = position;
    }

    getProductVariantPrices() {
        const { product, StorePurchase } = this.props;
        const qty = find(this.props.StorePurchase.store.options, { position: 0 });

        const offer = StorePurchase.store.product
            ? customerFlow.getStreamlinedSpecialOffer(StorePurchase.store.product.get('id'))
            : null;

        const prices = {
            price: product.price * qty.selected,
            originalPrice: (product.originalPrice || 0) * qty.selected,
            creditsEarned: product.get('creditsEarned') * qty.selected
        };

        if (this.props.StoreApp.creditsGiftMode) {
            prices.gift = calculateCreditsAndDollarAmount(prices.price, this.props.StoreUser.userCredits, product.get('creditsMultiplier'));
        }

        if (this.selectedVariant) {
            prices.price = this.selectedVariant.price * qty.selected;
            prices.originalPrice = (this.selectedVariant.originalPrice || 0) * qty.selected;
            prices.creditsEarned = (this.selectedVariant.creditsEarned || 0) * qty.selected;

            if (this.props.StoreApp.creditsGiftMode) {
                prices.gift = calculateCreditsAndDollarAmount(prices.price, this.props.StoreUser.userCredits, product.get('creditsMultiplier'));
            }
        }

        if (this.props.StoreApp.creditsGiftMode) {
            prices.dollarPrice = prices.price / product.get('creditsMultiplier');
        } else {
            prices.dollarPrice = prices.price;
        }

        if (offer && offer.strictQty) {
            prices.price = offer.subtotal;
            prices.originalPrice = (product.originalPrice || 0) * offer.qty;
            prices.creditsEarned = product.get('creditsEarned') * offer.qty;
        }

        return prices;
    }

    @action changeQuantity = (quantity) => {
        this.errorMessage = null;
        const { StorePurchase: { isShipFreeProduct, isGiveAwayProduct, isFreeProduct, store } } = this.props;
        const qty = find(this.props.StorePurchase.store.options, { position: 0 });

        if (quantity) {
            qty.selected = Number(quantity) || 1;

            if (qty.selected < 1 || (isGiveAwayProduct || isFreeProduct || isShipFreeProduct)) {
                qty.selected = 1;
            }
        }

        if (store.product) {
            const offer = customerFlow.getStreamlinedSpecialOffer(store.product.get('id'));

            if (offer && offer.strictQty) {
                qty.selected = offer.qty;
            }
        }

        const maxQty = this.maxVariantQuantity();

        if (qty.selected > maxQty) {
            qty.selected = maxQty;
            this.errorMessage = 'Max available';
        }
    };

    @computed get selectedVariant() {
        if (this.__amountOptionsSelected() === this.props.StorePurchase.store.options.length - 1) {
            const selectedOptionsObject = reduce(this.props.StorePurchase.store.options, (res, option) => {
                if (option.position !== 0) {
                    res[`option${option.position}`] = option.selected;
                }
                return res;
            }, {});

            const index = findIndex(this.props.product.variants, (variant) => {
                return isMatch(variant.object, selectedOptionsObject);
            });

            return this.props.product.variants[index];
        }

        return null;
    }

    openExternalLink (event, shopDomain, promotion) {
        event.preventDefault();
        event.stopPropagation();

        const utmString = `?utm_source=Klickly&utm_medium=Marketing&utm_campaign=General&utm_term=${promotion.title}`;

        TrackHelper.sendEvent('click-through', [ promotion ], 'to-brand');
        window.open(`https://${shopDomain}${utmString}`);
    }

    maxVariantQuantity() {
        const { product } = this.props;

        let variantQuantity = 0;

        if (this.selectedVariant) {
            variantQuantity = this.selectedVariant.quantity;
        } else {
            variantQuantity = Math.max(...product.variants.map(({ quantity }) => quantity));
        }

        return variantQuantity;
    }

    __amountOptionsSelected() {
        return this.props.StorePurchase.store.options.reduce((res, option) => {
            const value = option.position !== 0 && option.selected !== null ? 1 : 0;
            return res + value;
        }, 0);
    }

    __checkOptionsForDisabling() {
        const { product: { variants } } = this.props;

        this.__enableAllOptions(this.props.StorePurchase.store.options);

        const amountSelected = this.__amountOptionsSelected();
        // need to remove qty
        const optionsAmount = this.props.StorePurchase.store.options.length - 1;

        let filteredVariants = variants;

        if (amountSelected !== 0) {
            filteredVariants = filter(variants, (variant) => {
                const amountOfMatches = reduce(this.props.StorePurchase.store.options, (res, option) => {
                    const value = option.position !== 0 &&
                    option.selected &&
                    variant.get(`option${option.position}`, null) === option.selected
                        ? 1 : 0;

                    return res + value;
                }, 0);

                return amountOfMatches >= amountSelected;
            });
        }

        const uniqOptionBuckets = reduce(filteredVariants, (res, variant) => {
            for(let i = 1; i <= optionsAmount; i++) {
                const key = `option${i}`;
                const value = this.__clearOptionValue(variant.get(key));

                if (!res[key]) {
                    res[key] = {};
                }

                res[key][value] = 1;
            }

            return res;
        }, {});

        each(this.props.StorePurchase.store.options, (option) => {
            if (amountSelected === 1 && option.selected !== null && optionsAmount !== 1) {
                return true;
            }

            each(option.values, (value) => {
                const optionKey = this.__clearOptionValue(value.value);
                value.disabled = !get(uniqOptionBuckets, `option${option.position}.${optionKey}`, false);

                if (value.disabled && option.selected === value.value) {
                    option.selected = null;
                }
            });
        });

        return true;
    }

    __clearOptionValue(value) {
        return value.replace(/([ .])/g, '');
    }

    __enableAllOptions(options) {
        each(options, (option) => {
            each(option.values, (value) => {
                value.disabled = false;
            });
        });
    }

    __getNextNotSelectedOption() {
        const option = find(this.props.StorePurchase.store.options, { selected: null });
        if (option) {
            return option.position;
        }

        return 0;
    }

    handleOnClickHeart = async(event) => {
        event.stopPropagation();
        event.preventDefault();

        const { product, StoreFavorites, StoreApp, StoreAsidePanel } = this.props;
        await StoreFavorites.toggleFavoriteProduct(product, StoreApp.isMobile, StoreAsidePanel.showFavorites, StoreAsidePanel.hideFavorites);

        this.changeQuantity();
    };

    handleCheckoutCreation = () => {
        this.changeTab('buy');

        const notSelectedPosition = this.handleOnClickValue(true);
        if (notSelectedPosition === 0) {
            // everything is good need to find variant and proceed
            const qty = find(this.props.StorePurchase.store.options, { position: 0 });
            this.props.onPayloadComplete({ selectedVariant: this.selectedVariant, qty: qty.selected });
        } else {
            const option = find(this.props.StorePurchase.store.options, { position: notSelectedPosition });
            this.errorMessage = `Oops! Please select ${option.name}`;
        }
        return true;
    };

    handleOnClickValue = (checked) => {
        this.__checkOptionsForDisabling();

        this.changeQuantity();

        if (checked) {
            const position = this.__getNextNotSelectedOption();
            this.changeOptionPosition(position);
            return position;
        }

        return 0;
    };

    handleAddToCartClick = () => {
        if(!this.props.StorePurchase.store.profile) {
            this.errorMessage = `Sing in first in order to add product to the cart.`;
            if(QuicktoHelper.paramsToRedirect.tutorial && !localStore.get('tutorialPassed')) {
                this.props.StorePurchase.handleCloseModal();
                this.props.StoreTutorial.goToNextStep();
            }
        } else {
            const { StoreCheckout, StoreAsidePanel, product } = this.props;
            const id = product.get('id');

            if (StoreCheckout.hasProduct(id)) {
                StoreCheckout.remove(id);

                if (StoreCheckout.isEmpty()) {
                    StoreAsidePanel.hideCart();
                }
                return;
            }

            StoreCheckout.add(product);
            StoreAsidePanel.showElement('cart');
        }
    };

    constructor (props) {
        super(props);

        this.activeOptionPosition = this.props.StorePurchase.store.options.length === 1 ? 0 : 1;

        if (customerFlow.shouldGoToQtyOption()) {
            this.handleOnClickValue(true);
        }

        if (this.props.StoreApp.creditsGiftMode) {
            this.tabs[0] = 'gift';
        }
    }

    componentDidMount () {
        this.handleOnClickValue(false);
    }

    // eslint-disable-next-line
    render() {
        const { product, StoreFavorites, StoreCheckout, StorePurchase } = this.props;
        const { isGiveAwayProduct, isFreeProduct, isStreamlineProduct, isShipFreeProduct } = StorePurchase;
        const inFavorites = StoreFavorites.isFavoriteProduct(product.id);
        const inCart = StoreCheckout.hasProduct(product.id);
        const prices = this.getProductVariantPrices();
        const { creditsGiftMode } = this.props.StoreApp;
        const isCustomerFlow = isGiveAwayProduct || isFreeProduct || isStreamlineProduct || isShipFreeProduct;
        const offer = customerFlow.getStreamlinedSpecialOffer(product.get('id'));

        let dealPrice = `$${roundToLocaleString(offer && offer.strictQty ? offer.deal : prices.price)}`;

        if (creditsGiftMode) {
            dealPrice = prices.gift.credits;

            if (prices.gift.dollarAmount !== 0) {
                dealPrice = `${dealPrice} + $${roundToLocaleString(prices.gift.dollarAmount)}`;
            }
        }

        const isShowOriginalPrice = isGiveAwayProduct || isFreeProduct || (Boolean(offer) && offer.strictQty) || isShipFreeProduct;

        const isShowOnlyDealPrice = offer && offer.deal === prices.price;

        return(
            <div className="info">
                { !this.props.StorePurchase.productInExpandableUnit && !isCustomerFlow &&
                    <div className={classNames('info-action--favorites', {'is-active': inFavorites})}
                        onClick={this.handleOnClickHeart}
                    />
                }
                <div className="wrapper-tooltip">
                    <ReactTooltip effect="float" place="top" html />
                </div>
                <h3 className="info__title" data-tip={ product.get('title') }>
                    { product.get('title') }
                </h3>
                <span onClick={(e) => this.openExternalLink(e, product.get('shopDomain'), product.object)}
                    className="info__brand"
                    data-tip={ product.get('brandName') }>
                    { product.get('brandName') }
                </span>
                <ul className="info__tab-list">
                    {
                        this.tabs.map((label, key) =>
                            <li key={`info-details-tab-${label}`}
                                onClick={() => this.changeTab(key)}
                                className={classNames('info__tab', {'is-active': this.activeTab === key})}>
                                { label }
                            </li>
                        )
                    }
                </ul>
                { this.activeTab === 1 ?
                    <div className="info-details">
                        <ScrollArea
                            speed={1}
                            className="scrollarea-vertical"
                            contentClassName="vertical-content"
                            horizontal={false}
                            vertical={true}
                        >
                            <p className="info-details__text"
                                dangerouslySetInnerHTML={{__html: product.get('description')}} />
                        </ScrollArea>
                    </div>
                    :
                    <div className="info-buy">
                        {!isShowOnlyDealPrice && isShowOriginalPrice &&
                            <InfoRow label='Original price' value={`$${roundToLocaleString(prices.originalPrice || prices.price)}`} classPrefix="list-price"/>
                        }

                        {!offer && prices.originalPrice > 0 &&
                            <InfoRow label="List price" value={`$${roundToLocaleString(prices.originalPrice)}`} classPrefix="list-price"/>}

                        <InfoRow label={creditsGiftMode ? 'Gift price' : isGiveAwayProduct || isFreeProduct || isShipFreeProduct ? 'New member price' : 'Deal price'} iconSrc={getProjectData('coins-active')} value={isGiveAwayProduct || isFreeProduct || isShipFreeProduct ? '$0.00' : dealPrice} classPrefix={creditsGiftMode ? 'gift' : 'deal'}/>

                        <RenderIf condition={prices.originalPrice > prices.dollarPrice && this.props.StoreDiscount.isDiscountAvailable}>
                            <InfoRow label="Save"
                                hasToolTip={true}
                                classPrefix="save"
                                value={`$${roundToLocaleString(prices.originalPrice - prices.dollarPrice)} (${roundToLocaleString((prices.originalPrice - prices.dollarPrice) * 100 / prices.originalPrice, 0)}%)`}/>
                        </RenderIf>
                        <div>
                            <ul className="info-buy__option-list">
                                {
                                    product.options.map((option, key) => {
                                        const isAlertActive = option.position === this.activeOptionPosition;

                                        const dynamicClassNames = {
                                            'is-active': isAlertActive,
                                            'is-active--blink': isAlertActive && product.options.length > 1
                                        };

                                        return (
                                            <li key={key}
                                                onClick={() => this.changeOptionPosition(option.position) }
                                                className={classNames('info-buy__option', dynamicClassNames)}
                                            >
                                                {option.name}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                            <div className="info-buy__option-values" >
                                <ScrollArea ref={(ref) => {
                                    this.optionScrollAreaRef = ref;
                                }}
                                contentStyle={{ width: this.productOptionValuesWidth }}
                                speed={1}
                                contentClassName="content"
                                horizontal={true}
                                >
                                    {
                                        this.activeOptionPosition === 0 ?
                                            <QtyCounter
                                                changeProductOptionValuesWidth={(width) => this.changeProductOptionValuesWidth(width)}
                                                changeQuantity={this.changeQuantity}
                                                option={find(this.props.StorePurchase.store.options, { position: 0 })}
                                            />
                                            :
                                            <ProductOptionValues
                                                changeProductOptionValuesWidth={(width) => this.changeProductOptionValuesWidth(width)}
                                                option={find(this.props.StorePurchase.store.options, { position: this.activeOptionPosition })}
                                                handleOnClickValue={this.handleOnClickValue}
                                            />
                                    }
                                </ScrollArea>
                            </div>
                        </div>
                    </div>
                }

                <div className="info-action-box">
                    <div className="info-action-wrapper">
                        {this.errorMessage && <p className="info__buy-error">{this.errorMessage}</p>}
                        <Button
                            onClick={this.handleCheckoutCreation}
                            label={creditsGiftMode ? 'CLAIM GIFT!' : isGiveAwayProduct || isFreeProduct || isShipFreeProduct ? 'Get Gift' : 'Buy'}
                            className="dark-pink info-action"
                        />
                    </div>
                    {
                        !(isGiveAwayProduct || isFreeProduct || isStreamlineProduct || isShipFreeProduct) &&
                        <div className="info-action-wrapper info-action-wrapper--cart">
                            <Button
                                onClick={this.handleAddToCartClick}
                                label={inCart ? 'Added' : 'Add to cart'}
                                className="common-button info-action--cart"
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const InfoRow = ({ label, value, classPrefix, hasToolTip = false, iconSrc }) => {
    return(
        <div className={`info-row ${classPrefix}`}>
            <span className="info-row__label">
                { hasToolTip ?
                    <DiscountTooltip>
                        { label }:
                    </DiscountTooltip> :

                    `${ label }:`
                }
            </span>
            <span className="info-row__value">
                { iconSrc && classPrefix === 'gift' && <div className="info-row__project-icon"><img src={iconSrc} alt="icon"/></div> }
                { value }
            </span>
        </div>
    );
};

export default ProductDetails;
