import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import productsLoader from '../../../helpers/productsLoader';
import customerFlow from '../../../helpers/customerFlow';
import ProductService from '../../../services/product';
import './index.scss';
import {getProjectData} from "../../../projectMap";

@inject('StoreNotifications')
@inject('StoreApp')
@observer
export class ProductLanding extends Component {
    config = {
        'freeoffer': {
            link: '/sign-up',
            linkText: 'Sign up now'
        },
        'freeproduct': {
            link: '/sign-up2',
            linkText: 'Sign up now',
            paragraph: <>Join thousands of others who got their <strong>FREE</strong> gift!</>
        },
        'shipfree': {
            link: '/sign-up2',
            linkText: 'Sign up now'
        },
        'giveaway': {
            link: '/sign-up2',
            linkText: 'Sign up now'
        },
        'productland': {
            link: `/under10?strategy=${this.props.match.params.productId}&tutorial&coins=1`,
            linkText: 'Get started'
        },
        'productlander': {
            link: '/sign-up',
            linkText: 'Sign up now'
        }
    };

    @observable productData = null;

    @action async setProductData(productId) {
        try {
            const product = await ProductService.getProductById(productId);
            this.productData = {
                _id: productId,
                image: product.images[0].src,
                title: product.title,
                brand: product.brandName,
                price: productsLoader.parseProduct(product).price
            };
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }
    }

    redirectToShop = (path) => {
        const referrer = document.referrer ? new URL(document.referrer) : null;

        if (referrer && referrer.origin !== process.env.QUICKTOSHOP_URL) {
            return window.location.replace(path);
        }

        return this.props.history.push(path);
    };

    componentDidMount() {
        const { match: { params: { productId } } } = this.props;
        this.setProductData(productId);
    }

    render() {
        const { productData, props } = this;

        if (!productData) {
            return null;
        }

        const { StoreApp, history } = props;
        const { _id: productID } = productData;
        const [ , flow ] = history.location.pathname.split('/');
        const { linkText, paragraph = `${getProjectData('title')} is a site that let’s you earn cash just for online window shopping!` } = this.config[flow];
        const isSecondary = flow === 'productlander';
        const isProductFree = flow === 'freeproduct';
        let { link } = this.config[flow];

        if (flow === 'freeoffer') {
            link += '?gift';
            customerFlow.enableFreeOffer(productID);
        }

        if (flow === 'giveaway') {
            StoreApp.disableGiftMode();
            (productData.price <= 10) && customerFlow.enableGiveAway(productID);
        }

        if (flow === 'freeproduct') {
            (productData.price <= 10) && customerFlow.enableFreeProduct(productID);
        }

        if (flow === 'shipfree') {
            (productData.price <= 10) && customerFlow.enableShipFree(productID);
        }

        return (
            <div className={classnames('product-landing', {'product-landing--secondary': isSecondary}, {'product-landing--free': isProductFree})}>
                <div className="product-landing__line"/>
                <div className="product-landing__content">
                    <div className="product-landing__data">
                        <div className="product-landing__image">
                            <img src={this.productData && this.productData.image} alt=""/>
                        </div>
                        <div className="product-landing__row">
                            <span className="product-landing__name">{this.productData && this.productData.title}</span>
                            <div className="product-landing__price product-landing__price--original">
                                <span className="product-landing__price-label">Retail Value:</span>
                                <span className="product-landing__price-value">${this.productData && this.productData.price}</span>
                            </div>
                        </div>
                        <div className="product-landing__row">
                            <span className="product-landing__brand">{this.productData && this.productData.brand}</span>
                            <div className="product-landing__price product-landing__price--new">
                                <span className="product-landing__price-label">Your price:</span>
                                <span className="product-landing__price-value">FREE</span>
                            </div>
                        </div>
                    </div>
                    <div className="product-landing__info">
                        <button onClick={() => this.redirectToShop(link)} className="product-landing__action product-landing__action--filled product-landing__action--large">
                            <span>Sign up to get free gift</span>
                        </button>
                        <div className="product-landing__paragraph-box">
                            <p className="product-landing__paragraph">
                                Get your <span>{this.productData && this.productData.title}</span> for <b>free</b> just
                                for <b>signing up!</b>
                            </p>
                            <p className="product-landing__paragraph">
                                {paragraph}
                            </p>
                            <p className="product-landing__paragraph">
                                Thanks for checking us out!
                            </p>
                        </div>
                        <div className="product-landing__action-box">
                            <button onClick={() => this.redirectToShop('/')} className="product-landing__action">
                                <span>Learn more</span>
                            </button>
                            <span className="product-landing__action-divider">or</span>
                            <button onClick={() => this.redirectToShop(link)} className="product-landing__action product-landing__action--filled">
                                <span>{linkText}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProductLanding);
