import { observable } from 'mobx';

const availableStorageList = [ 'sessionStorage', 'localStorage' ];

/**
 * Wrapper for HTML5 Web Storage
 * Makes data from storage observable
 */
class BrowserStore {
    store = null;
    storagePlace = '';

    constructor(storagePlace) {
        const isAvailableStorage = availableStorageList.some((s) => s === storagePlace);

        if (typeof Storage !== 'undefined' && isAvailableStorage && window[storagePlace]) {
            // Code for localStorage/sessionStorage.
            this.storagePlace = storagePlace;
            this.store = this.createObservableStorage();
        } else {
            // eslint-disable-next-line
            console.warn('Sorry! No Web Storage support..');
        }
    }

    createObservableStorage() {
        const state = {};

        for (let i = 0; i < window[this.storagePlace].length; i++) {
            const key = window[this.storagePlace].key(i);
            state[key] = this.parseValue(window[this.storagePlace].getItem(key));
        }

        return observable.map(state);
    }

    get size() {
        return this.store.size;
    }

    has(key) {
        return this.store.has(key);
    }

    get(key, defaultValue) {
        const value = this.store.get(key);
        return value !== undefined ? value : defaultValue;
    }

    set(key, v) {
        window[this.storagePlace].setItem(key, JSON.stringify(v));
        this.store.set(key, v);
        return v;
    }

    remove(key) {
        window[this.storagePlace].removeItem(key);
        this.store.delete(key);
    }

    clear() {
        window[this.storagePlace].clear();
        this.store.clear();
    }

    keys() {
        return this.store.keys();
    }

    values() {
        return this.store.values();
    }

    entries() {
        return this.store.entries();
    }

    forEach(callback) {
        return this.store.forEach(callback);
    }

    map(callback) {
        return [ ...this.store.entries() ].map(callback);
    }

    parseValue(value) {
        try {
            return JSON.parse(value);
        } catch(e) {
            return value;
        }
    }
}

export const sessionStore = new BrowserStore('sessionStorage');
export const localStore = new BrowserStore('localStorage');
