import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import { ProductMainInfo } from '../ProductCard';
import { sanitizeProductName, prepareImageUrl } from '../../../helpers/static';
import RenderIf from '../../../helpers/renderIf';

import './favorites.scss';
@inject('StoreAsidePanel')
@inject('StoreFavorites')
@observer
class ComponentFavorites extends Component {
    goToProductModal(redirectUrl, isAvailable) {
        if (isAvailable) {
            this.props.history.push(redirectUrl);
        }
    }

    async removeProduct(event, promotionId) {
        const { StoreFavorites, StoreAsidePanel } = this.props;
        event.stopPropagation();
        await StoreFavorites.removeProductFromFavorites(promotionId, StoreAsidePanel.hideFavorites);
    }

    render() {
        const { StoreFavorites } = this.props;

        return (
            <div className="favorites-block">
                <div className="favorites-block__top">
                    <div className="title">
                        <span className="title__icon"/>
                        <span className="title__text">Favorites</span>
                    </div>
                    <span className="count">{StoreFavorites.items.length}</span>
                </div>

                <div className="favorites-block__products">
                    {StoreFavorites.items.map((item, key) => {
                        const { status, product } = item;
                        const isAvailable = status === 'available';
                        const redirectUrl = `/${sanitizeProductName(product.get('title'))}/${product.id}`;

                        return (
                            <div key={`favorites-block-${key}-${product.id}`}
                                className="favorite-product"
                                onClick={() => this.goToProductModal(redirectUrl, isAvailable)}
                            >
                                <div className="favorite-product__photo">
                                    <img
                                        src={`${prepareImageUrl(product.get('image'), '_60x$1')}`}
                                        alt={product.get('title')}
                                    />
                                </div>

                                <div className="favorite-product__info">

                                    <ProductMainInfo
                                        title={product.get('title')}
                                        brandName={product.get('brandName')}
                                        price={product.price}
                                        originalPrice={product.originalPrice}
                                        multiplier={product.get('creditsMultiplier')}
                                    />

                                    <div className="favorite-product__info__actions">
                                        <div onClick={event => this.removeProduct(event, product.id)}
                                            className="favorite-product__info__actions__delete">
                                            Delete
                                        </div>
                                        <RenderIf condition={isAvailable}>
                                            <Link
                                                onClick={event => event.stopPropagation()}
                                                to={redirectUrl}
                                                className="favorite-product__info__actions__buy"
                                            >
                                                Buy
                                            </Link>
                                        </RenderIf>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(ComponentFavorites);
