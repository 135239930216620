import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import Input from '../../ui/Input';
import Checkbox from '../../ui/Input/Checkbox';
import Button from '../../ui/Button';
import RenderIf from '../../../helpers/renderIf';
import FormBinder from '../../../helpers/formBinder';

import {
    BILLING_ADDRESS_FIELDS,
    BILLING_ADDRESS_FIELDS_EDIT_MODE,
    EMAIL
} from '../../../constants/formFields';
import { cardImages, CardItem } from '../CardItem';

import { compareAddresses, generateStateField } from '../../../helpers/static';

import UtilStripe from '../../../utils/Stripe';

import './creditCard.scss';
import Loader from "../../ui/Loader";
import CustomerService from "../../../services/customer";

@inject('StoreUser')
@inject('StorePurchase')
@inject('StoreNotifications')
@observer
class CreditCardForm extends Component {
    form = null;

    @observable creditCardLoader = false;
    @observable isLoading = false;
    defaultAddress = null;

    constructor(props) {
        super(props);
        const { existingData = {}, defaultAddress, editMode, StorePurchase } = this.props;
        this.defaultAddress = defaultAddress;
        let isEqualWithShipping = Boolean(this.defaultAddress);
        if (editMode && existingData.address1) {
            isEqualWithShipping = compareAddresses([ 'address1', 'address2', 'city', 'province', 'zipCode' ], existingData, this.defaultAddress || {});
        }

        const FIELDS = editMode ? [ ...BILLING_ADDRESS_FIELDS_EDIT_MODE ] : [ ...BILLING_ADDRESS_FIELDS ];

        if (this.isStreamlineProduct && !StorePurchase.hasProfileEmail) {
            const ASYNC_EMAIL_RULES = {
                ...EMAIL,
                rules: `${EMAIL.rules}|checkUserEmail`
            };

            FIELDS.push(ASYNC_EMAIL_RULES);
        }

        this.form = new FormBinder(FIELDS, {
            onSubmit: this.onSuccess.bind(this)
        }, {}, { ...existingData, isEqualWithShipping });
    }

    async componentDidMount () {
        if (!this.props.editMode) {
            await UtilStripe.init(this.props.StorePurchase.purchaseOn, this.onCardChangeForm, this.fontSize);
        }
    }

    get isStreamlineProduct() {
        return this.props.StorePurchase.isStreamlineProduct;
    }

    get isShipFreeProduct() {
        return this.props.StorePurchase.isShipFreeProduct;
    }

    get fontSize () {
        const { purchaseOn } = this.props.StorePurchase;
        let size = '16px';

        if (purchaseOn === 'marketplace') {
            size = window.innerWidth > '667' ? '20px' : '14px';
        }

        return size;
    }

    get isValidBillingForm() {
        const { form } = this;
        const isFormTouched = !this.props.isModal ? !this.form.isPristine : true;

        if (form.$('isEqualWithShipping').value) {
            return isFormTouched && (this.props.editMode || form.$('nameOnCard').isValid && form.$('cardToken').isValid);
        }

        return isFormTouched && form.isValid;
    }

    onCardChangeForm = (params) => {
        const { error = null, loading = null, token = null } = params || {};
        this.form.$('cardToken').showErrors(false);

        if (error !== null) {
            this.form.$('cardToken').invalidate(error);
        }

        if (loading !== null) {
            this.creditCardLoader = loading;
        }

        if (token !== null) {
            this.form.$('cardToken').set(token);
        }
    };

    async onSuccess(form) {
        this.isLoading = true;

        const { existingData = {} } = this.props;
        const values = form.values();
        const defaultAddress = values.isEqualWithShipping ? this.defaultAddress : {};

        delete defaultAddress._id;

        const data = { ...existingData, ...values, ...defaultAddress };

        if (data.province) {
            data.province = generateStateField(data.province);
        }

        if (this.props.StoreUser.isAuthenticated) {
            try {
                let cards = [];

                if (existingData && existingData._id) {
                    cards = await CustomerService.updateCreditCard(data);
                } else {
                    cards = await CustomerService.addCreditCard(data);
                }

                this.props.StoreUser.updateItem('cards', cards);
                this.props.handlerOnSuccess(cards, values.email);
            } catch (error) {
                this.props.StoreNotifications.addError(error);
            } finally {
                this.isLoading = false;
            }
        } else {
            const baseCard = data.cardToken.card;
            this.props.handlerOnSuccess(
                [
                    {
                        ...data,
                        isDefault: true,
                        brand: baseCard.brand,
                        last4: baseCard.last4,
                        expMonth: baseCard.exp_month,
                        expYear: baseCard.exp_year
                    }
                ],
                values.email
            );
            this.isLoading = false;
        }
    }

    handleCancel = (event) => {
        this.form.onReset(event);
        return this.props.handlerOnCancel();
    };

    render() {
        const { form } = this;
        const { isModal = false, existingData = {}, editMode, creditsCoinEnabled, StorePurchase } = this.props;
        const isOpenAddressForm = !form.$('isEqualWithShipping').value;

        const badgeImages = {
            norton: require('../../../images/security/norton.png'),
            truste: require('../../../images/security/truste.png'),
            mcafee: require('../../../images/security/mcafee.png')
        };

        return (
            <section className={classNames('card-form-wrapper billing-form', {'billing-form--opened-address-form': isOpenAddressForm})}>
                <form onSubmit={form.onSubmit}>
                    <div className="billing-form-logos">
                        {
                            creditsCoinEnabled && <span className="billing-form-logos__text">
                                {
                                    this.isShipFreeProduct
                                        ? 'Billing info is only used to ensure one item per household. Your card will not be charged for your gift!'
                                        : 'Billing info is only used for remainder of subtotal, shipping & taxes'
                                }
                            </span>
                        }
                        { !creditsCoinEnabled && this.isShipFreeProduct && <span className="billing-form-logos__text"></span>}
                        <span className="form-logos">
                            { Object.keys(cardImages).map( (key) => {
                                const image = cardImages[key];
                                return <img key={`card-image-${key}`} className="form-logos__icon" src={image} alt={`card-${key}`} />;
                            })}
                        </span>
                    </div>

                    {
                        editMode ?
                            <div className="accordion">
                                <div className="accordion-default-item">
                                    <CardItem
                                        {...existingData}
                                        address1={!isOpenAddressForm ? this.defaultAddress.address1 : existingData.address1}
                                        className={classNames({'card-item--change-address': isOpenAddressForm })}

                                    />
                                </div>
                            </div>
                            :
                            <div className="card-element-relative">
                                <Input
                                    showLabel={false}
                                    error={form.$('nameOnCard').error}
                                    {...form.$('nameOnCard').bind() }
                                />

                                {/* Card input (Stripe element) */}
                                <div className="card-element__wrapper">
                                    <div id="stripe-iframe-wrapper" className='form-control__input'/>
                                    <div className="error-message">
                                        {form.$('cardToken').error}
                                    </div>

                                    <Loader display={this.creditCardLoader} size="30px" />
                                </div>
                            </div>
                    }

                    <div className="is-equal-with-shipping">
                        <div className="common-input-wraper">
                            <label htmlFor="isEqualWithShipping">Billing address</label>
                        </div>

                        {
                            this.defaultAddress &&
                            <div className="is-equal-with-shipping__checkbox">
                                <Checkbox
                                    content="My billing address is the same as my shipping address"
                                    {...form.$('isEqualWithShipping').bind()}
                                />
                            </div>
                        }

                    </div>

                    <RenderIf condition={isOpenAddressForm || !this.defaultAddress}>
                        <Fragment>
                            <div className='form form-settings-shipping'>
                                <div className="two-inputs-row">
                                    <Input
                                        showLabel={false}
                                        error={form.$('firstName').error}
                                        {...form.$('firstName').bind()}
                                    />
                                    <Input
                                        showLabel={false}
                                        error={form.$('lastName').error}
                                        {...form.$('lastName').bind()}
                                    />
                                </div>

                                {this.isStreamlineProduct && !StorePurchase.hasProfileEmail &&
                                    <Input
                                        showLabel={false}
                                        error={form.$('email').error}
                                        {...form.$('email').bind()}
                                    />
                                }

                                <div className="two-inputs-row displaced">
                                    <Input
                                        showLabel={false}
                                        error={form.$('address1').error}
                                        {...form.$('address1').bind()}
                                    />
                                    <Input
                                        showLabel={false}
                                        error={form.$('address2').error}
                                        {...form.$('address2').bind()}
                                    />
                                </div>

                                <Input
                                    showLabel={false}
                                    error={form.$('city').error}
                                    {...form.$('city').bind()}
                                />

                                <div className="two-inputs-row">
                                    <Input
                                        showLabel={false}
                                        error={form.$('province').error}
                                        {...form.$('province').bind()}
                                    />
                                    <Input
                                        showLabel={false}
                                        error={form.$('zipCode').error}
                                        {...form.$('zipCode').bind()}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    </RenderIf>

                    {
                        creditsCoinEnabled &&
                            <Fragment>
                                <span className="billing-form__label">You will be able to confirm all details on next step.</span>
                                <div className="billing-form__trust-badge-box">
                                    <img src={badgeImages.norton} alt="" className="billing-form__trust-badge norton"/>
                                    <img src={badgeImages.truste} alt="" className="billing-form__trust-badge truste"/>
                                    <img src={badgeImages.mcafee} alt="" className="billing-form__trust-badge mcafee"/>
                                </div>
                            </Fragment>
                    }

                    <div className="market-place-settings-content__actions">
                        <RenderIf condition={!isModal}>
                            <Button
                                onClick={this.handleCancel}
                                label="Cancel"
                                className="transparent"
                            />
                        </RenderIf>

                        <Button
                            type="submit"
                            onClick={form.onSubmit}
                            label={isModal ? "Continue" : "Save"}
                            className="dark-pink header-btn"
                            disabled={!this.isValidBillingForm}
                        />
                    </div>
                </form>
                <Loader display={this.isLoading} />
            </section>
        );
    }
}

export default CreditCardForm;
