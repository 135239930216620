import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import './header.scss';
import ComponentSearchForm from '../Search';
import Loader from '../../ui/Loader';
import QuicktoHelper from '../../../helpers/quickto';
import { localStore } from '../../../helpers/browserStores';
import { quickToCart, quickToCartActive } from '../../../images/quickTo';

import EventListener from '../../../helpers/eventListener';
import {getProjectData} from "../../../projectMap";

const images = {
    favoritesEmpty: require('../../../images/heart-empty.svg'),
    favoritesActive: require('../../../images/heart-active.svg'),
    userPlaceholder: require('../../../images/user-placeholder.svg')
};

@inject('StoreTutorial')
@inject('StoreFavorites')
@inject('StoreUser')
@inject('StoreApp')
@inject('StoreNotifications')
@inject('StoreCategoryFilter')
@inject('StorePurchase')
@inject('StoreAsidePanel')
@inject('StoreCheckout')
@observer
class ComponentHeader extends Component {
    eListener = new EventListener();

    @observable showDropdown = false;
    @action toggleDropdown = () => {
        this.showDropdown = !this.showDropdown;

        if (this.props.StoreApp.isMobile) {
            this.props.history.push('/settings/profile');
        } else if (this.showDropdown) {
            this.eListener.addClick('ref-header-user-menu', () => {
                this.showDropdown = false;
            });
        } else {
            this.eListener.removeClick();
        }
    };

    @observable displayLogoutLoader = false;
    @action handleLogoutClick = async(event) => {
        event.stopPropagation();
        event.preventDefault();

        this.displayLogoutLoader = true;
        try {
            await this.props.StoreUser.logout();
            this.props.StoreFavorites.clearFavoriteProducts();
            this.toggleDropdown();

            this.props.history.push('/');
        } catch (error) {
            this.props.StoreNotifications.addError(error);
        }

        this.displayLogoutLoader = false;
    };

    openSignInModal = () => {
        this.props.history.push('/sign-in');
        if (QuicktoHelper.paramsToRedirect.tutorial && !localStore.get('tutorialPassed')) {
            this.props.StoreTutorial.goToNextStep();
        }
    };

    render() {
        let { displaySearchBlock = true, displayFavoritesBlock = true, creditsLandingPage = false } = this.props;
        const { StoreTutorial, StoreFavorites, StoreUser, className = '', StoreAsidePanel } = this.props;

        if (creditsLandingPage) {
            displaySearchBlock = false;
            displayFavoritesBlock = false;
        }

        const coinsCircle = StoreAsidePanel.display.coins ? getProjectData('coins-active') : getProjectData('coins-empty');

        return (
            <div className={classNames('mp-header-wrapper', className, {'mp-header-wrapper--landing': creditsLandingPage})}>
                <div className="mp-header">
                    <div className="mp-header-rectangle"/>
                    <div className="mp-header-content">
                        <Link to="/">
                            <img alt="logo" className="mp-header__logo" src={getProjectData('logo')}/>
                        </Link>

                        { displaySearchBlock && <ComponentSearchForm /> }

                        <div className="mp-header-actions">
                            { displayFavoritesBlock &&
                                <div className="mp-header-actions__aside">
                                    {
                                        this.props.StoreApp.creditsCoinEnabled &&
                                        <Fragment>
                                            <div onClick={() => {
                                                StoreAsidePanel.toggleElement('coins');

                                                if (StoreTutorial.isOpen) {
                                                    StoreTutorial.hideTutorial();
                                                    StoreTutorial.showModal();
                                                }
                                            }}
                                            className="mp-header-coins mp-header__aside-trigger">
                                                <img className="mp-header-coins__icon"
                                                    src={coinsCircle} alt=""/>
                                                <div className="mp-header-badge">
                                                    <span className="mp-header-badge__value">{this.props.StoreUser.userCredits}</span>
                                                </div>
                                            </div>
                                            <div className="mp-header-cart mp-header__aside-trigger" onClick={() => StoreAsidePanel.toggleElement('cart')}>
                                                <img src={StoreAsidePanel.display.cart ? quickToCartActive : quickToCart} alt="" className="mp-header-cart__icon"/>
                                                <div className="mp-header-badge">
                                                    <span className="mp-header-badge__value">
                                                        {this.props.StoreCheckout.count()}
                                                    </span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    <div onClick={() => StoreAsidePanel.toggleElement('favorites')}
                                        className="mp-header-favorites mp-header__aside-trigger">
                                        <img className="mp-header-favorites__icon"
                                            src={StoreAsidePanel.display.favorites ? images.favoritesActive : images.favoritesEmpty }
                                            alt=""/>
                                        <div className={classNames('mp-header-badge', {'is-hidden': StoreAsidePanel.display.favorites})}>
                                            <span className="mp-header-badge__value">
                                                {StoreFavorites.items.length}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                StoreUser.isAuthenticated ?
                                    <div className="mp-header-actions-user-menu">
                                        <div onClick={this.toggleDropdown} className="mp-header-actions-user-menu__switcher">
                                            <img className="user-logo" src={StoreUser.userLogo || images.userPlaceholder} alt=""/>
                                            <div className={classNames('user-menu-wrapper is-login', {'is-active': this.showDropdown })}>
                                                <ul className="user-menu ref-header-user-menu">
                                                    <li className="user-menu__item">
                                                        <Link to="/settings/profile" className="user-menu__link">Profile</Link>
                                                    </li>
                                                    <li className="user-menu__item">
                                                        <Link to="/settings/account-settings" className="user-menu__link">Account Settings</Link>
                                                    </li>
                                                    <li className="user-menu__item">
                                                        <Link to="/settings/order-history" className="user-menu__link">Order History</Link>
                                                    </li>
                                                    <li className="user-menu__item">
                                                        <span onClick={this.handleLogoutClick} className="user-menu__link">Sign out</span>
                                                    </li>
                                                </ul>

                                                <Loader display={this.displayLogoutLoader} size="30px" />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <button onClick={this.openSignInModal} className={classNames("common-button dark-pink",
                                        {'transparent-pink': this.props.StoreApp.creditsCoinEnabled})}>
                                        Log in
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(ComponentHeader);
