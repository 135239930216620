import React, { Component } from 'react';
import { observer } from "mobx-react";
import { action } from "mobx";
import classNames from "classnames";
import './index.scss';

@observer
class ProductOptionValues extends Component {
    ref = null;

    @action onValueClick(optionValue) {
        const { option, handleOnClickValue } = this.props;
        if (!optionValue.disabled) {
            if (option.selected === optionValue.value) {
                option.selected = null;
            } else {
                option.selected = optionValue.value;
            }
            handleOnClickValue(Boolean(option.selected));
        }
    }

    updateWidth = () => {
        this.props.changeProductOptionValuesWidth(`${this.ref.scrollWidth}px`);
    };

    componentDidMount() {
        this.updateWidth();
    }

    componentDidUpdate() {
        this.updateWidth();
    }

    render() {
        const { option } = this.props;

        return (
            <div className="mp-modal-options" ref={(ref) => {
                this.ref = ref;
            }}>
                {
                    option.values && option.values.map((optionValue, i) => {
                        return (
                            <div key={`product-option-value-${i}`}
                                className={classNames('mp-modal-options__item',
                                    { 'active': optionValue.value === option.selected },
                                    { 'in-active': optionValue.disabled },
                                )}
                                onClick={() => this.onValueClick(optionValue)} >
                                {optionValue.value}
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default ProductOptionValues;
