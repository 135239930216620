
export default [
    {
        imageClass: 'popular',
        key: 'popular',
        text: 'Popular',
        visible: true,
        order: 0
    },
    {
        imageClass: 'newest',
        key: 'newest',
        text: 'Newest',
        visible: true,
        order: 1
    },
    {
        imageClass: 'under10',
        key: 'under10',
        text: 'Under $10',
        visible: true,
        order: 2
    },
    {
        imageClass: 'clothing',
        key: 'clothing, shoes & jewelry',
        text: 'Clothing',
        visible: true
    },
    {
        imageClass: 'beauty',
        key: 'beauty',
        text: 'Beauty',
        visible: true
    },
    {
        imageClass: 'personal',
        key: 'health & personal care',
        text: 'Personal',
        visible: true
    },
    {
        imageClass: 'electronics',
        key: 'electronics',
        text: 'Electronics',
        visible: true
    },
    {
        imageClass: 'outdoors',
        key: 'sports & outdoors',
        text: 'Outdoors',
        visible: true
    },
    {
        imageClass: 'pets',
        key: 'pet supplies',
        text: 'Pets',
        visible: true
    },
    {
        imageClass: 'baby',
        key: 'baby',
        text: 'Baby',
        visible: true
    },
    {
        imageClass: 'home',
        key: 'home & kitchen',
        text: 'Home',
        visible: true
    },
    {
        imageClass: 'grocery',
        key: 'grocery & gourmet food',
        text: 'Grocery',
        visible: true
    },
    {
        imageClass: 'phones',
        key: 'cell phones & accessories',
        text: 'Phones',
        visible: true
    },
    {
        imageClass: 'office',
        key: 'office products',
        text: 'Office',
        visible: true
    },
    {
        imageClass: 'garden',
        key: 'patio, lawn & garden',
        text: 'Garden',
        visible: true
    },
    {
        imageClass: 'tools',
        key: 'tools & home improvement',
        text: 'Tools',
        visible: true
    },
    {
        imageClass: 'movies',
        key: 'movies & tv',
        text: 'Movies',
        visible: true
    },
    {
        imageClass: 'music',
        key: 'musical instruments',
        text: 'Music',
        visible: true
    },
    {
        imageClass: 'games',
        key: 'toys & games',
        text: 'Games',
        visible: true
    },
    {
        imageClass: 'automotive',
        key: 'automotive',
        text: 'Auto',
        visible: true
    },
    {
        imageClass: 'arts',
        key: 'arts, crafts & sewing',
        text: 'Arts & crafts',
        visible: true
    }
];
