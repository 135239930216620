import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import './index.scss';

const Switch = inject('StoreApp')(observer(({ StoreApp, history }) => {
    return (
        <div className="switch">
            <input checked={StoreApp.creditsGiftMode} onChange={() => {
                StoreApp.creditsToggleMode();
                history.push(StoreApp.lastMarketplaceURL);
            }} className="switch__input" type="checkbox" id="checkbox-1"/>
            <label className="switch__label" htmlFor="checkbox-1" />
        </div>
    );
}));

export default withRouter(Switch);
