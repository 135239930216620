
import Request from '../utils/Request';
import { shopAPI } from '../config/api';

class ProductService {
    path = shopAPI.endPoints.customer;

    getProductById(id) {
        return Request.get(`/promotions/${id}`, null, null);
    }

    getSimilarProducts(promotionId, accountId = null) {
        return Request.get(`/promotions/recommendations?promotionId=${promotionId}${accountId ? `&accountId=${accountId}` : ''}&perPage=20`, null, null);
    }
}

const instance = new ProductService();
export default instance;
