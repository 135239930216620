import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { differencesData, bestsellersData, brandsImages, footerLinks, widgetLinks } from './constants';
import { GTM } from '../../../utils/GTM';
import './index.scss';
import {getProjectData} from "../../../projectMap";

@inject('StoreApp')

@observer
class QuickToLanding extends React.Component {
    @observable isMobileMenuShown = false;
    @observable isVisibleAboutUsPopup = false;
    @observable isVisibleContactUsPopup = false;

    @action toggleMobileMenu = () => {
        this.isMobileMenuShown = !this.isMobileMenuShown;
    }

    @action showPopup = (popupType) => {
        this.isVisibleAboutUsPopup = popupType === 'about-us' ? true : false;
        this.isVisibleContactUsPopup = popupType === 'contact-us' ? true : false;
    }

    @action hidePopup = (popupType) => {
        this.isVisibleAboutUsPopup = popupType === 'about-us' ? false : this.isVisibleAboutUsPopup;
        this.isVisibleContactUsPopup = popupType === 'contact-us' ? false : this.isVisibleContactUsPopup;
    }

    redirectToShop = (path) => {
        const referrer = document.referrer ? new URL(document.referrer) : null;

        if (referrer && referrer.hostname !== process.env.QUICKTOSHOP_URL) {
            return window.location.replace(path);
        }

        return this.props.history.push(path);
    }

    showKlicklyIframe = (timeout) => {
        setTimeout(() => document.querySelector('.klickly-iframe-unit-wrapper').classList.add('klickly-iframe-animation-appears'), timeout);
    }

    componentDidMount() {
        this.showKlicklyIframe(5000);
    }

    render() {
        const { isMobile } = this.props.StoreApp;

        return(
            <div className="landing">
                <QuickToLandingHeader
                    redirect={this.redirectToShop}
                    toggleMobileMenu={this.toggleMobileMenu}
                    isMobileMenuShown={this.isMobileMenuShown}
                    showPopup={this.showPopup}
                />
                <QuickToLandingBanner
                    redirect={this.redirectToShop}
                />
                <QuickToLandingDifferences
                    data={differencesData}
                />
                <QuickToLandingBestSellers
                    redirect={this.redirectToShop}
                    data={bestsellersData}
                />
                <QuickToLandingBrands
                    images={brandsImages}
                />
                <QuickToLandingFooter
                    links={footerLinks}
                    showPopup={this.showPopup}
                />
                <QuickToLandingIframe
                    isMobile = {isMobile}
                />
                <QuickToLandingPopup
                    isVisibleAboutUsPopup={this.isVisibleAboutUsPopup}
                    isVisibleContactUsPopup={this.isVisibleContactUsPopup}
                    hidePopup={this.hidePopup}
                />
            </div>
        );
    }
}

const QuickToLandingHeader = ({ isMobileMenuShown, toggleMobileMenu, redirect, showPopup }) => {
    return(
        <header className="landing-header">
            <div className="landing-header__container">
                <span className="landing-header__logo">{getProjectData('landing-header__logo')}</span>
                <div className="landing-header__menu">
                    <div className="landing-header__nav-icon-wrapper">
                        <div className={classNames('landing-header__nav-icon', {'opened': isMobileMenuShown})} onClick={toggleMobileMenu}>
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <nav className="landing-header__nav">
                        <ul>
                            <li className="landing-header__nav-item">
                                <a href="https://marketplace.klickly.com/" target="_blank" rel="noopener noreferrer">shop</a>
                            </li>
                            <li className="landing-header__nav-item">
                                <div onClick={() => showPopup('about-us')}>about us</div>
                            </li>
                            <li className="landing-header__nav-item">
                                <a href="https://consumer.klickly.com/" target="_blank" rel="noopener noreferrer">create account</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="landing__action landing-header__action">
                        <a className="landing-header__link" href="https://consumer.klickly.com/" target="_blank" rel="noopener noreferrer">Sign in</a>
                    </div>
                </div>
            </div>
            <nav className={classNames('landing-header__nav--mobile', {'opened': isMobileMenuShown})}>
                <ul>
                    <li className="landing-header__nav-item"><a href="https://marketplace.klickly.com/" target="_blank" rel="noopener noreferrer">shop</a></li>
                    <li className="landing-header__nav-item"><div onClick={() => showPopup('about-us')}>about us</div></li>
                    <li className="landing-header__nav-item"><a href="https://consumer.klickly.com/" target="_blank" rel="noopener noreferrer">create account</a></li>
                </ul>
            </nav>
        </header>
    );
};
const QuickToLandingBanner = ({ redirect }) => {
    return (
        <section className="landing-banner">
            <div className="landing-banner__container">
                <h1 className="landing-banner__title">Discover something new to love.</h1>
                <div className="landing__action landing-banner__action">
                    <div className="landing-banner__link" onClick={() => {
                        GTM.HomePageCTAEvent.send();
                        window.open('https://marketplace.klickly.com/');
                    }}>shop now</div>
                </div>
            </div>
        </section>
    );
};
const QuickToLandingDifferences = ({ data }) => {
    return(
        <section className="landing-differences">
            <div className="landing-differences__container">
                <h2 className="landing__title">why we're different</h2>
                <div className="landing-differences__box">
                    {data.map(({icon, text}, idx) => {
                        return(
                            <div className="landing-differences__item" key={`item-${idx}`}>
                                <div className="landing-differences__icon-wrapper">
                                    <img src={icon} alt="icon" className="landing-differences__icon"/>
                                </div>
                                <span className="landing-differences__text">{text}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
const QuickToLandingBestSellers = ({ data, redirect }) => {
    return(
        <section className="landing-bestsellers">
            <h2 className="landing__title">best sellers</h2>
            <h3 className="landing__subtitle">shop our best selling items</h3>
            <div className="landing-bestsellers__container">
                <div className="landing-bestsellers__box">
                    {data.map(({image, name, price}) => {
                        return(
                            <div className="landing-bestsellers__item" key={`pruduct-${name}`}>
                                <div onClick={() => window.open('https://marketplace.klickly.com/')}>
                                    <img src={image} alt="bestseller" className="landing-bestsellers__image"/>
                                </div>
                                <div className="landing-bestsellers__info">
                                    <span className="landing-bestsellers__name">{name}</span>
                                    <span className="landing-bestsellers__divider">...</span>
                                    <span className="landing-bestsellers__price">{price}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
const QuickToLandingBrands = ({ images }) => {
    return(
        <section className="landing-brands">
            <h2 className="landing__title">shop from brands like</h2>
            <div className="landing-brands__box">
                {images.map((src, idx) => {
                    return(
                        <div onClick={() => window.open('https://marketplace.klickly.com/')} className="landing-brands__item-wrapper" key={`brand-${idx}`}>
                            <img src={src} alt="brand" className="landing-brands__item"/>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

const QuickToLandingIframe = ({isMobile}) => {
    const widgetLink = widgetLinks[process.env.FRONT_BUILD_ENV];
    const brandAds = process.env.FRONT_BUILD_ENV === 'production' ? '&brandId=5bea2174f18d2d46cd5968da' : '';

    return(
        <div className={classNames("klickly-iframe-unit-wrapper", {'klickly-iframe-unit-wrapper_mobile': isMobile})}>
            {
                isMobile ?
                    <iframe title="klickly-widget" id="widgetFrame" scrolling="no" src={`${widgetLink}/?height=50&width=320${brandAds}`} frameBorder="0" width="320" height="50" />
                    :
                    <iframe title="klickly-widget" id="widgetFrame" scrolling="no" src={`${widgetLink}/?height=250&width=300${brandAds}`} frameBorder="0" width="300" height="250" />
            }
        </div>
    );
};

const QuickToLandingPopup = ({isVisibleContactUsPopup, isVisibleAboutUsPopup, hidePopup}) => {
    const mailto = getProjectData('mailto');
    return(
        <div className={classNames("quickto-modal-wrapper", {'visible': isVisibleContactUsPopup || isVisibleAboutUsPopup})}
        >
            <div className="quickto-modal-content">
                {
                    isVisibleContactUsPopup ?
                    <>
                    <div className="quickto-modal-header">
                        <div className="quickto-modal-header__logo">{getProjectData('landing-header__logo')}</div>
                        <div className="quickto-modal-header__close" onClick={() => hidePopup('contact-us')}></div>
                    </div>
                    <div className="quickto-modal-header__text">Contact Information</div>
                    <div className="quickto-modal-body">
                        <div className="quickto-modal-body__left">
                            <div className="quickto-modal-body__row">Address:</div>
                            <div className="quickto-modal-body__row">520 Broadway, Suite 200</div>
                            <div className="quickto-modal-body__row">Santa Monica, CA 90401</div>
                        </div>
                        <div className="quickto-modal-body__right">
                            <div className="quickto-modal-body__row">Email:</div>
                            <div className="quickto-modal-body__row"><a href={`mailto:${mailto}`}>{mailto}</a></div>
                        </div>
                    </div>
                    <div className="quickto-modal-footer">
                        <div className="quickto-modal-footer__ok-button" onClick={() => hidePopup('contact-us')}>OK</div>
                    </div>
                    </> : null
                }
                {
                    isVisibleAboutUsPopup ?
                    <>
                    <div className="quickto-modal-header">
                        <div className="quickto-modal-header__logo">{getProjectData('landing-header__logo')}</div>
                        <div className="quickto-modal-header__close" onClick={() => hidePopup('about-us')}></div>
                    </div>
                    <div className="quickto-modal-header__text">About us</div>
                    <div className="quickto-modal-body">
                        <div className="quickto-modal-body__left">
                            <div className="quickto-modal-body__row quickto-modal-body__row_one">
                                <span className="quickto-modal-body__logo">{getProjectData('landing-header__logo')}</span> shop is a best way to shop on the internet!<br/> We partner with coolest and hippiest brands to offer you the best deals online.
                            </div>
                        </div>
                    </div>
                    <div className="quickto-modal-footer">
                        <div className="quickto-modal-footer__ok-button" onClick={() => hidePopup('about-us')}>OK</div>
                    </div>
                    </> : null
                }
            </div>
        </div>
    );
};

const QuickToLandingFooter = ({ showPopup }) => {
    return(
        <footer className="landing-footer">
            <div className="landing-footer__container">
                <div className="landing-footer__info">
                    <span className="landing-footer__heading">Information</span>
                    <ul className="landing-footer__list">
                        <li className="landing-footer__list-item" key='about' onClick={() => showPopup('about-us')}>
                            About Us
                        </li>
                        <li className="landing-footer__list-item" key='contact' onClick={() => showPopup('contact-us')}>
                            Contact Us
                        </li>
                        <li className="landing-footer__list-item" key='account'>
                            <a href="https://consumer.klickly.com/" target="_blank" rel="noopener noreferrer">My Account</a>
                        </li>
                        <li className="landing-footer__list-item" key='order-history'>
                            <a href="https://consumer.klickly.com/" target="_blank" rel="noopener noreferrer">Orders History</a>
                        </li>
                        <li className="landing-footer__list-item" key='advanced-search'>
                            <a href="https://marketplace.klickly.com/" target="_blank" rel="noopener noreferrer">Advanced Search</a>
                        </li>
                        <li className="landing-footer__list-item" key='privacy-policy'>
                            <a href="https://www.klickly.com/docs/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
                <img className="landing-footer__payment-methods"
                    src={require('../../../images/quickTo/landing/quickto-cards.svg')}
                    alt="payment methods"/>
            </div>
        </footer>
    );
};

export default withRouter(QuickToLanding);
