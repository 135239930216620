import {action, computed, observable} from 'mobx';

const defaultDiscount = {
    rate: 0,
    type: null,
    freeShipping: false
};

class StoreDiscount {
    @observable discount = defaultDiscount;
    @observable appliedDiscount = defaultDiscount;

    @observable lastFavoritesProductsLength = 0;

    @action setAvailableDiscount(discount) {
        this.discount = discount;
    }

    @computed get isFreeShipping() {
        return Boolean(this.discount.freeShipping);
    }

    @computed get isDiscountAvailable() {
        return Boolean(this.discount && this.discount.rate);
    }

    @computed get discountText() {
        const text = `Receive ${this.discount.rate}% off when you create an account`;

        if (this.discount.type === 'favorites') {
            return `${text} and favorite ${this.discount.amountOfProducts} products.`;
        }

        return text;
    }
}

export default StoreDiscount;
